import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
export function CountdownTimer({ ToTime }) {

    const { t: trans,i18n } = useTranslation(["info"]);
    let lang = (i18n.language || 'ar');

    const calculateTimeLeft = () => {
        const difference = new Date(ToTime) - new Date();
        if (difference <= 0) return null;

        return {
           month : Math.floor((difference / 1000 / 60 / 60/24/30) %12),
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / (1000 * 60)) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        if (!timeLeft) return;

        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [ToTime, timeLeft]);

    return (
        <>
            {timeLeft ? (
                    <> ⏳
        
         
         
                    {timeLeft.month > 0 &&
                         <>
                        {timeLeft.month}
                        <>
                            {trans("info:month")}
                        </>
    
                    </>
                    }
                    {timeLeft.month > 0 && ': '}
                    {
                        timeLeft.days > 0 && <>
                            <>
                                {timeLeft.days}
                            </>
                            <>
                                {trans("info:day")}
                            </>
                        </>
                    }
                    {timeLeft.days > 0 && ': '}
                    {
                        timeLeft.hours > 0 && <>
                            <>
                                {timeLeft.hours}
                            </>
                            <>
                                {trans("info:hour")}
                            </>
    
                        </>
                    }
                    {timeLeft.hours > 0 && ': '}
                    {
                        timeLeft.minutes > 0 && <>
                            <>
                                {timeLeft.minutes}
                            </>
                            <>
                                {trans("info:minute")}
                            </>
    
                        </>
                    }
                   {': '}
                    {
                        timeLeft.seconds && ( <>
                            <>
                                {timeLeft.seconds}
                            </>
                            <>
                                {trans("info:second")}
                            </>
    
                        </>)
                    }
         
         </> 
         
                ) : (
                <>🎉 Expired! 🎉</>
            )}
        </>
    );
}


