import { Fragment, useCallback, useEffect, useState } from "react";
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router";
import ErrorLabel from '../../components/error';
import { DropDownInput1 } from "../../components/_input/_dropdown1";
import { OfferDetail } from "../../components/offerDetail";
import { Swiper, SwiperSlide } from "swiper/react";
import { getCleanSlug } from '../../utils/slugManager';
import config from "../../configuration/config.json";
import { DateInput3 } from "../../components/_input/_dateInput_3";
import Popup from "../../User/popup";
import CarUser from "../../User/components/CarUser";
import DatePicker from 'react-datepicker';
import { toast } from "react-toastify";
import PhoneDropDown from "../../components/_input/_phoneDropDown";
import { DateInput2 as DateInput } from "../../components/_input/_dateInput_2";
import { InputField } from "../../components/_input/_input";
import { DropDownInput } from "../../components/_input/_dropdown";
import { TextArea } from "../../components/_input/_textArea";
import { useUserCart } from '../../User/hooks/useUserCart';
import { GetMessages, formValidate } from '../../utils/validation';
import { Price } from "../../components/__simple/_price";
import { now } from "moment";
import Moment from "react-moment";
import SimpleButton from "../../components/_input/SimpleButton";
import Joi from "joi";
import { getData, User_Data_Key } from "../../utils/localStorageManager";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function OfferDetailPage() {

    const { t: trans, i18n } = useTranslation(["common", "offers", "extra"]);
    let { slug } = useParams();
    const { changecartItem } = useUserCart();
    const languageDir = 'language-' + localStorage.getItem('i18nextLng');
    const [isLogin, setLogin] = useState(false);
    const [Branches, setBranches] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupPay, setShowPopupPay] = useState(null);
    const [banner, setBanner] = useState({});
    const [Note, setNote] = useState("");
    const [packages, setPackages] = useState([]);
    const [years, setYears] = useState([]);
    const [isSendeng, setIsSendeng] = useState(false);
    const [error, setError] = useState({});
    const [indexPanel, setIndexPanel] = useState("1000");
    const [requestForm, setRequestForm] = useState({
        lastName: '',
        firstName: '',
        phone: '',
        email: '',
        subject: '',
        date: '',
        time: '',
        messageContent: '',
        myCarID: '',
        SiteID: null,
    });
    const LoginSchema = Joi.object({
        date: Joi.date().required().messages(GetMessages()),
        time: Joi.date().required().messages(GetMessages()),
        // VIN: Joi.string().required().messages(GetMessages()),
        meterReading: Joi.string().required().messages(GetMessages()),
        SiteID: Joi.string().required().messages(GetMessages()),
        year: Joi.string().required().messages(GetMessages()),
        model: Joi.string().required().messages(GetMessages())
    });
    const notLoginSchema = Joi.object({
        date: Joi.date().required().messages(GetMessages()),
        time: Joi.date().required().messages(GetMessages()),
        // VIN: Joi.string().required().messages(GetMessages()),
        SiteID: Joi.string().required().messages(GetMessages()),
        meterReading: Joi.string().required().messages(GetMessages()),
        firstName: Joi.string().required().messages(GetMessages()),
        lastName: Joi.string().required().messages(GetMessages()),
        // email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).messages(GetMessages()),
        phone: Joi.string().required().messages(GetMessages()),
        year: Joi.string().required().messages(GetMessages()),
        model: Joi.string().required().messages(GetMessages())
    });
    const [offer, setOffer] = useState(null);
    const [offerSupportedCars, setOfferSupportedCars] = useState([]);
    const [packageSupportedCars, setPackageSupportedCars] = useState([]);
    const [acticeService, setActiceService] = useState(null);
    const [acticeCar, setActiceCar] = useState(null);
    const [acticePackage, setActicePackage] = useState(null);
    let lang = (i18n.language || 'ar');
    useEffect(() => {
        callback();
        FetchOfferSupportedServicesSacondry();
        FetchOfferSupportedVehicles();
        GetBranch();
    }, []);

    useEffect(() => {

        try {
            window.tamaraWidgetConfig = {
                lang: lang,
                country: "SA",
                publicKey: "71258997-64ec-418a-9cba-0d3849fa219f"
            }


            window.TamaraFAQsFrame.render({ lang: lang })
        } catch (error) {

        }

        let data = {};

        try {
            data = JSON.parse(getData(User_Data_Key, true));
        } catch (error) {

        }
        try {

            if (getData('isAuthenticated'))
                setLogin(true);
        } catch (err) {
            //console.log(err);
            setLogin(false);
        }

    }, [lang]);

    var tamarFAQsConfig = {
        lang: 'ar', // Language. Default is Arabic
        styles: { // Custome style - Ignore this if you don't have any custome style
            width: '100%',
            height: '100%'
        },
        autoRender: false, // Auto render after the script is loaded. Default is 'true'
        tamaraLogo: false // Display tamara logo during loading content page. Default is 'true'
    }

    const [fullDatetime, setFullDatetime] = useState(null);
    useEffect(() => {

        try {

            if (requestForm?.date) {

                var md = new Date(requestForm?.date);
                try {
                    if (requestForm?.time) {

                        var Hours = requestForm?.time.split(":");

                        md.setHours(Hours[0], Hours[1], 0);
                    }
                } catch (error) {

                }
                var datetime = new moment(md).format('yyyy-MM-DD HH:mm:ss')
                // var datetime = md.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replaceAll(',', '').replaceAll('/', '-');
                setFullDatetime(datetime);
                console.log("datetime:", datetime);
            }

        } catch (error) {
        }


    }, [requestForm]);
    useEffect(() => {

        var ly = [];

        try {
            if (acticeCar?.fromYear && acticeCar?.toYear) {

                ly.push({ value: acticeCar.fromYear, text: acticeCar.fromYear });
                var newy = parseInt(acticeCar.fromYear);
                for (var i = 0; i <= 30; i++) {

                    newy = parseInt(newy) + 1;

                    ly.push({ value: newy, text: newy });

                    if (newy >= acticeCar?.toYear) {

                        break;
                    }
                }
                setYears(ly);

            }
        } catch (error) {

        }


    }, [acticeCar]);

    const callback = useCallback(async () => {
        try {


            try {

                if ("caches" in window) {
                    let url = "detail-" + getCleanSlug();

                    // Opening that particular cache
                    const cacheStorage = await caches.open("OfferPage");
                    // Fetching that particular cache data
                    const cachedResponse = await cacheStorage.match(
                        url
                    );
                    var data = await cachedResponse?.json();
                    if (data) {
                        setBanner(data?.banner)
                        setPackages(data?.packages)
                        setOffer(data?.offer)
                        setOfferSupportedCars(data?.offerSupportedCars)
                        setPackageSupportedCars(data?.packagesSupportedCars)

                        if (data?.packages.length == 1) {

                            setActicePackage(data?.packages[0]);
                        }

                    }
                }

            } catch (error) {
            }
            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/offers/detail/" + getCleanSlug(),
                method: "get",
            });
            setBanner(response.data.banner)
            setPackages(response.data.packages)
            setOffer(response.data.offer)
            setOfferSupportedCars(response.data.offerSupportedCars)
            setPackageSupportedCars(response.data.packagesSupportedCars)

            if (response.data.packages.length == 1) {

                setActicePackage(response.data.packages[0]);
            }

            const Responsedata = new Response(JSON.stringify(response.data));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("OfferPage").then((cache) => {
                    cache.put("detail-" + getCleanSlug(), Responsedata);

                });
            }

        } catch (error) {
            console.log(error);
        }
    }, [lang, slug])

    const FetchOfferSupportedServicesSacondry = useCallback(async () => {
        try {
            const cslug = await getCleanSlug();
            await axios.post(
                config.baseURL + 'func',
                {
                    //Site_FetchOfferSupportedServices
                    "name": "Site_FetchOfferSupportedServices",
                    "values": {
                        "slug": cslug
                    }
                }
            ).then(function (response) {

                var Mainservice = [];
                var Lastservice = [];
                var MainserviceIDS = [];
                response.data?.map((item, index) => {

                    if (item?.ServiceID) {
                        if (!Lastservice.includes(item)) {
                            Lastservice.push(item);
                            MainserviceIDS.push(item?.subService2);
                        }
                    } else if (item?.subService2) {

                        if (!Mainservice.includes(item)) {

                            Mainservice.push(item);
                        }
                    }

                });
                Mainservice = Mainservice.filter(item => !MainserviceIDS.includes(item?.subService2))
                setSupportedMainServices(Mainservice);
                setSupportedLastsServices(Lastservice);
                if (Lastservice[0])
                    setActiceService(Lastservice[0]);

                setSupportedServices(response.data);
                setLoadingSupportedServices(false);


                const Responsedata = new Response(JSON.stringify(response.data));

                if ("caches" in window) {
                    // Opening given cache and putting our data into it
                    caches.open("OfferPage").then((cache) => {
                        cache.put("Site_FetchOfferSupportedCampaignTransServices-" + getCleanSlug(), Responsedata);

                    });
                }

            }).catch(function (error) {
                console.log(error);
                setLoadingSupportedServices(false);
            });

        } catch (error) {
        }

    }, []);
    const [SupportedServices, setSupportedServices] = useState([]);
    const [SupportedMainServices, setSupportedMainServices] = useState([]);
    const [SupportedLastsServices, setSupportedLastsServices] = useState([]);
    const [isLoadingSupportedServices, setLoadingSupportedServices] = useState(true);
    const FetchOfferSupportedServices = useCallback(async () => {
        try {
            setLoadingSupportedServices(true);


            try {

                if ("caches" in window) {
                    let url = "Site_FetchOfferSupportedCampaignTransServices-" + getCleanSlug();

                    // Opening that particular cache
                    const cacheStorage = await caches.open("OfferPage");
                    // Fetching that particular cache data
                    const cachedResponse = await cacheStorage.match(
                        url
                    );
                    var data = await cachedResponse?.json();
                    if (data) {
                        var Mainservice = [];
                        var Lastservice = [];
                        var MainserviceIDS = [];
                        data?.map((item, index) => {

                            if (item?.ServiceID) {
                                if (!Lastservice.includes(item)) {
                                    Lastservice.push(item);
                                    MainserviceIDS.push(item?.subService2);
                                }
                            } else if (item?.subService2) {

                                if (!Mainservice.includes(item)) {

                                    Mainservice.push(item);
                                }
                            }

                        });
                        Mainservice = Mainservice.filter(item => !MainserviceIDS.includes(item?.subService2))
                        setSupportedMainServices(Mainservice);
                        setSupportedLastsServices(Lastservice);
                        if (Lastservice[0])
                            setActiceService(Lastservice[0]);

                        setSupportedServices(data);
                        setLoadingSupportedServices(false);
                    }
                }

            } catch (error) {
            }

            const cslug = await getCleanSlug();
            await axios.post(
                config.baseURL + 'func',
                {
                    //Site_FetchOfferSupportedServices
                    "name": "Site_FetchOfferSupportedCampaignTransServices",
                    "values": {
                        "slug": cslug
                    }
                }
            ).then(function (response) {

                var Mainservice = [];
                var Lastservice = [];
                var MainserviceIDS = [];
                response.data?.map((item, index) => {

                    if (item?.ServiceID) {
                        if (!Lastservice.includes(item)) {
                            Lastservice.push(item);
                            MainserviceIDS.push(item?.subService2);
                        }
                    } else if (item?.subService2) {

                        if (!Mainservice.includes(item)) {

                            Mainservice.push(item);
                        }
                    }

                });
                Mainservice = Mainservice.filter(item => !MainserviceIDS.includes(item?.subService2))
                setSupportedMainServices(Mainservice);
                setSupportedLastsServices(Lastservice);
                if (Lastservice[0])
                    setActiceService(Lastservice[0]);

                setSupportedServices(response.data);
                setLoadingSupportedServices(false);


                const Responsedata = new Response(JSON.stringify(response.data));

                if ("caches" in window) {
                    // Opening given cache and putting our data into it
                    caches.open("OfferPage").then((cache) => {
                        cache.put("Site_FetchOfferSupportedCampaignTransServices-" + getCleanSlug(), Responsedata);

                    });
                }

            }).catch(function (error) {
                console.log(error);
                FetchOfferSupportedServicesSacondry();
            });
        } catch (error) {
            console.log(error);
            setLoadingSupportedServices(false);
        }

    }, []);


    const [SupportedVehicles, setSupportedVehicles] = useState([]);
    const [isLoadingSupportedVehicles, setLoadingSupportedVehicles] = useState(true);
    const FetchOfferSupportedVehicles = useCallback(async () => {
        try {
            setLoadingSupportedVehicles(true);

            try {

                if ("caches" in window) {
                    let url = "Site_FetchOfferSupportedVehicles-" + getCleanSlug();

                    // Opening that particular cache
                    const cacheStorage = await caches.open("OfferPage");
                    // Fetching that particular cache data
                    const cachedResponse = await cacheStorage.match(
                        url
                    );
                    var data = await cachedResponse?.json();
                    if (data) {
                        setSupportedVehicles(data);
                        setLoadingSupportedVehicles(false);
                    }
                }

            } catch (error) {
            }

            await axios.post(
                config.baseURL + 'func',
                {
                    "name": "Site_FetchOfferSupportedVehicles",
                    "values": {
                        "slug": getCleanSlug()

                    }
                }
            ).then(function (response) {

                setSupportedVehicles(response.data);
                setLoadingSupportedVehicles(false);

                const Responsedata = new Response(JSON.stringify(response.data));

                if ("caches" in window) {
                    // Opening given cache and putting our data into it
                    caches.open("OfferPage").then((cache) => {
                        cache.put("Site_FetchOfferSupportedVehicles-" + getCleanSlug(), Responsedata);

                    });
                }

            }).catch(function (error) {
                console.log(error);
                setLoadingSupportedVehicles(false);
            });
        } catch (error) {
            console.log(error);
            setLoadingSupportedVehicles(false);
        }

    }, []);

    const handelshowPup = (type) => {


        setShowPopup(true);
    }
    function SendResreve(VIN, MainServiceID, subServiceID, TypeServiceID, ServecID, ModelCode, UserID, Amount) {
        var thisSchema = null;

        if (isLogin) {

            thisSchema = LoginSchema;
        } else {
            thisSchema = notLoginSchema;

        }

        const result = formValidate(thisSchema, requestForm) ?? {};

        setError(result);
        if (Object.keys(result).length == 0 && isSendeng == false) {

            if (SupportedLastsServices?.length <= 0) {

                toast.warn(trans("offers:services_supported") + " (0)");
                return;
            }

            setIsSendeng(true);

            var ServicesList = "";
            SupportedLastsServices.map((a) => {

                ServicesList += a.ServiceID + ","


            });

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = {
                "VIN": requestForm?.VIN,
                "Model": acticeCar?.type,
                "Year": requestForm?.year ? requestForm?.year + "" : null,
                "CarType": acticeCar?.modelCode,
                "UserID": UserID ?? "",
                "TotalAmount": Amount ? Amount : 0,
                "MainServiceID": MainServiceID,
                "subServiceID": subServiceID,
                "TypeServiceID": TypeServiceID,
                "ServiceID": ServecID,
                "RequiredServicesList": ServicesList,
                "Note": Note,

                "MeterReading": requestForm?.meterReading,
                "FirstName": requestForm?.firstName,
                "LastName": requestForm?.lastName,
                "phone": requestForm?.phone,
                "Email": requestForm?.email,
                "StoreID": requestForm?.SiteID,
                "Orderdate": moment(requestForm?.date).format('YYYY-MM-DD'),
                "OrderTime": moment(requestForm?.time).format('HH:mm:ss'),
                "PackageId": acticeCar?.packageId ? acticeCar?.packageId + "" : null

            };


            axios.request({
                url: config.controllURL + "api/Service/ServiceRequestAdd", headers: myHeaders,
                data: raw, method: "POST", responseType: "json"
            }).then(function (response) {
                //console.log("Service", response.data)

                setShowPopup(false);
                toast.success(trans("info:reservation_completed_successfully"));

            }
            ).then((response) => {
                //console.log('Error', response);
                setIsSendeng(false);
            })
                .catch(error => {
                    //console.log('error', error);
                    setIsSendeng(false);
                }



                );

        } else {
            console.log("result>>>>>>>>>>>>>>>>>:", result);

        }
    }

    async function GetBranch() {

        const Body = {
            "name": "site_FetchMaintenanceBranches"
        };

        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {

                setBranches(response.data);


                setTimeout(() => {
                    if (response?.data?.length == 1) {

                        const val = { ...requestForm };
                        val.SiteID = response?.data[0]?.SiteID;
                        setRequestForm(val);
                    }
                }, 1000);

            })
            .catch(function (error) {
                //console.log(error);
            });


    }

    const [listTimeAvailable, setListTimeAvailable] = useState([]);
    // const [maxTime, setMaxTime] = useState(0);
    // const [minTime, setMinTime] = useState(0);

    const webAvailableReshours = async (date = '') => {
        const Body = {
            "name": "web_AvailableReshours",
            "values": {
                "Resdate": date
            }
        }

        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                console.log("web_AvailableReshours:response", response);
                if (response.status >= 200 && response.status < 299) {
                    console.log("web_AvailableReshours:response", response.statusText);
                    if (response?.data && response?.data.length != 0) {
                        response?.data.forEach((val, index) => {

                            setListTimeAvailable(_lstTime => [..._lstTime, parseInt(val?.AvailableHour)]);
                        });

                    }
                }
            })
            .catch(function (error) {
                console.log("web_AvailableReshours:Error", error);
                toast.warn(trans("form:please-choose-another-date"));
            });
    }
    return (
        <Fragment>
            {/* 
            <Banner
                subtitle={banner?.type}
                title={banner?.title}
                desc={banner?.excerpt}
                secondImage={banner?.image ? config?.mainURL + banner?.image : null}
                offerDate={banner?.endDate}
                backOpasity={banner?.backOpasity}
                isOffer={true}
                packages={packages}
                handelActicePackage={(value) => {

                    setActicePackage(value);
                    setActiceCar(null);

                }}
                handelButton={(type) => {

                    handelshowPup(type);
                }}
            /> */}

            <div className={" is-offer " + " mx-10 mb-8 banner-container p-8 border-radius  " + languageDir + ' '} >

                <div className="banner-bg">

                </div>

                <div className="banner-info">

                    <div className="banner-info-site">
                        <div className="banner-info-img border-radius" >
                            <img className={"object-full "} hidden={(!banner?.image || banner?.endDate == null)} src={banner?.image ? (config?.mainURL + banner?.image) : null} alt={banner?.title} onError={e => e.target.style.display = 'none'} />

                        </div>

                        {
                            SupportedVehicles != null && SupportedVehicles.length > 0 ?

                                <div className="nice-sm-hidden ">

                                    <div className={"my-2 mt-10 flex nice-flex-center-items panel-section  nice-border-top-section"}>

                                        <h1 className="text-20 line-top text-super-gray font-bold">{trans("offers:vehicles_supported")}</h1>

                                        <div className="panel-content-top-tage ">
                                            <h2 className="mx-5 text-gray  text-30">{(acticePackage?.id ? SupportedVehicles.filter(a => a.packageId == acticePackage?.id) : SupportedVehicles)?.length}</h2>

                                        </div>
                                    </div>

                                    <div className={"my-10  p-8 pb-20 border-radius"}>
                                        <Swiper
                                            spaceBetween={20}
                                            slidesPerView={2}
                                            breakpoints={{
                                                576: {
                                                    slidesPerView: 3,
                                                },
                                            }}
                                            pagination
                                            resizeObserver={"true"}

                                            watchSlidesVisibility
                                            watchSlidesProgress
                                            autoplay={false}
                                            loop={false}
                                            observer={true}

                                        >
                                            {
                                                (acticePackage?.id ? SupportedVehicles.filter(a => a.packageId == acticePackage?.id) : SupportedVehicles)?.map((data, index) => {
                                                    return (
                                                        <SwiperSlide
                                                            key={index}
                                                        >
                                                            <CarUser
                                                                onclick={() => {

                                                                    setActiceCar(data);

                                                                }}

                                                                withMoreLinke={false}
                                                                key={index + 5000}
                                                                // inputDate={data.InputDate}
                                                                showDetails={false}
                                                                active={data.slug == acticeCar?.slug}
                                                                id={data.slug}
                                                                img={data.image ? data.image : '/assets/images/eparts/default.png'}
                                                                name={((lang == "ar" ? data?.brand : data?.brandEn) + " " + (lang == "ar" ? data?.group : data?.groupEn))}
                                                                brand={data.brand}
                                                                fromToYear={data?.fromYear + "-" + data?.toYear}

                                                            // link={"/user/vehicles/privateVehicles/" + data.CarID + "/" + data.FetchType}
                                                            />

                                                        </SwiperSlide>
                                                    );
                                                })
                                            }

                                        </Swiper>
                                    </div>
                                </div>
                                : null
                        }
                    </div>

                    {
                        offer &&
                        <div className="banner-info-flex">
                            <div className="banner-info-flex-subtitle">
                                <h3 className={"text-20"}>
                                    {banner?.type}
                                </h3>
                            </div>
                            <div className="banner-info-flex-text">
                                <h1 className={"text-30"}>
                                    {banner?.title}
                                </h1>
                                <p className={"text-20 mt-4 desc"}>
                                    {banner?.excerpt}
                                </p>

                                {
                                    banner?.endDate ?
                                        <div className={"banner-date my-10"}>
                                            <p>{trans("offers:end-in")}</p>
                                            <Moment duration={now()}
                                                format={"D"}
                                                date={banner?.endDate}
                                                className={"text-24"} />
                                            <p>{trans("offers:day")}</p>
                                            <p>|</p>
                                            <p>{trans("offers:date")}</p>
                                            <Moment className={"text-24"} format={"DD/MM/YYYY"} date={banner?.endDate} />
                                        </div>
                                        : null
                                }

                            </div>


                            <div className={"py-2  w-100 nice-border-top-section"}>

                                {
                                    packages && packages.length > 0 &&
                                    packages?.map((data, index) => {

                                        return (

                                            <div className={"box-package-detail  nice-shadow-1 p-5 my-5 " + (data?.id == acticePackage?.id ? "active" : "")} key={index}
                                                onClick={() => {

                                                    setActiceCar(null);
                                                    if (acticePackage?.id == data?.id) {
                                                        setActicePackage(null);
                                                        return;
                                                    }
                                                    setActicePackage(data);


                                                }}

                                            >
                                                <div className={"box-package-detail-name "}>
                                                    <h3 className={"text-24 font-bold text-line-clamp text-line-clamp-2"}>{data?.title}</h3>
                                                    <div className="box-package-detail-name-info">

                                                        {
                                                            offer?.reservationOnly == true ?
                                                                ""
                                                                :
                                                                <h5 className={"text-24 fond-bold text-primary px-4"}>
                                                                    <Price price={data?.price} />
                                                                </h5>
                                                        }



                                                    </div>

                                                </div>

                                                <div className={"box-package-detail-desc "}>

                                                    <h3 className={"text-16 font-bold text-gray text-line-clamp text-line-clamp-1"}>{trans("offers:vehicles_supported")}:</h3>
                                                    <h3 className={"text-16 font-bold text-line-clamp text-line-clamp-2"}>{data?.desc}</h3>
                                                </div>

                                            </div>

                                        );
                                    })
                                }

                            </div>


                            {
                                SupportedVehicles != null && SupportedVehicles.length > 0 ?

                                    <div className="nice-notsm-hidden w-100 nice-border-top-section">

                                        <div className={"my-2 mt-10 flex nice-flex-center-items panel-section "}>

                                            <h1 className="text-20 line-top text-super-gray font-bold">{trans("offers:vehicles_supported")}</h1>

                                            <div className="panel-content-top-tage ">
                                                <h2 className="mx-5 text-gray  text-30">{(acticePackage?.id ? SupportedVehicles.filter(a => a.packageId == acticePackage?.id) : SupportedVehicles)?.length}</h2>

                                            </div>
                                        </div>

                                        <div className={"my-10  p-8 pb-20 border-radius"}>
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={2}
                                                breakpoints={{
                                                    576: {
                                                        slidesPerView: 3,
                                                    },
                                                }}
                                                pagination
                                                resizeObserver={"true"}

                                                watchSlidesVisibility
                                                watchSlidesProgress
                                                autoplay={false}
                                                loop={false}
                                                observer={true}

                                            >
                                                {
                                                    (acticePackage?.id ? SupportedVehicles.filter(a => a.packageId == acticePackage?.id) : SupportedVehicles)?.map((data, index) => {
                                                        return (
                                                            <SwiperSlide
                                                                key={index}
                                                            >
                                                                <CarUser
                                                                    onclick={() => {

                                                                        setActiceCar(data);

                                                                    }}

                                                                    withMoreLinke={false}
                                                                    key={index + 5000}
                                                                    // inputDate={data.InputDate}
                                                                    showDetails={false}
                                                                    active={data.slug == acticeCar?.slug}
                                                                    id={data.slug}
                                                                    img={data.image ? data.image : '/assets/images/eparts/default.png'}
                                                                    name={((lang == "ar" ? data?.brand : data?.brandEn) + " " + (lang == "ar" ? data?.group : data?.groupEn))}
                                                                    brand={data.brand}
                                                                    fromToYear={data?.fromYear + "-" + data?.toYear}

                                                                // link={"/user/vehicles/privateVehicles/" + data.CarID + "/" + data.FetchType}
                                                                />

                                                            </SwiperSlide>
                                                        );
                                                    })
                                                }

                                            </Swiper>
                                        </div>
                                    </div>
                                    : null
                            }

                            {
                                offer &&
                                <div className="offer-button  flex flex-row  gap-1  items-cente mt-10 w-100 clearfix">

                                    <div className={`${offer?.reservationOnly == true ? 'w-100' : 'w-50'} `}>
                                        <SimpleButton
                                            classes="bg-primary text-white text-30"
                                            title={trans("info:reserve")}
                                            onClick={() => {

                                                handelshowPup("reserve");
                                            }}
                                        />

                                    </div>

                                    {
                                        offer?.reservationOnly == true ?
                                            <></>
                                            :
                                            <div className="w-50 ">
                                                <SimpleButton
                                                    classes="bg-black text-white text-20"
                                                    iconPath={'/assets/icons/cart.svg'}
                                                    title={trans("info:add_to_cart")}
                                                    onClick={(evt) => {


                                                        // handelshowPup("cart");


                                                        if (acticePackage?.price && acticeCar?.type) {

                                                            var objectCart =
                                                            {
                                                                id: acticeService?.ServiceID + '-' + acticeCar.type + '-' + acticeCar?.packageId,
                                                                productID: acticeService?.ServiceID,
                                                                name: acticeCar?.excerptAr,
                                                                nameEn: acticeCar?.excerptEn,
                                                                brand: acticeCar?.brand
                                                                , brandEn: acticeCar?.brandEn
                                                                , Tax: acticeCar?.Tax
                                                                , image: banner?.image
                                                                , Discount: acticeCar?.Discount
                                                                , group: acticeCar?.group
                                                                , groupEn: acticeCar?.groupEn
                                                                , BrandID: acticeCar?.brandID
                                                                , modelTypes: acticeCar?.DescriptionAr
                                                                , modelTypesEn: acticeCar?.DescriptionEn
                                                                , colorName: acticeCar['Color Araboc']
                                                                , colorNameEn: acticeCar['Color English']
                                                                , year: requestForm?.year
                                                                , modelTypeID: acticeCar.type
                                                                , MiniDownPayment: 0
                                                                , price: acticePackage?.price
                                                                , packageId: acticeCar?.packageId
                                                                , Orderdate: fullDatetime
                                                                , Type: "Bouquet",


                                                            }

                                                            changecartItem(objectCart?.productID, acticeCar?.ColorID, acticeCar.type, 1, null, acticeCar?.packageId, objectCart, fullDatetime);
                                                            //   addToCart(
                                                            //       {
                                                            //           id: VIN+'-'+SerciveID,
                                                            //           ProductID:SerciveID,
                                                            //           name:VIN,
                                                            //           price: infoPrice[0].Price,
                                                            //           Type:"service",

                                                            //       }
                                                            //   )
                                                            setShowPopup(false);

                                                        } else {
                                                            // toast.warning(trans("info:can_reserve_service"));

                                                            toast.info(trans("info:select_car"), {
                                                                // style: { left: evt.target.offsetLeft ,top: evt.target.offsetTop },
                                                                toastId: "1",
                                                                // transition: "Zoom",
                                                                // bodyStyle:{ top: "10em" ,left:"5em"}
                                                            });

                                                            // update a toast
                                                            // toast.update("1", {
                                                            //     content:trans("info:select_car")
                                                            // });

                                                        }
                                                    }} />
                                            </div>
                                    }

                                </div>
                            }

                            {
                                offer?.allowInstallments &&
                                <div className="w-100 my-10 nice-border-top-section">
                                    <h1 className="text-20  font-bold">{trans("info:buy-now-pay-later")}</h1>
                                    <div className=" flex flex-row  items-cente my-5 gap-2">


                                        <div className={' border-radius-sm p-5 box-shadow-sm nice-border-tabby flex-column  w-50  text-center bg-white nice-pointer'}
                                            onClick={() => {


                                                setShowPopupPay("tabby");
                                            }}

                                        >
                                            <img className="w-30 h-rem-3" src="/assets/icons/tabby.png" />
                                            <div className="w-100 flex my-2 flex-row  gap-1 nice-flex-center">
                                                <h3 className="text-20  font-family"> {trans("info:pay-4-interest-free-payments")}
                                                    <span className={" text-20 text-bold"}>  {(acticePackage?.price / 4) ? <>{(lang == "ar" ? " بقيمة " : " of ")} <Price price={(acticePackage?.price / 4)} /> </> : null} </span>

                                                </h3>
                                                <img className={"w-10 h-rem-2 " + (lang == "en" ? "rotate-180" : "")} src="/assets/icons/arrow-right.svg" />
                                            </div>
                                        </div>



                                        <div className={' border-radius-sm p-5 box-shadow-sm nice-border-tamara flex-column w-50  text-center bg-white nice-pointer'}

                                            onClick={() => {


                                                setShowPopupPay("tamara");
                                            }}
                                        >

                                            {

                                            }
                                            <img className="w-30 h-rem-3" src={`/assets/icons/${lang == "ar" ? "Ar-tamara.svg" : "En-tamara.svg"}`} />


                                            <div className="w-100 my-2 flex flex-row  gap-1 nice-flex-center">
                                                <h3 className="text-20  font-family"> {trans("info:pay-4-interest-free-payments")}
                                                    <span className={" text-20 text-bold"}>
                                                        {(acticePackage?.price / 4) ? <>{(lang == "ar" ? " بقيمة " : " of ")} <Price price={(acticePackage?.price / 4)} /> </> : null}
                                                    </span>

                                                </h3>
                                                {/* <tamara-widget type="tamara-summary" amount='350' inline-type='0' inline-variant='text' config='{"badgePosition":"endLine","showExtraContent":""}' > */}

                                                <img className={"w-10 h-rem-2 " + (lang == "en" ? "rotate-180" : "")} src="/assets/icons/arrow-right.svg" style={{ width: "4rem", height: "2rem" }} />
                                                {/* </tamara-widget> */}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            }

                            {
                                (offer?.content) &&
                                <div className="panel-section panel-white bg-theme-up w-100">
                                    <div className={`panel-content   ${(indexPanel == "1000") ? "show-panel-info" : ""}`}>
                                        <div className="panel-content-top px-5"

                                            onClick={(e) => setIndexPanel(indexPanel == "1000" ? "000" : "1000")}

                                        >
                                            <h1 className="text-30   text-super-gray font-bold">{trans("offers:offer")}</h1>
                                            <img src="/assets/icons/arrow.svg" />
                                        </div>

                                        <div className="panel-content-body">
                                            <div className="mx-10 my-10">

                                                {
                                                    offer?.content ?
                                                        <div className={"new-lines offer-detail-data"}>
                                                            <ul>
                                                                {
                                                                    offer?.content?.split('\n')?.map((item, index) => {

                                                                        return (
                                                                            item && item.trim() &&
                                                                            <li className={"text-20 font-bold"} key={index}>
                                                                                {item}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>

                                                        </div>
                                                        : null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }


                        </div>

                    }




                </div>
            </div>


            <div className={"container mb-32  nice-border-top-section"}>
                {/* <Breadcrumb className={"mt-16 "}
                    links={[
                        {
                            name: trans("common:offers"),
                            to: "/offers"
                        }, {
                            name: banner?.title
                        }
                    ]}
                /> */}
                {
                    // packages?.length === 0 ?
                    <OfferDetail
                        desc={offer?.content}
                        terms={offer?.name}
                        slug={slug}
                        offerId={banner?.id}
                        supportedCars={SupportedVehicles}
                        SupportedServices={SupportedServices}
                        SupportedMainServices={SupportedMainServices}
                        SupportedLastsServices={SupportedLastsServices}
                        packages={packages}
                        acticePackage={acticePackage}
                        handelActiceCar={(value) => {
                            setActiceCar(value);
                            var pac = packages?.filter(a => a.id == value?.packageId)[0];
                            if (pac) {
                                setActicePackage(pac);
                            }
                            setShowPopup(true);

                        }}
                        handelActiceService={(value) => {
                            // setActiceService(value)
                        }}

                        handleActicePackage={(value) => {
                            setActicePackage(value);
                            setActiceCar(null);
                            setShowPopup(true);

                        }}

                    />
                    // : null
                }
                {/* {
                    packages?.map((data, index) => {
                        var cars = [];
                        if (packageSupportedCars != null)
                            packageSupportedCars
                                .map((car) => {
                                    if (car.packageId == data.id)
                                        cars.push(car);
                                });
                        return (
                            <OfferPackage
                                key={index}
                                slug={slug}
                                title={data?.title}
                                desc={data?.desc}
                                terms={data?.terms}
                                price={data?.price}
                                image={data?.image}
                                offerDate={banner?.endDate}
                                offerId={banner?.id}
                                additional={data?.additional}
                                supportedCars={cars}
                            />
                        );
                    })
                } */}

            </div>

            <Popup show={showPopup}>
                <div className="offer-popup nice-flex nice-flex-center">
                    <div className="offer-popup-container p-4">
                        <div className="offer-popup-container-header">
                            <h1 className="text-balck text-bold text-24 text-center">{trans("info:request-offer")}</h1>
                            <span className='offer-popup-container-header-close text-block text-gray' onClick={() => {
                                setShowPopup(false)
                            }}></span>
                        </div>
                        <div className="offer-popup-container-header-title my-10 px-20">

                            <div className="offer-popup-container-header-title-subtitle">
                                <h3 className={"text-20"}>
                                    {banner?.type}
                                </h3>
                            </div>
                            <div className="offer-popup-container-header-title-text">
                                <h1 className={"text-30"}>
                                    {banner?.title}
                                </h1>
                                <p className={"text-16 mt-4 desc"}>
                                    {banner?.excerpt}
                                </p>

                            </div>

                        </div>
                        <div className="ruler-2 mb-5"></div>


                        <div className="flex flex-column nice-gap-3rem px-5 ">
                            {

                                !isLogin ?

                                    <>


                                        <>
                                            <div className="flex flex-row grid-2-cols nice-gap-2rem ">
                                                <>
                                                    <div className="basis-2/4 grow">

                                                        <InputField

                                                            errorLabel={error?.firstName}
                                                            placeholder={trans("form:first-name")}
                                                            value={requestForm.firstName}
                                                            length={255}
                                                            onChangeValue={
                                                                (e) => {
                                                                    const val = { ...requestForm };
                                                                    val.firstName = e.target.value;
                                                                    setRequestForm(val);
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                </>
                                                <>

                                                    <div className="basis-2/4 grow">
                                                        <InputField

                                                            errorLabel={error?.lastName}
                                                            placeholder={trans("info:surname")}
                                                            value={requestForm.lastName}
                                                            length={255}
                                                            onChangeValue={
                                                                (e) => {
                                                                    const val = { ...requestForm };
                                                                    val.lastName = e.target.value;
                                                                    setRequestForm(val);
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            </div>
                                        </>

                                        <>
                                            <div className="grow">
                                                <PhoneDropDown
                                                    title={trans("form:phone")}
                                                    errorLabel={error?.phone}
                                                    onChangeValue={(countryCode, phoneNumber) => {

                                                        const val = { ...requestForm };
                                                        if (phoneNumber)
                                                            val.phone = `+${countryCode}${phoneNumber}`;
                                                        else
                                                            val.phone = "";
                                                        setRequestForm(val);
                                                    }}
                                                />
                                            </div>
                                        </>

                                        <div className="grow">
                                            <InputField
                                                value={requestForm.email}
                                                type='email'
                                                onChangeValue={(e) => {
                                                    const val = { ...requestForm };
                                                    val.email = e.target.value;
                                                    setRequestForm(val);
                                                }}
                                                errorLabel={error?.email}
                                                placeholder={trans("form:email")}
                                                name='email'
                                            />
                                        </div>
                                    </>

                                    :
                                    null
                            }

                            {
                                <>

                                    {
                                        (isLogin || (requestForm?.phone && requestForm?.lastName && requestForm?.firstName)) &&

                                        <>
                                            <div className="flex flex-row grid-2-cols nice-gap-2rem ">


                                                <div className="basis2/4 grow">

                                                    <DropDownInput1
                                                        title={trans("info:model")}
                                                        errorLabel={error?.model}
                                                        options={[
                                                            ...(SupportedVehicles.filter(a => a.packageId == acticePackage?.id)).map(B => {
                                                                return { value: B.slug, text: ((lang == "ar" ? B?.brand : B?.brandEn) + " " + (lang == "ar" ? B?.group : B?.groupEn)) };
                                                            })]}
                                                        onSelect={(value) => {

                                                            console.log("onSelect:", value);
                                                            const val = { ...requestForm };
                                                            val.model = value;
                                                            val.year = null;
                                                            setRequestForm(val);

                                                            var car = (SupportedVehicles.filter(a => a.slug == value))[0];


                                                            if (car) {

                                                                setActiceCar(car);
                                                            }

                                                        }}

                                                    />
                                                </div>

                                                <div className="basis2/4 grow">

                                                    <DropDownInput1
                                                        title={trans("info:vehicle_year")}
                                                        errorLabel={error?.year}
                                                        options={[...years]}
                                                        selectedValue={requestForm?.year}
                                                        onSelect={(value) => {
                                                            const val = { ...requestForm };
                                                            val.year = value + "";
                                                            setRequestForm(val);
                                                        }}

                                                    />
                                                </div>
                                            </div>



                                            <div className="flex flex-row grid-2-cols nice-gap-2rem ">


                                                <div className="basis2/4 grow">

                                                    <InputField
                                                        title={trans("info:meter-reading")}
                                                        errorLabel={error?.meterReading}

                                                        placeholder={trans("info:meter-reading")}
                                                        type='number'
                                                        onChangeValue={(e) => {
                                                            const val = { ...requestForm };
                                                            val.meterReading = e.target.value;
                                                            setRequestForm(val);

                                                        }} />
                                                </div>

                                                <div className="basis2/4 grow">

                                                    <InputField
                                                        errorLabel={error?.VIN}
                                                        title={trans("form:structure-no") + '(VIN)'}
                                                        placeholder={trans("form:structure-no") + '(VIN)'}
                                                        onChangeValue={(e) => {
                                                            const val = { ...requestForm };
                                                            val.VIN = e.target.value;
                                                            setRequestForm(val);

                                                        }} />
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {

                                        ((requestForm?.meterReading && requestForm?.year && requestForm?.model)) &&
                                        <>
                                            <div className="flex flex-row grid-2-cols nice-gap-2rem ">


                                                <div className="grow">
                                                    <div className="basis-2/4 grow">

                                                        <DatePicker
                                                            // showIcon
                                                            isClearable={!!requestForm.date}

                                                            className=" w-100"

                                                            selected={requestForm.date}
                                                            onChange={async (value) => {
                                                                const val = { ...requestForm };
                                                                val.date = value;
                                                                val.time = null;
                                                                setRequestForm(val);
                                                                setListTimeAvailable([]);

                                                                if (val.date) {
                                                                    await webAvailableReshours(moment(value).format('yyyy-MM-DD'));
                                                                }
                                                            }}
                                                            filterDate={(date) => {
                                                                const today = new Date();
                                                                const yesterday = new Date(today);
                                                                yesterday.setDate(today.getDate() - 1);
                                                                const day = date.getDay();
                                                                return (day === 0 || day === 1 || day === 2 || day === 3 || day === 4 || day === 6)
                                                                    && (new Date(date).setHours(0, 0, 0) > yesterday.setHours(0, 0, 0)); // Sunday (0), Saturday (6)

                                                            }}
                                                            placeholderText={trans("form:maintenance-date")}
                                                            dateFormat="yyyy-MM-dd"
                                                            customInput={<DateInput3
                                                                value={moment(requestForm.date).format('yyyy-MM-DD')}
                                                            />}
                                                        />
                                                    </div>
                                                    <ErrorLabel errorTitle={error?.date} />
                                                </div>

                                                <div className="grow">
                                                    <div className="basis-2/4 grow">
                                                        <DatePicker

                                                            includeTimes={listTimeAvailable.map((val) => {
                                                                const time = new Date();
                                                                let res;

                                                                if (requestForm.date && new Date(requestForm.date).toDateString() === new Date().toDateString()) {
                                                                    if (val > new Date().getHours()) {
                                                                        res = time.setHours(val, 0, 0);
                                                                    }
                                                                } else if (requestForm.date && new Date(requestForm.date) > new Date()) {
                                                                    res = time.setHours(val, 0, 0);
                                                                } else {
                                                                    res = time.setHours(val, 0, 0);
                                                                }

                                                                return res;
                                                            })}
                                                            isClearable={!!requestForm.time}
                                                            selected={requestForm.time}
                                                            onChange={(value) => {

                                                                const val = { ...requestForm };
                                                                val.time = value;
                                                                setRequestForm(val);
                                                            }}
                                                            className="w-100"

                                                            placeholderText={trans("form:maintenance-time")}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={60}
                                                            timeInputLabel={trans("form:maintenance-time")}

                                                            timeCaption={trans("form:maintenance-time")}
                                                            // withPortal
                                                            dateFormat="hh:mm aa"
                                                            // showTimeInput
                                                            customInput={<DateInput3 />}
                                                        />
                                                    </div>
                                                    <ErrorLabel errorTitle={error?.time} />
                                                </div>


                                            </div>



                                            {
                                                <>
                                                    <DropDownInput
                                                        title={trans("info:branch")}
                                                        errorLabel={error?.SiteID}
                                                        options={[
                                                            ...Branches.map(B => {
                                                                return { value: B.SiteID, text: lang === 'ar' ? B.DescriptionAr : B.DescriptionEn };
                                                            })]}

                                                        selectedValue={Branches?.length == 1 ? Branches[0]?.SiteID : requestForm?.SiteID}
                                                        onSelect={(value) => {

                                                            console.log("requestForm:", requestForm);
                                                            console.log("onSelect:", value);
                                                            const val = { ...requestForm };
                                                            val.SiteID = value;
                                                            setRequestForm(val);
                                                        }}

                                                    />


                                                    <TextArea
                                                        row={5}
                                                        title={trans("info:notes")}
                                                        onChangeValue={(data) => {
                                                            setNote(data);
                                                        }}
                                                    />


                                                </>
                                            }

                                        </>
                                    }

                                </>
                            }

                        </div>



                        <div className={"py-2   gap-2 w-100 px-20"}>

                            {
                                packages && packages.length > 0 &&
                                packages?.filter(a => a?.id == acticePackage?.id).map((data, index) => {

                                    return (

                                        <div className={"box-package-detail  nice-shadow-1 p-5 my-10 " + (data?.id == acticePackage?.id ? "active" : "")} key={index}
                                            onClick={() => {

                                                setActicePackage(data);

                                            }}

                                        >
                                            <div className={"box-package-detail-name "}>
                                                <h3 className={"text-24 font-bold text-line-clamp text-line-clamp-2"}>{data?.title}</h3>
                                                <div className="box-package-detail-name-info">

                                                    {
                                                        offer?.reservationOnly == true ?
                                                            ""
                                                            :
                                                            <h5 className={"text-24 fond-bold text-primary px-4"}>
                                                                <Price price={data?.price} />
                                                            </h5>

                                                    }
                                                </div>

                                            </div>

                                            <div className={"box-package-detail-desc "}>

                                                <h3 className={"text-16 font-bold text-gray text-line-clamp text-line-clamp-1"}>{trans("offers:vehicles_supported")}:</h3>
                                                <h3 className={"text-16 font-bold text-line-clamp text-line-clamp-2"}>{data?.desc}</h3>
                                            </div>

                                        </div>

                                    );
                                })
                            }

                        </div>
                        {/* 
                        {
                            SupportedVehicles != null && (SupportedVehicles.filter(a => a.packageId == acticePackage?.id))?.length > 0 ?

                                <>  <div className={"my-10 mt-10 flex nice-flex-center-items panel-section"}>

                                    <h1 className="text-20 line-top text-super-gray font-bold">{trans("offers:vehicles_supported")}</h1>
                                    <div className="panel-content-top-tage ">
                                        <h2 className="mx-5 text-gray  text-30">{(acticePackage?.id ? SupportedVehicles.filter(a => a.packageId == acticePackage?.id) : SupportedVehicles)?.length}</h2>

                                    </div>
                                </div>

                                    <div className={"my-10 bg-secondary-lighter p-8 pb-20 border-radius"}>
                                        <Swiper
                                            spaceBetween={20}
                                            slidesPerView={2}
                                            breakpoints={{
                                                576: {
                                                    slidesPerView: 3,
                                                },
                                            }}
                                            pagination
                                            resizeObserver={"true"}

                                            watchSlidesVisibility
                                            watchSlidesProgress
                                            autoplay={false}
                                            loop={false}
                                            observer={true}

                                        >
                                            {
                                                (acticePackage?.id ? SupportedVehicles.filter(a => a.packageId == acticePackage?.id) : SupportedVehicles)?.map((data, index) => {
                                                    return (
                                                        <SwiperSlide
                                                            key={index}
                                                        >


                                                            <CarUser
                                                                onclick={() => {

                                                                    setActiceCar(data);

                                                                }}

                                                                withMoreLinke={false}
                                                                key={index + 5000}
                                                                // inputDate={data.InputDate}
                                                                showDetails={false}
                                                                active={data.slug == acticeCar?.slug}
                                                                id={data.slug}
                                                                img={data.image ? data.image : '/assets/images/eparts/default.png'}
                                                                name={(data?.brand + " " + data?.group)}
                                                                brand={data.brand}
                                                                fromToYear={data?.fromYear + "-" + data?.toYear}
                                                            // link={"/user/vehicles/privateVehicles/" + data.CarID + "/" + data.FetchType}
                                                            />

                                                        </SwiperSlide>
                                                    );
                                                })
                                            }

                                        </Swiper>
                                    </div>
                                </>
                                : null
                        } */}


                        {/* {
                            SupportedLastsServices != null && SupportedLastsServices.length > 0 ?
                                <>
                                    <div className={"my-10 mt-20  flex nice-flex-center-items "}>

                                        <h1 className="text-20 line-top text-super-gray font-bold">{trans("offers:services_supported")}</h1>
                                        <div className="panel-content-top-tage ">
                                            <h2 className="mx-5 text-gray  text-20">{SupportedLastsServices?.length} {" "} {trans("global:services")}</h2>

                                        </div>
                                    </div>
                                    {
                                        <div className="more-services nice-apply-theme bg-secondary-lighter border-radius py-2">
                                            <div className="my-10 nice-apply-theme ">
                                                <h3 className="text-20 text-center nice-apply-theme font-bold"></h3>
                                                <Swiper
                                                    spaceBetween={5}
                                                    slidesPerView={2}
                                                    breakpoints={{
                                                        576: {
                                                            slidesPerView: 4,
                                                        },
                                                    }}
                                                    className={"mr-8 my-8  nice-apply-theme"}
                                                    resizeObserver={"true"}
                                                >

                                                    {SupportedLastsServices?.map((_val, _index) => {
                                                        return (

                                                            <SwiperSlide key={_index} className={"px-3"}>
                                                                <div onClick={() => {
                                                                    // setActiceService(_val);

                                                                }}>
                                                                    <CustomItemServices
                                                                        // title={trans("mobileCommon:finance")}
                                                                        title={lang == "ar" ? _val.ServiceNameAR : _val.ServiceName}
                                                                        borderCSS={_val.ServiceID == acticeService?.ServiceID ? "border-primary-box?? " : ""
                                                                        }
                                                                    />
                                                                </div>
                                                            </SwiperSlide>

                                                        );
                                                    })}



                                                </Swiper>
                                            </div>


                                        </div>


                                    }
                                </>
                                : null
                        } */}




                        <div className="flex flex-center nice-row mt-20 px-5">
                            <div className={`${offer?.reservationOnly == true ? 'nice-col-md-12' : 'nice-col-md-6'} mb-20`}>
                                <div className="nice-bg-btn bg-primary text-white text-30" onClick={() => {

                                    //  const isLogin =  getData('isAuthenticated')
                                    //  if(!isLogin){
                                    //      setVehicales([]);
                                    //      toast.error("يرجى تسجيل الدخول اولا لجلب المركبات الخاصة بك");
                                    //      return;
                                    //  }

                                    var cleanData = {};
                                    try {
                                        cleanData = JSON.parse(getData(User_Data_Key, true));
                                    } catch (e) {


                                    }

                                    // if (acticePackage?.price && acticeCar?.type)
                                    SendResreve(acticeCar?.type, acticeService?.mainService, acticeService?.subService1, acticeService?.subService2, acticeService?.ServiceID, null, cleanData?.custID, acticePackage?.price);



                                }}>
                                    {trans("info:reserve")}
                                </div>
                            </div>

                            {
                                offer?.reservationOnly == true ?
                                    ""
                                    :
                                    <div className="nice-col-md-6 mb-20">
                                        <div className={"nice-bg-btn   text-30 text-white gap-3r " + ((acticePackage?.price && acticeCar?.type) ? "bg-black" : "bg-gray")} onClick={() => {

                                            if (acticePackage?.price && acticeCar?.type) {

                                                var objectCart =
                                                {
                                                    id: acticeService?.ServiceID + '-' + acticeCar.type + '-' + acticeCar?.packageId,
                                                    productID: acticeService?.ServiceID,
                                                    name: acticeCar?.excerptAr,
                                                    nameEn: acticeCar?.excerptEn,
                                                    brand: acticeCar?.brand
                                                    , brandEn: acticeCar?.brandEn
                                                    , Tax: acticeCar?.Tax
                                                    , image: banner?.image
                                                    , Discount: acticeCar?.Discount
                                                    , group: acticeCar?.group
                                                    , groupEn: acticeCar?.groupEn
                                                    , BrandID: acticeCar?.brandID
                                                    , modelTypes: acticeCar?.DescriptionAr
                                                    , modelTypesEn: acticeCar?.DescriptionEn
                                                    , colorName: acticeCar['Color Araboc']
                                                    , colorNameEn: acticeCar['Color English']
                                                    , year: requestForm?.year
                                                    , modelTypeID: acticeCar.type
                                                    , MiniDownPayment: 0
                                                    , price: acticePackage?.price
                                                    , packageId: acticeCar?.packageId
                                                    , Orderdate: fullDatetime
                                                    , Type: "Bouquet",

                                                }

                                                changecartItem(objectCart?.productID, acticeCar?.ColorID, acticeCar.type, 1, null, acticeCar?.packageId, objectCart, fullDatetime, requestForm?.year);
                                                //   addToCart(
                                                //       {
                                                //           id: VIN+'-'+SerciveID,
                                                //           ProductID:SerciveID,
                                                //           name:VIN,
                                                //           price: infoPrice[0].Price,
                                                //           Type:"service",

                                                //       }
                                                //   )
                                                setShowPopup(false);

                                            } else {
                                                toast.warning(trans("info:select_car"));

                                            }
                                        }
                                        }>
                                            {trans("info:add_to_cart")} <img src="/assets/icons/cart.svg" className="filtter-primary" alt="hassan jameel cart" />
                                        </div>
                                    </div>
                            }
                        </div>

                    </div>

                </div>
            </Popup>
            <Popup show={showPopupPay == null ? false : true}>
                <div className="offer-popup nice-flex nice-flex-center ">
                    <div className={"offer-popup-container p-4   " + (showPopupPay == "tabby" ? "h-80 " : "h-90")}>
                        <div className="offer-popup-container-header">
                            <span className='offer-popup-container-header-close text-block text-gray' onClick={() => {
                                setShowPopupPay(null)
                            }}></span>
                        </div>

                        {
                            showPopupPay == "tabby" ?
                                // <div className="tamara-faqs ">
                                <iframe className={"h-90 w-100"} src={`https://checkout.tabby.ai/promos/product-page/installments/${lang}/${(acticePackage && acticePackage?.price) ? "?price=" + acticePackage?.price : "?price=0"}&currency=SAR`}  ></iframe>
                                // </div>
                                :

                                // <div className="tamara-faqs ">
                                <iframe className={"h-90 w-100"} src={`https://cdn.tamara.co/widget-v2/tamara-widget.html?lang=${lang}&widgetType=information-widget&public_key=98ca3489-55a5-4392-9010-671549dbfba2${(acticePackage && acticePackage?.price) ? "&amount=" + acticePackage?.price : "&amount=0"}&paymentType=installment`} ></iframe>
                            // </div>


                        }


                    </div>
                </div>
            </Popup>


        </Fragment >
    );
}