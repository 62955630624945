import { Link } from "react-router-dom";
import { useContext } from "react";
import ConfirmContext from "../../../../utils/confirmContext";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
export function NavCarCard({ img, name, link, Year, carData, ...prop }) {
    const context = useContext(ConfirmContext);
    const { t: trans } = useTranslation(["info"]);
    const history = useHistory();
    return (
        <Link {...prop} to={link} onClick={prop.onClick}>
            <div className={"car"}>

                <img src={img} alt={name} onError={e => e.target.style.display = 'none'} />
                <span className={"text-20 "}>{name}</span>
                <div className={"nav-car-link"}>
                    <Link to={"#"} onClick={() => {

                        context.onShow(
                            {
                                modelYear: carData?.year,
                                displayConfirm: true,
                                title: carData?.name,
                                image: carData?.img,
                                backGround: carData?.brandImage,
                                brand: carData?.brand,
                                brandID: carData?.brandID,
                                isHyper: carData?.hybird,
                                isNew: carData?.newCar,
                                defaultColorID: carData?.colorID,
                                cartDetails: {
                                    id: carData?.ProductId,
                                    name: name,
                                    price: carData?.minPrice,
                                    brand: carData?.brand,
                                    image: img,
                                    Tax: carData?.Tax,
                                    Discount: carData?.RateCareDiscount,
                                    Type: "car",
                                    modelTypes: carData?.modelTypes
                                },
                                carOrder: {
                                    vehicle: carData?.ProductId,
                                    group: carData?.group
                                }
                            });
                    }}>
                        <img data-src="/assets/icons/cart.svg"
                            class="size-30 filter-primary ls-is-cached" alt="hassan jameel carts " src="/assets/icons/cart.svg" />{trans("info:buy")}
                    </Link>
                    <Link
                        to={`/order-car?vehicle=${carData?.productId}&model=${carData?.year}&type=${carData?.type}&color=${carData?.colorID}`}
                        onClick={() => {

                            // if (carData && carData?.ProductID)
                            //     history.push(`/order-car?vehicle=${carData?.ProductID}&model=${carData.year}&type=${carData.type}&color=${carData?.ColorID}`);

                        }} >
                        {trans("info:purchase_order")}
                    </Link>

                </div>
            </div>
        </Link>
    );
}