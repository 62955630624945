import moment from "moment";
import { useState, useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory, useParams } from "react-router";
import axios from 'axios';
import config from "../../configuration/config.json";
import Popup from "../../User/popup";
const PurchaseAlertPage = () => {
  const { t: trans, i18n } = useTranslation(["new", "cartFile"]);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const lang = localStorage.getItem('i18nextLng');
  const languageDir = 'language-' + lang;
  const [doneDate, setDoneDate] = useState(new moment().format('yyyy-MM-DD hh:mm A'));
  const { id } = useParams();
  const popRef = useRef(null);
  const [showFinalPopup, setShowFinalPopup] = useState(false);
  const history = useHistory();
  useEffect(() => {

    function handleClickOutside(event) {
      if (popRef.current && !popRef.current.contains(event.target) && showFinalPopup === true) {
        // setShowFinalPopup(false);
        history.push('/');

      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFinalPopup]);

  useEffect(() => {
    setShowFinalPopup(true);
    //اجراء التاكد من حالة الدفع وتحديث حقل isPayment
    //Site_Carts_PaymentStatus
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    axios.request({
      url: config.controllURL + "api/Payment/PaymentStatus", headers: myHeaders,
      params: {
        ID: id
      },
      method: "POST", responseType: "json"
    }).then(function (response) {

      console.log('PaymentStatus response>>>>>>>>>:', response);

    }
    ).then((response2) => {
      console.log('response2', response2);
    })
      .catch(error => console.log('error', error));
    // if(id){
    //  axios.request({
    //    baseURL: config.controllURL,
    //   url: lang + "/api/cart/isPayed",
    //   params:{
    //     id:id
    //   },
    //   method: "put",
    // }).then(response=>{
    //   }).catch(error=>{
    //     console.log(error);
    //   })
    // }
  }, []);
  return (<div className={"purchase-alert "}>
    {/* <img src="/assets/images/finalized.svg" className="object-contain object-full" /> */}
    <div className={"purchase-alert-box " + languageDir}>
      {/* <date className="text-30 text-amazing">{doneDate}</date> */}
    </div>




    <Popup show={showFinalPopup}>
      <div className="w-full h-full flex justify-center items-center" >
        <div className="cart-finalized" ref={popRef}>
          <div className="cart-finalized-container flex flex-col">

            <div className="cart-finalized-container-img flex flex-col">
              <img src="/assets/images/finalized.svg" />
            </div>

            <div className="flex flex-wrap justify-center">
              <span className="text-20 font-bold text-black mx-2 unselectable ">{trans("new:thank_to_choose")}</span>
              <span className="text-20 font-bold text-primary mx-2"> {trans("new:HJ_cars")}</span>
            </div>

            {
              <>
                <div className="flex flex-row justify-center">
                  <span className="text-20 font-bold  mx-2"> {trans("new:request-received")}</span>
                </div>
                <div className="flex flex-row justify-center">
                  <span className="text-20 font-bold  mx-2"> {trans("new:You-will-be-contacted")}</span>
                </div>

                {/* {

                  id &&
                  <div className="flex flex-row justify-center cart-finalized-code"
                    onClick={() => {

                      navigator.clipboard.writeText(id);
                    }}

                  >

                    <span className="text-20 font-bold text-white  mx-2"> {trans("new:order-number") + " :"}</span>
                    <span className="text-20 font-bold text-white  mx-2">{id && id}</span>
                    <img src="/assets/icons/copy.svg" alt='' />
                  </div>
                } */}

              </>

            }



            <div className="flex flex-center flex-col  mt-5 pt-5 w-80">
              <div className=" mb-5 w-100">
                <div className="nice-sm-btn cart-notification-go  bg-primary text-white text-16" onClick={() => {
                  history.push('/');

                }}>
                  {trans("cartFile:Continue_browsing")}
                </div>
              </div>
            </div>

          </div>


        </div>


      </div>
    </Popup>

  </div>)
}

export default PurchaseAlertPage;