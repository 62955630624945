import { concat } from "lodash";
// this function is used to format the number based on the contry currency code
export const numberFormat = (number, lang, showZero, classNameCurrency, displayCurrencyLiter = true, syntax = { code: 'SAR', locale: 'en' }) => {
    // const code = lang == 'en' ? ' SAR ' : ' ر.س ';
    if (number == null || number === undefined || number === 'null' || number === '' || ((number === '0' || number === 0) && showZero === false)) {

        return null;
    }

    if (displayCurrencyLiter) {// concat(
        return  (classNameCurrency ?
            <span key={Math.random} className={classNameCurrency}>
                {<CurrencyCode number={Intl.NumberFormat().format(number)} />}
            </span> :<CurrencyCode number={Intl.NumberFormat().format(number)} />)
            // ," ",Intl.NumberFormat().format(number));
    }
    return Intl.NumberFormat().format(number);
};


export const CurrencyCode = ({number}) => {
    return (
         (<i className="icon-icomoon-Saudi_Riyal_Symbol-2" >{number}</i>)
        //  <>{&#xe900;}</>
        // <i class="icon-Saudi_Riyal_Symbol-2"></i>
        // <span style={{ display: "inline-flex", alignItems: "center" }}>
        //     <svg
        //         xmlns="http://www.w3.org/2000/svg"
        //         viewBox="0 0 1124.14 1256.39"
        //         className="custom-svg"
        //         style={{ width: "1em", height: "1em", verticalAlign: "middle" }}
        //     >
        //         <defs>
        //             <style>
        //                 {`
        //       .cls-1 {
        //         fill: currentColor; /* يجعل اللون متوافق مع لون النص */
        //       }
        //     `}
        //             </style>
        //         </defs>
        //         <path className="cls-1" d="M699.62,1113.02h0c-20.06,44.48-33.32,92.75-38.4,143.37l424.51-90.24c20.06-44.47,33.31-92.75,38.4-143.37l-424.51,90.24Z" />
        //         <path className="cls-1" d="M1085.73,895.8c20.06-44.47,33.32-92.75,38.4-143.37l-330.68,70.33v-135.2l292.27-62.11c20.06-44.47,33.32-92.75,38.4-143.37l-330.68,70.27V66.13c-50.67,28.45-95.67,66.32-132.25,110.99v403.35l-132.25,28.11V0c-50.67,28.44-95.67,66.32-132.25,110.99v525.69l-295.91,62.88c-20.06,44.47-33.33,92.75-38.42,143.37l334.33-71.05v170.26l-358.3,76.14c-20.06,44.47-33.32,92.75-38.4,143.37l375.04-79.7c30.53-6.35,56.77-24.4,73.83-49.24l68.78-101.97v-.02c7.14-10.55,11.3-23.27,11.3-36.97v-149.98l132.25-28.11v270.4l424.53-90.28Z" />
        //     </svg>
        // </span>
    );
};
