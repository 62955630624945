import { Fragment, useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import Popup from "../../User/popup";
import { useParams, useHistory, useLocation } from "react-router";
import ErrorLabel from '../../components/error';
import { getCleanSlug } from '../../utils/slugManager';
import config from "../../configuration/config.json";
import { DateInput3 } from "../../components/_input/_dateInput_3";
import DatePicker from 'react-datepicker';
import { toast } from "react-toastify";
import PhoneDropDown from "../../components/_input/_phoneDropDown";
import { InputField } from "../../components/_input/_input";
import { DropDownInput } from "../../components/_input/_dropdown";
import { TextArea } from "../../components/_input/_textArea";
import { GetMessages, formValidate } from '../../utils/validation';
import { Price } from "../../components/__simple/_price";

import Joi from "joi";
import { getData, User_Data_Key } from "../../utils/localStorageManager";
export function OfferServiceReservationByID() {
    const popRef = useRef(null);
    let { slug } = useParams();
    const { t: trans, i18n } = useTranslation(["common", "offers", "extra", "eparts", "cartFile"]);
    const languageDir = 'language-' + localStorage.getItem('i18nextLng');
    const [isLogin, setLogin] = useState(false);
    const [Branches, setBranches] = useState([]);
    const [dataFullNum, setDataFullNum] = useState(0);
    const [indexPanel, setIndexPanel] = useState("0000");
    const [banner, setBanner] = useState({});
    const [orderReservation, setOrderReservation] = useState(null);
    const [Note, setNote] = useState("");
    const [resOrderID, setResOrderID] = useState(null);
    const [packages, setPackages] = useState([]);
    const [years, setYears] = useState([]);
    const [isSendeng, setIsSendeng] = useState(false);
    const [error, setError] = useState({});
    const [showFinalPopup, setShowFinalPopup] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const [requestForm, setRequestForm] = useState({
        lastName: '',
        name: '',
        phone: '',
        email: '',
        subject: '',
        date: '',
        time: '',
        messageContent: '',
        myCarID: '',
        SiteID: null,
    });
    const LoginSchema = Joi.object({
        date: Joi.date().required().messages(GetMessages()),
        time: Joi.date().required().messages(GetMessages()),
        // VIN: Joi.string().required().messages(GetMessages()),
        meterReading: Joi.string().required().messages(GetMessages()),
        SiteID: Joi.string().required().messages(GetMessages()),
        year: Joi.string().required().messages(GetMessages()),
        model: Joi.string().required().messages(GetMessages())
    });
    const notLoginSchema = Joi.object({
        date: Joi.date().required().messages(GetMessages()),
        time: Joi.date().required().messages(GetMessages()),
        SiteID: Joi.string().required().messages(GetMessages()),
        name: Joi.string().required().messages(GetMessages()),
        phone: Joi.string().required().messages(GetMessages()),
        year: Joi.string().required().messages(GetMessages()),
        model: Joi.string().required().messages(GetMessages())
    });
    const [offer, setOffer] = useState(null);
    const [offerSupportedCars, setOfferSupportedCars] = useState([]);
    const [packageSupportedCars, setPackageSupportedCars] = useState([]);
    const [acticeService, setActiceService] = useState(null);
    const [acticeCar, setActiceCar] = useState(null);
    const [acticePackage, setActicePackage] = useState(null);
    let lang = (i18n.language || 'ar');
    useEffect(() => {
        callback();
        FetchOfferSupportedServicesSacondry();
        FetchOfferSupportedVehicles();
        GetBranch();
    }, []);

    useEffect(() => {

        try {
            window.tamaraWidgetConfig = {
                lang: lang,
                country: "SA",
                publicKey: "71258997-64ec-418a-9cba-0d3849fa219f"
            }


            window.TamaraFAQsFrame.render({ lang: lang })
        } catch (error) {

        }

        let data = {};

        try {
            data = JSON.parse(getData(User_Data_Key, true));
        } catch (error) {

        }
        try {

            if (getData('isAuthenticated'))
                setLogin(true);
        } catch (err) {
            //console.log(err);
            setLogin(false);
        }

    }, [lang]);

    useEffect(() => {

        function handleClickOutside(event) {
            if (popRef.current && !popRef.current.contains(event.target) && showFinalPopup === true) {
                setShowFinalPopup(false);
                setResOrderID(null);
                setOrderReservation(null);
                if (location.pathname)
                    history.push(location.pathname);

            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showFinalPopup]);

    const [fullDatetime, setFullDatetime] = useState(null);
    useEffect(() => {

        try {

            if (requestForm?.date) {

                var md = new Date(requestForm?.date);
                try {
                    if (requestForm?.time) {

                        var Hours = requestForm?.time.split(":");

                        md.setHours(Hours[0], Hours[1], 0);
                    }
                } catch (error) {

                }
                var datetime = new moment(md).format('yyyy-MM-DD HH:mm:ss')
                // var datetime = md.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replaceAll(',', '').replaceAll('/', '-');
                setFullDatetime(datetime);
                console.log("datetime:", datetime);
            }

        } catch (error) {
        }


    }, [requestForm]);
    useEffect(() => {

        var ly = [];

        try {
            if (acticeCar?.fromYear && acticeCar?.toYear) {

                ly.push({ value: acticeCar.fromYear, text: acticeCar.fromYear });
                var newy = parseInt(acticeCar.fromYear);
                for (var i = 0; i <= 30; i++) {

                    newy = parseInt(newy) + 1;

                    ly.push({ value: newy, text: newy });

                    if (newy >= acticeCar?.toYear) {

                        break;
                    }
                }
                setYears(ly);

            }
        } catch (error) {

        }


    }, [acticeCar]);

    const callback = useCallback(async () => {
        try {


            try {

                if ("caches" in window) {
                    let url = "detail-" + getCleanSlug();

                    // Opening that particular cache
                    const cacheStorage = await caches.open("OfferPage");
                    // Fetching that particular cache data
                    const cachedResponse = await cacheStorage.match(
                        url
                    );
                    var data = await cachedResponse?.json();
                    if (data) {
                        setBanner(data?.banner)
                        setPackages(data?.packages)
                        setOffer(data?.offer)
                        setOfferSupportedCars(data?.offerSupportedCars)
                        setPackageSupportedCars(data?.packagesSupportedCars)

                        if (data?.packages.length == 1) {

                            setActicePackage(data?.packages[0]);
                        }

                    }
                }

            } catch (error) {
            }
            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/offers/detail/" + getCleanSlug(),
                method: "get",
            });
            setBanner(response.data.banner)
            setPackages(response.data.packages)
            setOffer(response.data.offer)
            setOfferSupportedCars(response.data.offerSupportedCars)
            setPackageSupportedCars(response.data.packagesSupportedCars)

            if (response.data.packages.length == 1) {

                setActicePackage(response.data.packages[0]);
            }

            const Responsedata = new Response(JSON.stringify(response.data));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("OfferPage").then((cache) => {
                    cache.put("detail-" + getCleanSlug(), Responsedata);

                });
            }

        } catch (error) {
            console.log(error);
        }
    }, [lang, slug])

    const FetchOfferSupportedServicesSacondry = useCallback(async () => {
        try {
            const cslug = await getCleanSlug();
            await axios.post(
                config.baseURL + 'func',
                {
                    //Site_FetchOfferSupportedServices
                    "name": "Site_FetchOfferSupportedServices",
                    "values": {
                        "slug": cslug
                    }
                }
            ).then(function (response) {

                var Mainservice = [];
                var Lastservice = [];
                var MainserviceIDS = [];
                response.data?.map((item, index) => {

                    if (item?.ServiceID) {
                        if (!Lastservice.includes(item)) {
                            Lastservice.push(item);
                            MainserviceIDS.push(item?.subService2);
                        }
                    } else if (item?.subService2) {

                        if (!Mainservice.includes(item)) {

                            Mainservice.push(item);
                        }
                    }

                });
                Mainservice = Mainservice.filter(item => !MainserviceIDS.includes(item?.subService2))
                setSupportedMainServices(Mainservice);
                setSupportedLastsServices(Lastservice);
                if (Lastservice[0])
                    setActiceService(Lastservice[0]);

                setSupportedServices(response.data);
                setLoadingSupportedServices(false);


                const Responsedata = new Response(JSON.stringify(response.data));

                if ("caches" in window) {
                    // Opening given cache and putting our data into it
                    caches.open("OfferPage").then((cache) => {
                        cache.put("Site_FetchOfferSupportedCampaignTransServices-" + getCleanSlug(), Responsedata);

                    });
                }

            }).catch(function (error) {
                console.log(error);
                setLoadingSupportedServices(false);
            });

        } catch (error) {
        }

    }, []);
    const [SupportedServices, setSupportedServices] = useState([]);
    const [SupportedMainServices, setSupportedMainServices] = useState([]);
    const [SupportedLastsServices, setSupportedLastsServices] = useState([]);
    const [isLoadingSupportedServices, setLoadingSupportedServices] = useState(true);

    const [SupportedVehicles, setSupportedVehicles] = useState([]);
    const [isLoadingSupportedVehicles, setLoadingSupportedVehicles] = useState(true);
    const FetchOfferSupportedVehicles = useCallback(async () => {
        try {
            setLoadingSupportedVehicles(true);

            try {

                if ("caches" in window) {
                    let url = "Site_FetchOfferSupportedVehicles-" + getCleanSlug();

                    // Opening that particular cache
                    const cacheStorage = await caches.open("OfferPage");
                    // Fetching that particular cache data
                    const cachedResponse = await cacheStorage.match(
                        url
                    );
                    var data = await cachedResponse?.json();
                    if (data) {
                        setSupportedVehicles(data);
                        setLoadingSupportedVehicles(false);
                    }
                }

            } catch (error) {
            }

            await axios.post(
                config.baseURL + 'func',
                {
                    "name": "Site_FetchOfferSupportedVehicles",
                    "values": {
                        "slug": getCleanSlug()

                    }
                }
            ).then(function (response) {

                setSupportedVehicles(response.data);
                setLoadingSupportedVehicles(false);

                const Responsedata = new Response(JSON.stringify(response.data));

                if ("caches" in window) {
                    // Opening given cache and putting our data into it
                    caches.open("OfferPage").then((cache) => {
                        cache.put("Site_FetchOfferSupportedVehicles-" + getCleanSlug(), Responsedata);

                    });
                }

            }).catch(function (error) {
                console.log(error);
                setLoadingSupportedVehicles(false);
            });
        } catch (error) {
            console.log(error);
            setLoadingSupportedVehicles(false);
        }

    }, []);

    useEffect(() => {

        if (SupportedVehicles && SupportedVehicles.length > 0 && acticeCar?.brandID == null) {
            // Get the first brandID
            const firstBrandID = SupportedVehicles[0].brandID;
            // Check if all other brandIDs are the same as the first one
            const allSameBrand = SupportedVehicles.every(vehicle => vehicle.brandID === firstBrandID);
            // Return the brandID if all are the same, otherwise return null
            var Ac = acticeCar ?? {};
            if (Ac && allSameBrand) {
                Ac["brandID"] = firstBrandID;
                setActiceCar(Ac);
            }
        }else{


        }

    }, [SupportedVehicles,acticeCar]);
    function SendResreve(VIN, MainServiceID, subServiceID, TypeServiceID, ServecID, ModelCode, UserID, Amount) {
        var thisSchema = null;

        thisSchema = notLoginSchema;

        const result = formValidate(thisSchema, requestForm) ?? {};

        setError(result);

        console.log("result:>>>>:", result);
        if (Object.keys(result).length == 0 && isSendeng == false) {

            if (SupportedLastsServices?.length <= 0) {

                toast.warn(trans("offers:services_supported") + " (0)");
                return;
            }

            setIsSendeng(true);

            var ServicesList = "";
            SupportedLastsServices.map((a) => {

                ServicesList += a.ServiceID + ","


            });

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = {
                "VIN": requestForm?.VIN,
                "Model": acticeCar?.type,
                "Year": requestForm?.year ? requestForm?.year + "" : null,
                "CarType": acticeCar?.modelCode,
                "UserID": UserID ?? "",
                "TotalAmount": Amount ? Amount : 0,
                "MainServiceID": MainServiceID,
                "subServiceID": subServiceID,
                "TypeServiceID": TypeServiceID,
                "ServiceID": ServecID,
                "RequiredServicesList": ServicesList,
                "Note": Note,

                "MeterReading": requestForm?.meterReading,
                "FirstName": requestForm?.name,
                "phone": requestForm?.phone,
                "Email": requestForm?.email,
                "StoreID": requestForm?.SiteID,
                "Orderdate": moment(requestForm?.date).format('YYYY-MM-DD'),
                "OrderTime": moment(requestForm?.time).format('HH:mm:ss'),
                "PackageId": acticeCar?.packageId ? acticeCar?.packageId + "" : null
            };


            axios.request({
                url: config.controllURL + "api/Service/ServiceRequestAdd", headers: myHeaders,
                data: raw, method: "POST", responseType: "json"
            }).then(function (response) {
                //console.log("Service", response.data)
                if (response?.data?.OrderID) {
                    setResOrderID(response?.data?.OrderID);
                    setOrderReservation(raw);
                }


                setShowFinalPopup(true);

                toast.success(trans("info:reservation_completed_successfully"));

            }
            ).catch(error => {
                //console.log('error', error);
                setIsSendeng(false);
            }



            );

        } else {
            console.log("result>>>>>>>>>>>>>>>>>:", result);

        }
    }

    async function GetBranch() {

        const Body = {
            "name": "site_FetchMaintenanceBranches"
        };

        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {

                setBranches(response.data);


                setTimeout(() => {
                    if (response?.data?.length == 1) {

                        const val = { ...requestForm };
                        val.SiteID = response?.data[0]?.SiteID;
                        setRequestForm(val);
                    }
                }, 1000);

            })
            .catch(function (error) {
                //console.log(error);
            });


    }

    const [listTimeAvailable, setListTimeAvailable] = useState([]);
    // const [maxTime, setMaxTime] = useState(0);
    // const [minTime, setMinTime] = useState(0);

    const webAvailableReshours = async (date = '') => {
        const Body = {
            "name": "web_AvailableReshours",
            "values": {
                "Resdate": date
            }
        }

        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                console.log("web_AvailableReshours:response", response);
                if (response.status >= 200 && response.status < 299) {
                    console.log("web_AvailableReshours:response", response.statusText);
                    if (response?.data && response?.data.length != 0) {
                        response?.data.forEach((val, index) => {

                            setListTimeAvailable(_lstTime => [..._lstTime, parseInt(val?.AvailableHour)]);
                        });

                    }
                }
            })
            .catch(function (error) {
                console.log("web_AvailableReshours:Error", error);
                toast.warn(trans("form:please-choose-another-date"));
            });
    }

    const checkDataFullNum = async () => {

        if (acticeCar?.packageId && SupportedLastsServices && SupportedLastsServices.length > 0 &&
            requestForm?.year && requestForm?.name && requestForm?.time && requestForm?.date) {

            setDataFullNum(2);
            return 2;

        } else {
            setDataFullNum(0);
            return 0;
        }


    }
    useEffect(() => {
        checkDataFullNum();
    }, [requestForm, SupportedLastsServices, acticeCar?.packageId]);


    return (
        <Fragment>

            <div className={"offer-service-reservation " + (acticeCar?.brandID == 1 ? " toyota-theme " : (acticeCar?.brandID == 2 ? " lexus-theme " : ""))} >
                <div className="offer-service-reservation-head" >
                    <div className="offer-service-reservation-head-Logo">

                        {
                            lang == "ar" ?

                                <div className="res-Logo-icon ">
                                    <img src="/assets/logo/logo_font_Black_AR.svg"
                                        alt="hassan jameel" className="hidden d-md-inline" />
                                    <img src="/assets/logo/logo_font_Black_AR.svg"
                                        alt="hassan jameel" className="d-inline d-md-hidden" />
                                </div>


                                :

                                <div className="res-Logo-icon ">
                                    <img src="/assets/logo/logo_font_Black_EN.svg"
                                        alt="hassan jameel" className="hidden d-md-inline" />
                                    <img src="/assets/logo/logo_font_Black_EN.svg"
                                        alt="hassan jameel" className="d-inline d-md-hidden" />
                                </div>
                        }
                    </div>

                    <div className="offer-service-reservation-head-extra flex flex-worp">
                        <div className="offer-service-reservation-head-brand flex flex-worp">
                            {
                                (acticeCar?.brandID == 1) ?
                                    <>
                                        <img src={`/assets/icons/brands/${"toyota-logo-withName.png"}`}
                                            className="" alt={acticeCar?.brandID} />
                                    </>
                                    :
                                    (
                                        (acticeCar?.brandID == 2) ?
                                            <>
                                                <img src={`/assets/icons/brands/${"lexus-logo-withName.png"}`}
                                                    className="" alt={acticeCar?.brandID} />
                                            </>
                                            :
                                            null

                                    )

                            }
                        </div>

                        <button
                            onClick={() => {

                                if (window.location.href.includes("/ar") || window.location.href.includes("/en")) {

                                    if (window.location.href.includes("/en"))
                                        window.location = window.location.href.replace("/en", "/ar");
                                    if (window.location.href.includes("/ar"))
                                        window.location = window.location.href.replace("/ar", "/en");

                                } else {

                                    window.location = window.location.origin + "/en" + window.location.pathname;
                                }
                            }}
                            className="offer-service-reservation-top-lang h-full  flex justify-center items-center">

                            <span className='text-white font-bold pl-2 pr-2 capitalize'>{trans('common:changeLangCode')}</span>
                            <img src="/assets/icons/globle.svg" alt="change language" />
                        </button>
                    </div>


                </div >


                <div className="offer-service-reservation-titel nice-container nice-relative">
                    <div className=" p-4">
                        <div className="mainteance-popup-container-header py-5 flex flex-col ">
                            <h3 className="text-balck text-bold text-40 my-5 ">
                                {banner?.type}</h3>

                            <p className="offer-service-reservation-titel-sub1 text-24 text-bold ">{banner?.title}</p>
                            <p className="protection-and-shading-titel-sub2 text-24 text-bold ">{banner?.excerpt}</p>

                        </div>
                    </div>
                </div>
                <div className=" nice-relative">
                    <div className=" p-4 pb-40">

                        <div className="ruler-2 mb-5"></div>


                        <div className="flex flex-column nice-gap-3rem px-5 ">

                            <div className="flex flex-row grid-2-cols nice-gap-2rem ">
                                <>
                                    <div className="basis-2/4 grow">

                                        <InputField

                                            errorLabel={error?.name}
                                            placeholder={trans("form:name")}
                                            value={requestForm.name}
                                            length={255}
                                            onChangeValue={
                                                (e) => {
                                                    const val = { ...requestForm };
                                                    val.name = e.target.value;
                                                    setRequestForm(val);
                                                }
                                            }
                                        />
                                    </div>
                                </>

                            </div>



                            <div className="grow">
                                <PhoneDropDown
                                    title={trans("form:phone")}
                                    errorLabel={error?.phone}
                                    onChangeValue={(countryCode, phoneNumber) => {

                                        const val = { ...requestForm };
                                        if (phoneNumber)
                                            val.phone = `+${countryCode}${phoneNumber}`;
                                        else
                                            val.phone = "";
                                        setRequestForm(val);
                                    }}
                                />
                            </div>


                            <div className="flex flex-col  nice-relative ">
                                {
                                    packages && packages.length > 0 &&
                                    packages?.map((data, _index) => {
                                        return (
                                            <div key={_index} className="offer-service-reservation-menu panel-section">
                                                <div className={`panel-content  ${(indexPanel == data?.id) ? "show-panel-info" : ""}`}>
                                                    <div className="panel-content-top"

                                                        onClick={(e) => {


                                                            setIndexPanel(null);
                                                            setActicePackage(null);
                                                            if (indexPanel != data?.id) {
                                                                setIndexPanel(data?.id);
                                                                setActicePackage(data);
                                                                const val = { ...requestForm };
                                                                val.model = null;
                                                                val.year = null;
                                                                setRequestForm(val);
                                                                setActiceCar(null);
                                                            }
                                                        }}


                                                    >
                                                        <div className="offer-service-reservation-menu-servie">
                                                            <h1 className={"text-20 text-super-gray   font-bold " + ((indexPanel == data?.id) ? " selectd " : "")}>
                                                                {data?.title}</h1>

                                                            <div className='offer-service-reservation-menu-price flex flex-row gap-2 text-bold nice-justifiy-end '>

                                                                {
                                                                    offer?.reservationOnly == true ?
                                                                        ""
                                                                        :
                                                                        <Price price={data?.price} />

                                                                }
                                                            </div>
                                                        </div>

                                                        <img src="/assets/icons/arrow.svg" />
                                                    </div>

                                                    <div className="panel-content-body">

                                                        <div className={"box-package-detail-desc "}>

                                                            <h3 className={"text-16 font-bold text-gray text-line-clamp text-line-clamp-1"}>{trans("offers:vehicles_supported")}:</h3>
                                                            <h3 className={"text-16 font-bold text-line-clamp text-line-clamp-2"}>{data?.desc}</h3>
                                                        </div>

                                                    </div>


                                                </div>
                                            </div>
                                        );
                                    })

                                }
                            </div>

                            <div className="flex flex-row grid-2-cols-importent nice-gap-2rem ">


                                <div className="basis2/4 grow">

                                    <DropDownInput

                                        errorLabel={error?.model}
                                        defaultOption={{
                                            text: trans("eparts:vehicle"),
                                            value: '0'
                                        }}
                                        selectedValue={requestForm?.model}
                                        options={[
                                            ...(SupportedVehicles.filter(a => a.packageId == acticePackage?.id)).map(B => {
                                                return { value: B.slug, text: ((lang == "ar" ? B?.brand : B?.brandEn) + " " + (lang == "ar" ? B?.group : B?.groupEn)) };
                                            })]}
                                        onSelect={(value) => {

                                            console.log("onSelect:", value);
                                            const val = { ...requestForm };
                                            val.model = value;
                                            val.year = null;
                                            setRequestForm(val);

                                            var car = (SupportedVehicles.filter(a => a.slug == value))[0];


                                            if (car) {

                                                setActiceCar(car);
                                            }

                                        }}

                                    />
                                </div>

                                <div className="basis2/4 grow">

                                    <DropDownInput
                                        errorLabel={error?.year}
                                        options={[...years]}
                                        defaultOption={{
                                            text: trans("info:created_year"),
                                            value: '0'
                                        }}
                                        selectedValue={requestForm?.year}
                                        onSelect={(value) => {
                                            const val = { ...requestForm };
                                            val.year = value + "";
                                            setRequestForm(val);
                                        }}

                                    />
                                </div>
                            </div>



                            <div className="flex flex-row grid-2-cols-importent nice-gap-2rem ">
                                <div className="basis2/4 grow">

                                    <InputField
                                        errorLabel={error?.VIN}
                                        placeholder={trans("form:structure-no") + '(VIN)'}
                                        onChangeValue={(e) => {
                                            const val = { ...requestForm };
                                            val.VIN = e.target.value;
                                            setRequestForm(val);

                                        }} />
                                </div>
                                <div className="flex flex-row  nice-gap-2rem ">

                                    <DropDownInput
                                        // title={trans("info:branch")}
                                        className={"w-90"}
                                        errorLabel={error?.SiteID}
                                        options={[
                                            ...Branches.map(B => {
                                                return { value: B.SiteID, text: lang === 'ar' ? B.DescriptionAr : B.DescriptionEn };
                                            })]}
                                        selectedValue={Branches?.length == 1 ? Branches[0]?.SiteID : requestForm?.SiteID}
                                        onSelect={(value) => {

                                            //////console.log("onSelect:", value);
                                            const val = {};
                                            val.SiteID = value;
                                            setRequestForm({ ...requestForm, ...val });
                                        }}


                                    />

                                    <a target="_blank" href="https://maps.app.goo.gl/TYwNm9SseQqA2WZx6" className="contact-tile">
                                        <div className="location-icon">
                                            <img src="/assets/icons/contact/location.svg" alt="user profile" />
                                        </div>
                                    </a>

                                </div>

                            </div>


                            <>
                                <div className="flex flex-row grid-2-cols-importent nice-gap-2rem ">


                                    <div className="grow">
                                        <div className="basis-2/4 grow">

                                            <DatePicker
                                                // showIcon
                                                isClearable={!!requestForm.date}

                                                className=" w-100"

                                                selected={requestForm.date}
                                                onChange={async (value) => {
                                                    const val = { ...requestForm };
                                                    val.date = value;
                                                    val.time = null;
                                                    setRequestForm(val);
                                                    setListTimeAvailable([]);

                                                    if (val.date) {
                                                        await webAvailableReshours(moment(value).format('yyyy-MM-DD'));
                                                    }
                                                }}
                                                filterDate={(date) => {
                                                    const today = new Date();
                                                    const yesterday = new Date(today);
                                                    yesterday.setDate(today.getDate() - 1);
                                                    const day = date.getDay();
                                                    return (day === 0 || day === 1 || day === 2 || day === 3 || day === 4 || day === 6)
                                                        && (new Date(date).setHours(0, 0, 0) > yesterday.setHours(0, 0, 0)); // Sunday (0), Saturday (6)

                                                }}
                                                placeholderText={trans("form:maintenance-date")}
                                                dateFormat="yyyy-MM-dd"
                                                customInput={<DateInput3
                                                    value={moment(requestForm.date).format('yyyy-MM-DD')}
                                                />}
                                            />
                                        </div>
                                        <ErrorLabel errorTitle={error?.date} />
                                    </div>

                                    <div className="grow">
                                        <div className="basis-2/4 grow">
                                            <DatePicker

                                                includeTimes={listTimeAvailable.map((val) => {
                                                    const time = new Date();
                                                    let res;

                                                    if (requestForm.date && new Date(requestForm.date).toDateString() === new Date().toDateString()) {
                                                        if (val > new Date().getHours()) {
                                                            res = time.setHours(val, 0, 0);
                                                        }
                                                    } else if (requestForm.date && new Date(requestForm.date) > new Date()) {
                                                        res = time.setHours(val, 0, 0);
                                                    } else {
                                                        res = time.setHours(val, 0, 0);
                                                    }

                                                    return res;
                                                })}
                                                isClearable={!!requestForm.time}
                                                selected={requestForm.time}
                                                onChange={(value) => {

                                                    const val = { ...requestForm };
                                                    val.time = value;
                                                    setRequestForm(val);
                                                }}
                                                className="w-100"

                                                placeholderText={trans("form:maintenance-time")}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={60}
                                                timeInputLabel={trans("form:maintenance-time")}

                                                timeCaption={trans("form:maintenance-time")}
                                                // withPortal
                                                dateFormat="hh:mm aa"
                                                // showTimeInput
                                                customInput={<DateInput3 />}
                                            />
                                        </div>
                                        <ErrorLabel errorTitle={error?.time} />
                                    </div>


                                </div>

                            </>



                            <TextArea
                                value={Note}
                                row={3}
                                placeholder={trans("info:notes")}
                                onChangeValue={(data) => {
                                    setNote(data);
                                }}
                            />
                        </div>


                        <div className="ruler-2 mb-3"></div>
                        {

                            <>
                                {

                                    <div className="nice-row mt-20 px-5 nice-flex-center ">
                                        <div className="nice-col-md-6 mb-5">
                                            <div className={"nice-bg-btn  text-white text-24 " + (dataFullNum >= 2 ? " bg-primary " : " bg-gray ")} onClick={() => {

                                                var cleanData = {};

                                                if (dataFullNum >= 2 && isSendeng == false)
                                                    SendResreve(acticeCar?.type, acticeService?.mainService, acticeService?.subService1, acticeService?.subService2, acticeService?.ServiceID, null, cleanData?.custID, acticePackage?.price);

                                            }}>
                                                {
                                                    trans("info:reserve")

                                                }

                                                {
                                                    isSendeng && <span className='mx-2 loading-btn '>
                                                        <img src='/assets/icons/loading.svg' width='25' height='25' />
                                                    </span>
                                                }

                                            </div>
                                        </div>

                                    </div>



                                }

                            </>
                        }


                        <ul className="reservation-privacy-link">
                            {

                                <li className="text-balck text-bold text-24 text-center">
                                    <a className={"text-16"} href="/privacy">{trans('offers:terms')}</a>
                                </li>
                            }

                        </ul>

                    </div>
                </div>
            </div>


            <Popup show={showFinalPopup}>
                <div className="w-full h-full flex flex-column justify-center items-center" >
                    <div className="cart-finalized" >
                        <div className="cart-finalized-container flex flex-col">

                            <div className="cart-finalized-container-img flex flex-col">
                                <img src="/assets/images/finalized.svg" />
                            </div>

                            <div className="flex flex-wrap justify-center">
                                <span className="text-20 font-bold text-black mx-2 unselectable ">{trans("new:thank_to_choose")}</span>
                                <span className="text-20 font-bold text-primary mx-2"> {trans("new:HJ_cars")}</span>
                            </div>

                            {
                                <>
                                    <div className="flex flex-row justify-center mb-10">
                                        <span className="text-20 font-bold  mx-2"> {trans("new:The_appointment_has_been_booked")}</span>
                                    </div>

                                    {acticeCar &&
                                        <div className="flex flex-row justify-center">
                                            <span className="text-20 font-bold text-primary  mx-2">
                                                {((lang == "ar" ? acticeCar?.brand : acticeCar?.brandEn) + " " + (lang == "ar" ? acticeCar?.group : acticeCar?.groupEn))}
                                                {` (${orderReservation?.Year}) `}</span>
                                        </div>
                                    }


                                    <div className="flex flex-row justify-center">
                                        <span className="text-20 font-bold  mx-2">{trans("form:maintenance-date")}: </span>
                                        <span className="text-20 font-bold text-primary  mx-2"> {orderReservation?.DateIn ?
                                            moment(orderReservation?.DateIn).format('YYYY-MM-DD')
                                            : moment(requestForm?.date).format('YYYY-MM-DD')} </span>
                                    </div>
                                    <div className="flex flex-row justify-center">
                                        <span className="text-20 font-bold  mx-2"> {trans("form:maintenance-time")}: </span>
                                        <span className="text-20 font-bold text-primary  mx-2">
                                            {
                                                orderReservation?.DateIn ? moment(orderReservation?.DateIn).format('hh:mm A')
                                                    : moment(requestForm?.time).format('hh:mm A')} </span>
                                    </div>

                                    {

                                        resOrderID &&
                                        <div className="flex flex-row justify-center cart-finalized-code"
                                            onClick={() => {

                                                navigator.clipboard.writeText(resOrderID);
                                            }}

                                        >

                                            <span className="text-20 font-bold text-white  mx-2"> {trans("new:Reservation_number") + " :"}</span>
                                            <span className="text-20 font-bold text-white  mx-2">{resOrderID && resOrderID}</span>
                                            <img src="/assets/icons/copy.svg" alt='' />
                                        </div>
                                    }

                                </>

                            }
                            <div className="flex flex-center flex-col  mt-5 pt-5 w-80">
                                <div className=" mb-5 w-100">
                                    <div className="nice-sm-btn cart-notification-go  bg-primary text-white text-16" onClick={() => {

                                        setShowFinalPopup(false);
                                        setResOrderID(null);
                                        if (location.pathname)
                                            history.push("/");

                                    }}>
                                        {trans("cartFile:Continue_browsing")}
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>
            </Popup>
        </Fragment >
    );
}