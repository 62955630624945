import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Popup from "../../User/popup";
import { TextArea } from "../../components/_input/_textArea";
import { useParams } from "react-router";
import config from '../../configuration/config.json';
import { useHistory, useLocation } from "react-router";
import { GetMessages, formValidate } from '../../utils/validation';
import Joi from "joi";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from 'react-loading-skeleton'
import { toast } from "react-toastify";
import { Price } from "../../components/__simple/_price";
import CustomItemServices from "../../components/CustomItemServices";
export function SalesOrderService() {

    let { OrderGUID } = useParams();
    const { t: trans, i18n } = useTranslation(["info", "employment", "eparts", "common", "global", "offers", "cartFile"]);
    let lang = (i18n.language || 'ar');
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [paramGUID, setParamGUID] = useState(query.get("Id") ?? null);
    const [pSalesorderid, setSalesorderid] = useState(query.get("Salesorderid") ?? null);
    const [sadadInfo, setSadadInfo] = useState(null);
    const [PVIN, setPVIN] = useState(null);
    const [PUser, setPUser] = useState(null);
    const [showFinalPopup, setShowFinalPopup] = useState(false);
    const [showFinalPopupMessage, setShowFinalPopupMessage] = useState("");
    const popRef = useRef(null);
    const [Agreements, setAgreements] = useState([]);
    const [resOrderID, setResOrderID] = useState(null);
    const history = useHistory();
    const [acticeMenu, setActiceMenu] = useState("Saving Packages");
    const [stepNum, setStepNum] = useState(1);
    const [dataFullNum, setDataFullNum] = useState(0);
    const [VINDetails, setVINDetails] = useState(null);
    const [infoPrice, setinfoPrice] = useState([{ Price: 0 }]);
    const [isSendeng, setIsSendeng] = useState(false);
    const [orderReservation, setOrderReservation] = useState(null);
    const [allselectServices, setAllSelectServices] = useState([]);
    const [acticeService, setActiceService] = useState(null);
    const [paymentType, setPaymentType] = useState(null);
    const [showAgreements, setshowAgreements] = useState(false);
    const [indexPanel, setIndexPanel] = useState("1000");
    const [brand, setBrand] = useState(null);
    const [type, setType] = useState(null);
    const [group, setGroup] = useState(null);
    const [year, setYear] = useState(null);
    const [ServiceID, setServiceID] = useState(null);
    const [allServiceID, setAllServiceID] = useState([]);
    const [Service, setService] = useState(null);
    const [requestForm, setRequestForm] = useState({
        name: '',
        phone: '',
        email: '',
        subject: '',
        date: '',
        time: '',
        messageContent: '',
        myCarID: '',
        SiteID: null,
        ServiceID: ''
    });
    
    const [platformTypes, setPlatformTypes] = useState([]);

    useEffect(() => {
        // تحميل الملف من مجلد public
        const loadManifest = async () => {
            try {
                const response = await fetch(`${process.env.PUBLIC_URL}/config.json`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                // Filter and map to extract keys with isActive = true
                var platformsTypes = data.platformsTypes || [];
                const activeKeys = platformsTypes
                    .filter((platform) => platform.isActive)
                    .map((platform) => platform.key);

                console.log("Active keys:", activeKeys);

                setPlatformTypes(activeKeys);

            } catch (error) {
                console.error("Error loading manifest.json:", error);
            }
        };

        loadManifest();
    }, []);

    const targetRef = useRef(null);
    const [salesOrder, setSalesOrder] = useState({});
    useEffect(() => {
        if (OrderGUID) {
            // FetchSalesOrderService(OrderGUID);
            FetchSalesOrderServiceFun(OrderGUID);
        }


    }, [OrderGUID]);

    const [browser, setBrowser] = useState('');

    useEffect(() => {
        const userAgent = navigator.userAgent;

        if (userAgent.indexOf('Chrome') > -1) {
            setBrowser('Chrome');
        } else if (userAgent.indexOf('Firefox') > -1) {
            setBrowser('Firefox');
        } else if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) {
            setBrowser('Safari');
        } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1) {
            setBrowser('Internet Explorer');
        } else if (userAgent.indexOf('Edge') > -1) {
            setBrowser('Edge');
        } else {
            setBrowser('Other');
        }
    }, []);

    useEffect(async () => {

        if (Service && Service.length > 0) {

            try {
                const response = await axios.request({
                    baseURL: config.controllURL,
                    url: lang + "/api/privacy/ProtectionAndShadingAgreement",
                    method: "get",
                });
                setAgreements(response.data?.agreements);
            } catch (error) {
                console.log(error);
            }

        }

    }, [Service]);

    useEffect(() => {
        const paymentStatus = new URL(window.location.href).searchParams.get("paymentStatus");
        const orderId = new URL(window.location.href).searchParams.get("orderId");

        const paymentMethodCallback = new URL(window.location.href).searchParams.get("PaymentMethod");
        const paymentTypeCallback = new URL(window.location.href).searchParams.get("PaymentType");
        if (paymentMethodCallback != null && paymentTypeCallback != null) {

            if (paymentMethodCallback == 'myfatoorah') {
                StatusPaymentMyfatoorahSalesOrdersService();
            }
            else if (paymentMethodCallback == 'paytabs') {
                StatusPaymentPayTabsSalesOrdersService();
            } else if (paymentMethodCallback == 'tamara') {
                if (paymentStatus == 'approved') {
                    StatusPaymentTamaraSalesOrdersService(orderId);
                    setTimeout(() => {

                        // StatusPaymentTamaraSalesOrdersService(orderId);

                    }, 2000);

                    setShowFinalPopup(true);
                }

            } else if (paymentMethodCallback == 'sadad') {
                StatusPaymentSadadSalesOrdersService();
                //site_fetchSadadCode_salesOrdersService
            } else {
                setIsSendeng(false);
                toast.error(trans("error:unknown_failure"));
            }

        }

    }, [])

    useEffect(() => {

        if (orderReservation) {
            setResOrderID(orderReservation?.MaintenanceOrderID);
            setShowFinalPopup(true);

        }

    }, [orderReservation])
    useEffect(() => {
        if (PVIN && PUser) {
            GetVINDetails(PVIN, PUser);
            fetchService();
        }

    }, [PVIN, PUser])

    useEffect(() => {
        if (VINDetails && VINDetails.length > 0) {
            fetchAllService(VINDetails[0]);
        }

    }, [VINDetails])
    async function FetchSalesOrderServiceFun(guid) {
        const Body = {
            "name": "site_FetchSalesOrder_Service_ByGUID",
            "values": {
                "GUID": guid
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                const result = response?.data;
                if (result && result.length > 0) {

                    var appData = result[0];
                    setSalesOrder(appData);

                    if (appData.StatusPaymentID == 'FullyPaid') {

                        const paymentMethodCallback = new URL(window.location.href).searchParams.get("PaymentMethod");
                        if (paymentMethodCallback == null) {
                            if (appData.SalesOrderID)
                                setResOrderID(appData.SalesOrderID);

                            setShowFinalPopupMessage(trans("تم الدفع مسبقا"));
                            setShowFinalPopup(true);
                        }

                    }
                    if (!appData.SN) {
                        setShowFinalPopupMessage(trans("يجب اضافة VIN"));
                        setShowFinalPopup(true);
                    }
                    setPVIN(appData.SN);
                    setPUser(appData.CustID);
                } else {
                    setShowFinalPopupMessage(trans("امر البيع غير موجود"));
                    setShowFinalPopup(true);
                }
            })
            .catch(function (error) {

                console.log(error);

            });


    }
    // site_FetchSalesOrder_Service_ByGUID
    async function GetVINDetails(VIN, User) {



        const Body = {
            "name": "App_GetVINDetailsByUserId",
            "values": {
                "VIN": VIN,
                "UserId": User
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                //console.log("App_GetVINDetails:", response.data);
                setVINDetails(response.data);
                setBrand(response.data[0].BrandID);
            })
            .catch(function (error) {

                console.log(error);
                setVINDetails([]);
                setShowFinalPopupMessage(trans("employment:The_page_link_is_incorrect"));
                setShowFinalPopup(true);
            });


    }

    useEffect(() => {

        function handleClickOutside(event) {
            if (popRef.current && !popRef.current.contains(event.target) && showFinalPopup === true) {
                setShowFinalPopup(false);
                setResOrderID(null);
                if (location.pathname)
                    history.push(location.pathname);

            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showFinalPopup]);


    //--------------------------------------------------
    const [maintenanceObj, setMaintenanceObj] = useState({
        showPopup: false, type: "Notset", main: "", sub1: "", sub2: ""
    });
    const [Defult, setDefult] = useState({
        showPopup: false, type: "Notset", main: "", sub1: "", sub2: ""
    });



    const notLoginSchema = Joi.object({
        name: Joi.string().required().messages(GetMessages()),
    });

    useEffect(() => {

        try {

            if (requestForm?.date) {

                var md = new Date(requestForm?.date);
                try {
                    if (requestForm?.time) {

                        var Hours = requestForm?.time.split(":");

                        md.setHours(Hours[0], Hours[1], 0);
                    }
                } catch (error) {

                }


            }

        } catch (error) {
        }


    }, [requestForm]);

    const [Note, setNote] = useState("");

    const [lodingService, setLodingService] = useState(false);


    async function fetchAllService(VehicleDetails) {
        setService([]);
        setinfoPrice([{ Price: 0 }]);

        setLodingService(true);


        const Body = {
            "name": "site_FetchProtectionServiceByVehicleType_forNewCar",
            "values": {
                "VehicleType": VehicleDetails.VehicleType
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {

                var filterservice = response?.data;
                setTimeout(setService(filterservice), 500);
                setLodingService(false);

            })
            .catch(function (error) {

                setLodingService(false);
                //////console.log(error);
            });



    }

    async function fetchService() {
        setService([]);
        setinfoPrice([{ Price: 0 }]);

        setLodingService(true);


        const Body = {
            //site_FetchSalesOrderTrans_Service_ByGUID
            "name": "site_FetchSalesOrderTrans_Service_ByGUID",
            "values": {
                "GUID": OrderGUID
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {

                var filterservice = response?.data;
                var ServiceIDList = filterservice?.map(s => s.ServiceID);
                setAllSelectServices(filterservice);
                setAllServiceID(ServiceIDList);
                setLodingService(false);
            })
            .catch(function (error) {

                setLodingService(false);
                //////console.log(error);
            });



    }

    const checkDataFullNum = async () => {

        if (paymentType && allServiceID && allServiceID.length > 0) {

            setDataFullNum(2);
            return 2;
        } else if (paymentType && allServiceID && allServiceID.length > 0) {

            setDataFullNum(2);
            return 2;
        } else {
            setDataFullNum(0);
            return 0;
        }


    }

    //---------------------

    const [error, setError] = useState({});
    async function SendResreve(VIN) {

        const val = { ...requestForm };
        val.year = year;
        val.ServiceID = ServiceID;
        setRequestForm(val);
        var mainNote = Note;
        const result = formValidate(notLoginSchema, val) ?? {};
        setError(result);
        if (Object.keys(result).length == 0 && isSendeng == false) {

            let listofServices;

            allServiceID.map((item) => {

                if (listofServices)
                    listofServices += "," + item;
                else
                    listofServices = item;
            });
            setIsSendeng(true);
            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = {
                    "paytype": paymentType,
                    "userId": PUser,
                    "VIN": VIN,
                    "OrderGUID": OrderGUID,
                    "note": mainNote,
                    "servicelist": listofServices,
                    "referenceOrderId": pSalesorderid,
                    "callback": window.location.origin + "/" + lang + `/maintenance/SalesOrderService/${OrderGUID}`
                };

                console.log("raw>>>>>>>>>>>>>>>>:", raw);

                await axios.request({
                    baseURL: config.controllURL,
                    url: "api/Payment/applyPaymentSalesOrdersService",
                    headers: myHeaders,
                    data: raw, method: "POST", responseType: "json"
                })

                    .then(async response => {
                        console.log('Payment respons data:\n', response.data);


                        if (response.data.URL_Paytabs) {
                            window.location.href = response.data.URL_Paytabs;

                        } else if (response.data.url_payment) {
                            window.location.href = response.data.url_payment;
                            //site_fetchSadadCode_salesOrdersService
                        } else {
                            setIsSendeng(false);
                            if (response?.data?.MessageError)
                                toast.error(response?.data?.MessageError);
                            else
                                toast.error(trans("error:unknown_failure"));
                        }

                        setIsSendeng(false);
                    })
                    .catch(function (error) {

                        setIsSendeng(false);
                    });

            } catch (error) {
                setIsSendeng(false);

                console.log("error:", error);
            }

        }
    }



    const GetUrlfunc = config.baseURL + 'func';


    async function StatusPaymentTamaraSalesOrdersService(orderId) {
        console.log('StatusPaymentTamaraSalesOrdersService >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');

        if (paramGUID && orderId) {



            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = {
                    "GUID": paramGUID,
                    "orderId": orderId ?? ""
                };

                await axios.request({
                    baseURL: config.controllURL,
                    url: "api/Payment/StatusPaymentTamaraSalesOrdersService",
                    headers: myHeaders,
                    data: raw, method: "POST", responseType: "json"
                }).then(async response => {
                    console.log('StatusPaymentTamaraSalesOrdersService respons:', response);
                    if (response.data) {

                        var data = response.data;
                        console.log('PaymentPaytabsStatusFully data:', data);
                        if (data.Status == "success") {
                            setShowFinalPopup(true);
                            if (data.OrderId)
                                setResOrderID(data.OrderId);
                        }
                    }


                }
                ).catch(async error => {
                    console.log('error StatusPaymentTamaraSalesOrdersService:', error)

                });
            } catch (error) {
                console.log('error', error)


            }
        }
    }

    async function StatusPaymentMyfatoorahSalesOrdersService() {
        console.log('StatusPaymentMyfatoorahSalesOrdersService >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');

        if (paramGUID) {



            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = {
                    "GUID": paramGUID
                };

                await axios.request({
                    baseURL: config.controllURL,
                    url: "api/Payment/StatusPaymentMyfatoorahSalesOrdersService",
                    headers: myHeaders,
                    data: raw, method: "POST", responseType: "json"
                }).then(async response => {
                    console.log('PaymentMyfatoorahStatusFully respons:', response);
                    if (response.data && response.data.length > 0) {

                        var data = response.data.filter(p => p.Status == "success")[0];
                        console.log('PaymentMyfatoorahStatusFully data:', data);
                        if (data.Status == "success") {
                            setShowFinalPopup(true);
                            if (data.OrderId)
                                setResOrderID(data.OrderId);
                        }
                    }

                }
                ).catch(async error => {
                    console.log('error PaymentMyfatoorahStatusFully:', error)

                });
            } catch (error) {
                console.log('error', error)


            }
        }
    }
    async function StatusPaymentPayTabsSalesOrdersService() {
        console.log('StatusPaymentPayTabsSalesOrdersService >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');

        if (paramGUID) {



            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = {
                    "GUID": paramGUID
                };

                await axios.request({
                    baseURL: config.controllURL,
                    url: "api/Payment/StatusPaymentPayTabsSalesOrdersService",
                    headers: myHeaders,
                    data: raw, method: "POST", responseType: "json"
                }).then(async response => {
                    console.log('PaymentPaytabsStatusFully respons:', response);
                    if (response.data && response.data.length > 0) {

                        var data = response.data.filter(p => p.Status == "success")[0];
                        console.log('PaymentPaytabsStatusFully data:', data);
                        if (data.Status == "success") {
                            setShowFinalPopup(true);
                            if (data.OrderId)
                                setResOrderID(data.OrderId);
                        }
                    }

                }
                ).catch(async error => {
                    console.log('error PaymentPaytabsStatusFully:', error)

                });
            } catch (error) {
                console.log('error', error)


            }
        }
    }
    async function StatusPaymentSadadSalesOrdersService() {
        console.log('StatusPaymentSadadSalesOrdersService >>>>>>>', paramGUID);

        if (paramGUID) {



            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = {
                    "GUID": paramGUID
                };

                await axios.request({
                    baseURL: config.controllURL,
                    url: "api/Payment/StatusPaymentSadadSalesOrdersService",
                    headers: myHeaders,
                    data: raw, method: "POST", responseType: "json"
                }).then(async response => {
                    console.log('StatusPaymentSadadSalesOrdersService respons:', response);

                    var invoiceID = response.data.sadadNumber.replaceAll('"', "");

                    try {
                        if (invoiceID && parseInt(invoiceID)) {
                            var datasadad = response.data;
                            datasadad.sadadNumber = invoiceID;
                            setSadadInfo(datasadad);
                        } else {
                            setSadadInfo(response?.data);
                        }
                    } catch (error) {
                        setSadadInfo(response.data);
                    }
                    //! اهمم مرحلة في الفحص
                    if (invoiceID != null && invoiceID != undefined) {
                        setShowFinalPopup(true);
                    }

                }
                ).catch(async error => {
                    console.log('error StatusPaymentSadadSalesOrdersService:', error)

                });
            } catch (error) {
                console.log('error', error)


            }
        }
    }

    useEffect(() => {

        if (VINDetails && VINDetails.length > 0) {
            setRequestForm({
                ...requestForm,
                name: lang == "ar" ? VINDetails[0].CustNameAr : VINDetails[0].CustNameEn,
                phone: VINDetails[0].Mobile,
                email: VINDetails[0].Email,
                myCarID: VINDetails[0].VIN
            });

        }

    }, [VINDetails,])

    useEffect(() => {
        checkDataFullNum();
    }, [requestForm, ServiceID, allServiceID, year, type, paymentType]);

    return (
        <>

            {
                (VINDetails && brand) ?

                    <div className={"protection-and-shading   " + (brand == 1 ? " toyota-theme " : " lexus-theme ")} >

                        <div className="protection-and-shading-head" >
                            <div className="protection-and-shading-head-Logo">

                                {
                                    lang == "ar" ?

                                        <div className="res-Logo-icon ">
                                            <img src="/assets/logo/logo_font_Black_AR.svg"
                                                alt="hassan jameel" className="hidden d-md-inline" />
                                            <img src="/assets/logo/logo_font_Black_AR.svg"
                                                alt="hassan jameel" className="d-inline d-md-hidden" />
                                        </div>


                                        :

                                        <div className="res-Logo-icon ">
                                            <img src="/assets/logo/logo_font_Black_EN.svg"
                                                alt="hassan jameel" className="hidden d-md-inline" />
                                            <img src="/assets/logo/logo_font_Black_EN.svg"
                                                alt="hassan jameel" className="d-inline d-md-hidden" />
                                        </div>
                                }
                            </div>

                            <div className="protection-and-shading-head-extra flex flex-worp">
                                <div className="protection-and-shading-head-brand flex flex-worp">
                                    {
                                        (brand == 1) ?
                                            <>
                                                <img src={`/assets/icons/brands/${"toyota-logo-withName.png"}`}
                                                    className="" alt={brand} />
                                            </>
                                            :
                                            (
                                                (brand == 2) ?
                                                    <>
                                                        <img src={`/assets/icons/brands/${"lexus-logo-withName.png"}`}
                                                            className="" alt={brand} />
                                                    </>
                                                    :
                                                    null

                                            )

                                    }
                                </div>

                                <button
                                    onClick={() => {

                                        if (window.location.href.includes("/ar") || window.location.href.includes("/en")) {

                                            if (window.location.href.includes("/en"))
                                                window.location = window.location.href.replace("/en", "/ar");
                                            if (window.location.href.includes("/ar"))
                                                window.location = window.location.href.replace("/ar", "/en");

                                        } else {

                                            window.location = window.location.origin + "/en" + window.location.pathname;
                                        }
                                    }}
                                    className="protection-and-shading-top-lang h-full  flex justify-center items-center">

                                    <span className='text-white font-bold pl-2 pr-2 capitalize'>{trans('common:changeLangCode')}</span>
                                    <img src="/assets/icons/globle.svg" alt="change language" />
                                </button>
                            </div>


                        </div >

                        <div className="protection-and-shading-titel nice-container nice-relative">
                            <div className=" p-4">
                                <div className="mainteance-popup-container-header py-5 flex flex-col ">
                                    <h3 className="text-balck text-bold text-40 my-5 ">
                                        {trans("info:Pamper_your_car_and_dont_skimp_on_it")}</h3>
                                    <p className="protection-and-shading-titel-sub1 text-24 text-bold ">{"حياك الله" +
                                        (requestForm?.name?.split(' ')[0] ? (lang == "ar" ? " يا" : ",") + requestForm.name.split(' ')[0]
                                            : " ") + "  في حسن جميل للسيارات "}</p>
                                    <p className="protection-and-shading-titel-sub2 text-24 text-bold ">{" يسعدنا خدمتك دائما ...باقي خطوة واحدة للدفع و سيارتك تصبح جاهزة"}</p>

                                </div>


                            </div>

                        </div >


                        {
                            Service &&
                            <div ref={targetRef} className="protection-and-shading-menu more-services bg-secondary-lighter border-radius py-2 alert-indicator-target-area">
                                <div className=" pb-10 my-5">
                                    {/* menu tabs */}
                                    <div className="my-10 nice-apply-theme ">
                                        <Swiper
                                            spaceBetween={3}
                                            slidesPerView={3}
                                            breakpoints={{
                                                576: {
                                                    slidesPerView: 3,
                                                },
                                            }}
                                            className={" my-8  nice-apply-theme"}
                                            resizeObserver={"true"}
                                        >

                                            {[{ name: "الباقات التوفيرية", id: "Saving Packages" }, { name: "الحماية والتظليل", id: "Protection And Shading" }, { name: "خدمات خاصة", id: "Special Services" }]?.map((_val, _index) => {
                                                return (

                                                    <SwiperSlide key={_index} className={""}>
                                                        <div className='flex h-100' onClick={() => {
                                                            setActiceService(null);
                                                            setActiceMenu(_val.id);
                                                            setIndexPanel(null);
                                                        }}>

                                                            <div className={'protection-and-shading-menu-item nice-apply-theme bg-theme-up text-center bg-white ' + (acticeMenu == _val.id ? " active " : "  ")}>
                                                                <h3 className="protection-and-shading-menu-item-titel ">{lang == "ar" ? _val.name : _val.id} </h3>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>

                                                );
                                            })}



                                        </Swiper>
                                    </div>
                                    {/* body */}
                                    <div className="flex flex-col  nice-relative ">
                                        {
                                            Service?.filter(s => s.MainWebGroup == acticeMenu).map((_Srtvice, _index) => {
                                                return (
                                                    <div key={_index} className="panel-section mx-5">


                                                        <div className={`panel-content  ${(indexPanel == _Srtvice.ServiceID) ? "show-panel-info" : ""}`}>
                                                            <div className="panel-content-top"

                                                                onClick={(e) => {
                                                                    setIndexPanel(null);
                                                                    setActiceService(null);
                                                                    if (indexPanel != _Srtvice.ServiceID) {
                                                                        setIndexPanel(_Srtvice.ServiceID);
                                                                        setActiceService(_Srtvice);
                                                                    }


                                                                }}


                                                            >
                                                                <div className="protection-and-shading-menu-servie">
                                                                    <h1 className={"text-20    font-bold " + (" text-super-gray ") + (allServiceID.includes(_Srtvice.ServiceID) ? " selectd " : "")}>
                                                                        {lang == "ar" ? _Srtvice.ServiceNameAR : _Srtvice.ServiceName}</h1>

                                                                    <div className='protection-and-shading-menu-price flex flex-row gap-2 text-bold nice-justifiy-end '>

                                                                        {
                                                                            _Srtvice?.PriceExcludeTax && <Price price={_Srtvice?.PriceExcludeTax} />
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <img src="/assets/icons/arrow.svg" />
                                                            </div>

                                                            <div className="panel-content-body">

                                                                <ul>
                                                                    {
                                                                        _Srtvice[lang == "ar" ? "ServiceDetails" : "ServiceDetailsEn"]?.replaceAll("\\n\\n", "\\n")?.split('\n')?.map((note, index) => {

                                                                            return (

                                                                                note ?
                                                                                    <li key={index} className="text-16 text-gray"  >
                                                                                        {/* <span className="text-16 text-gray" >{note}</span> */}
                                                                                        <span className="text-16 text-gray" dangerouslySetInnerHTML={{ __html: note }} />
                                                                                    </li>
                                                                                    : null

                                                                            );
                                                                        })
                                                                    }


                                                                </ul>


                                                            </div>


                                                        </div>
                                                    </div>
                                                );
                                            })

                                        }


                                    </div>
                                </div>
                            </div>
                        }


                        {/* menu tabs */}
                        {
                            allselectServices && allselectServices.length > 0 &&
                            <div className=" more-services bg-secondary-lighter border-radius py-2">

                                <div className=" py-5 flex flex-col ">
                                    <h3 className="text-balck text-bold text-20  text-center ">
                                        {trans("info:الخدمات المضافة ")}</h3>
                                </div>
                                <div className="my-10 nice-apply-theme nice-container nice-relative ">
                                    <Swiper
                                        spaceBetween={3}
                                        slidesPerView={3}
                                        pagination
                                        breakpoints={{
                                            576: {
                                                slidesPerView: 3,
                                            },
                                        }}
                                        className={" my-8  nice-apply-theme"}
                                        resizeObserver={"true"}
                                    >

                                        {allselectServices?.map((_val, _index) => {
                                            return (

                                                <SwiperSlide key={_index} className={""}>
                                                    <div className='flex  nice-relative overflow-hidden'>


                                                        <CustomItemServices
                                                            // title={trans("mobileCommon:finance")}
                                                            title={lang == "ar" ? _val.ServiceNameAR : _val.ServiceName}
                                                            borderCSS={"border-primary-box nice-flex-important nice-align-item-center my-5"}

                                                        >

                                                        </CustomItemServices>
                                                    </div>
                                                </SwiperSlide>

                                            );
                                        })}



                                    </Swiper>
                                </div>
                            </div>
                        }
                        <div className=" nice-container nice-relative">
                            {
                                allselectServices && allselectServices.length > 0 &&
                                <div className="flex flex-col">

                                    {
                                        allselectServices?.map((_val, _index) => {
                                            return (

                                                <div key={_index} className="cart-grid-info-div ">
                                                    <div className={"text-14 text-gray mx-1"}>{lang == "ar" ? _val.ServiceNameAR : _val.ServiceName}&nbsp;</div>
                                                    <div className={"text-14 text-gray mx-1"}> {_val?.PriceExcludeTax ? <Price price={_val?.PriceExcludeTax} className={"white-space-pre"} /> : ""}</div>

                                                </div>

                                            );
                                        })
                                    }
                                </div>
                            }

                            {
                                allselectServices && allselectServices.length > 0 &&
                                <>
                                    <div className="cart-grid-info-div border-tb">
                                        <div className={"text-14  text-black"}>{trans("new:tax_value")}</div>
                                        <div className={"text-14 text-black "}> {allselectServices?.reduce((total, service) => total + service.Price, 0) ? <Price price={allselectServices?.reduce((total, service) => total + (service.Price - service.PriceExcludeTax), 0)} className={"white-space-pre"} /> : ""}</div>
                                    </div>
                                    <div className="cart-grid-info-div border-tb">
                                        <div className={"text-16 font-bold text-black"}>{trans("new:total")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span></div>
                                        <div className={"text-16 text-black font-bold"}> {allselectServices?.reduce((total, service) => total + service.Price, 0) ? <Price price={allselectServices?.reduce((total, service) => total + service.Price, 0)} className={"white-space-pre"} /> : ""}</div>
                                    </div>
                                </>

                            }

                        </div>


                        <div className=" nice-container nice-relative">
                            <div className=" mt-10 p-4">
                                <TextArea
                                    value={Note}
                                    row={4}
                                    placeholder={trans("info:notes")}
                                    onChangeValue={(data) => {
                                        setNote(data);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="my-20 py-5  bg-gray-lighter ">
                            <div className="flex flex-col nice-gap-2rem nice-container ">
                                <div className="flex flex-row justify-between items-center nice-container">
                                    <h3 className="text-30 font-bold text-black"> {trans("new:pay_method")}</h3>

                                </div>
                                <div className="breakout!! flex flex-col nice-gap-2rem ">
                                    {
                                        platformTypes && platformTypes.includes("payTabs") &&
                                        <div className={"flex flex-col nice-gap-2rem border-radius p-5 " + (paymentType == "payTabs" ? " bg-gray-light " : "")}
                                            onClick={() => {
                                                setPaymentType("payTabs");
                                            }}
                                        >
                                            <label className={" text-20 font-bold flex flex-row nice-gap-1rem "}>
                                                <input type='radio' checked={paymentType == "payTabs" ? true : false} name='paymentType'


                                                    onChange={() => {



                                                    }}
                                                    id="primary"
                                                />
                                                {trans("new:online_all")}

                                            </label>
                                            <div className="flex flex-row items-center  mx-10">
                                                <img className="cart-payment-icon object-contain" src="/assets/icons/companies/master-card-3.png" alt={"master"} />
                                                <img className="cart-payment-icon object-contain" src="/assets/icons/companies/visa-3.png" alt={"visa"} />
                                                <img className="cart-payment-icon object-contain" src="/assets/icons/companies/mada-3.png" alt={"mada"} />

                                            </div>

                                        </div>


                                    }

                                    {
                                        platformTypes && platformTypes.includes("myfatoorah") &&
                                        <div className={"flex flex-col nice-gap-2rem border-radius p-5 " + (paymentType == "myfatoorah" ? " bg-gray-light " : "")}
                                            onClick={() => {
                                                setPaymentType("myfatoorah");
                                            }}
                                        >
                                            <label className={" text-20 font-bold flex flex-row nice-gap-1rem "}>
                                                <input type='radio' checked={paymentType == "myfatoorah" ? true : false} name='paymentType'


                                                    onChange={() => {



                                                    }}
                                                    id="primary"
                                                />
                                                {trans("new:online_all")}

                                            </label>
                                            <div className="flex flex-row items-center  mx-10">
                                                <img className="cart-payment-icon object-contain" src="/assets/icons/companies/master-card-3.png" alt={"master"} />
                                                <img className="cart-payment-icon object-contain" src="/assets/icons/companies/visa-3.png" alt={"visa"} />
                                                <img className="cart-payment-icon object-contain" src="/assets/icons/companies/mada-3.png" alt={"mada"} />

                                            </div>

                                        </div>


                                    }

                                    {
                                        platformTypes && platformTypes.includes("tamara") &&
                                        <div className={"flex flex-col  border-radius p-5  " + (paymentType == "tamara" ? " bg-gray-light  " : "")}
                                            onClick={() => {

                                                setPaymentType("tamara");
                                            }}
                                        >
                                            <label className={" text-20 font-bold flex flex-row nice-gap-1rem "}>
                                                <input type='radio' checked={paymentType == "tamara" ? true : false}
                                                    name='paymentType'

                                                    onChange={() => {
                                                        console.log("<< PaymentType >>: ", paymentType);
                                                    }}

                                                    id="primary"

                                                />

                                                {trans("new:byTamara")}

                                                <div className="flex flex-row  cart-payment-icon">
                                                    <img className="cart-payment-icon object-contain"
                                                        src={lang == 'ar'
                                                            ? "/assets/icons/AR-tamara.svg"
                                                            : "/assets/icons/En-tamara.svg"}
                                                        alt={""} />
                                                </div>

                                            </label>
                                            <label className={" text-20  flex flex-row  pr-20 pl-20"}>
                                                {trans("new:byTamara3")}
                                            </label>
                                            <label className={" text-20  flex flex-row pr-20 pl-20 "}>
                                                {trans("new:byTamara4")}
                                            </label>
                                        </div>

                                    }

                                    {
                                        platformTypes && platformTypes.includes("sadad") &&
                                        <div className={"flex flex-col nice-gap-2rem border-radius p-5  " + (paymentType == "sadad" ? " bg-gray-light  " : "")}
                                            onClick={() => {

                                                setPaymentType("sadad");
                                            }}
                                        >
                                            <label className={" text-20 font-bold flex flex-row  items-center nice-gap-1rem "}>
                                                <input type='radio' checked={paymentType == "sadad" ? true : false} name='paymentType'

                                                    onChange={() => {

                                                    }}
                                                    id="primary"
                                                />
                                                <img className=" object-contain"
                                                    src={"/assets/icons/companies/SADAD-Logo.png"} width={"60px"} alt={"SADAD"} />

                                            </label>


                                        </div>

                                    }

                                </div>
                            </div>
                        </div>

                        <ul className="reservation-privacy-link nice-container my-10">
                            {

                                <li className="text-balck text-bold text-24 text-center">
                                    <a className={"text-16 nice-pointer"}
                                        // href="/protectionAndShadingAgreement"
                                        onClick={(e) => {
                                            // e.defaultPrevented();
                                            setshowAgreements(true)
                                        }}
                                    >{trans('offers:terms')}</a>
                                </li>
                            }

                        </ul>

                        <div className="ruler-2 mb-3"></div>
                        {

                            <div className=" nice-container">
                                {

                                    <div className="flex flex-row nice-gap-2rem my-20  ">

                                        {
                                            browser && browser == "Safari" ?
                                                <div className=" w-100 mb-5 "
                                                    onClick={() => {

                                                        if (dataFullNum >= 2 && isSendeng == false)
                                                            SendResreve(PVIN);

                                                    }}

                                                >
                                                    <div title="" className={"nice-style-btn nice-flex-row py-3  text-white text-20 " + (dataFullNum >= 2 ? " bg-black" : " bg-gray ")}
                                                    >
                                                        {
                                                            isSendeng && <span className='mx-2 loading-btn '>
                                                                <img className="object-max-full " src='/assets/icons/loading.svg' width='25' height='25' />
                                                            </span>
                                                        }
                                                        <img className="object-max-full" src="/assets/icons/apple-pay.png" alt="apple-pay" width='70' height='70' />
                                                    </div>
                                                </div>

                                                :


                                                <div className="w-100  mb-5">
                                                    <div className={"nice-bg-btn  text-white text-24 " + (dataFullNum >= 2 ? " bg-primary " : " bg-gray ")}
                                                        onClick={() => {


                                                            if (dataFullNum >= 2 && isSendeng == false)
                                                                SendResreve(PVIN);

                                                        }}>
                                                        {
                                                            (orderReservation) ?
                                                                trans("info:save_changes")
                                                                :
                                                                trans("cartFile:comlete_buying")

                                                        }

                                                        {
                                                            isSendeng && <span className='mx-2 loading-btn '>
                                                                <img className="object-max-full " src='/assets/icons/loading.svg' width='25' height='25' />
                                                            </span>
                                                        }

                                                    </div>
                                                </div>

                                        }




                                    </div>



                                }

                            </div>
                        }



                    </div>

                    :
                    <>
                        <Skeleton count={1} borderRadius={"2rem"} width={"100%"} height={"20rem"}
                            containerClassName={""} className={"my-5"}
                            inline={true} />

                        <Skeleton count={3} borderRadius={"2rem"} width={"80%"} height={"5rem"}
                            containerClassName={"flex flex-col"} className={"my-5"}
                            inline={true} />

                        <Skeleton count={3} borderRadius={"2rem"} width={"30%"} height={"10rem"}
                            containerClassName={" flex justify-center items-center"} className={"my-5"}
                            inline={false} />
                        <Skeleton count={4} borderRadius={"2rem"} width={"100%"} height={"5rem"}
                            containerClassName={""} className={"my-5"}
                            inline={true} />
                        <Skeleton count={2} borderRadius={"2rem"} width={"100%"} height={"40rem"}
                            containerClassName={""} className={"my-5"}
                            inline={true} />
                        <Skeleton count={1} borderRadius={"2rem"} width={"100%"} height={"10rem"}
                            containerClassName={""} className={"my-5"}
                            inline={true} />

                    </>

            }

            <Popup show={showFinalPopup}>
                <div className="w-full h-full flex flex-column justify-center items-center" >
                    <div className="cart-finalized" >
                        <div className="cart-finalized-container flex flex-col">

                            <div className="cart-finalized-container-img flex flex-col">
                                <img src="/assets/images/finalized.svg" />
                            </div>

                            <div className="flex flex-wrap justify-center">
                                <span className="text-20 font-bold text-black mx-2 unselectable ">{trans("new:thank_to_choose")}</span>
                                <span className="text-20 font-bold text-primary mx-2"> {trans("new:HJ_cars")}</span>
                            </div>

                            {
                                showFinalPopupMessage &&
                                <>
                                    <div className="flex flex-wrap justify-center mx-20">
                                        <span className="text-20 font-bold  mx-2"> {showFinalPopupMessage}</span>
                                    </div>

                                </>

                            }
                            {
                                VINDetails && VINDetails.length > 0 &&
                                <>

                                    {

                                        resOrderID &&
                                        <div className="flex flex-row justify-center cart-finalized-code"
                                            onClick={() => {

                                                navigator.clipboard.writeText(resOrderID);
                                            }}

                                        >

                                            <span className="text-20 font-bold text-white  mx-2"> {trans("info:order-number") + " :"}</span>
                                            <span className="text-20 font-bold text-white  mx-2">{resOrderID && resOrderID}</span>
                                            <img src="/assets/icons/copy.svg" alt='' />
                                        </div>
                                    }
                                    -
                                </>

                            }
                            <div className="flex flex-center flex-col  mt-5 pt-5 w-80">
                                <div className=" mb-5 w-100">
                                    <div className="nice-sm-btn cart-notification-go  bg-primary text-white text-16" onClick={() => {

                                        setShowFinalPopup(false);
                                        setResOrderID(null);
                                        if (location.pathname)
                                            history.push("/");

                                    }}>
                                        {trans("cartFile:Continue_browsing")}
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>
            </Popup>

            <Popup show={showAgreements}>

                <div className="offer-popup  nice-flex nice-flex-center">
                    <div className="offer-popup-container p-4">
                        <div className="offer-popup-container-header">
                            <h1 className="text-balck text-bold text-24 text-center">{trans("offers:terms")}</h1>
                            <span className='offer-popup-container-header-close text-block text-gray' onClick={() => {
                                setshowAgreements(false)
                            }}></span>
                        </div>
                        <div className="ruler-2 mb-3"></div>
                        <div className='popup-content'>
                            <div className="mx-10 my-10">

                                {
                                    Agreements && Agreements.length > 0 ?
                                        <div className={"new-lines "}>
                                            <ul className={"text-20 my-10 mx-10 leading-relaxed"}>
                                                {
                                                    Agreements[0]?.explaination?.split('\n')?.map((item, index) => {

                                                        return <li className={"text-20 leading-relaxed font-bold"} key={index}>
                                                            {item?.replaceAll("*", " • ")}
                                                        </li>
                                                    })
                                                }
                                            </ul>

                                        </div>
                                        : null
                                }

                            </div>

                            <div className="ruler-2 mb-3"></div>
                        </div>
                    </div>
                </div>
            </Popup>


        </>
    );
}