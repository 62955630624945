import { DropDownInput } from "../../components/_input/_dropdown";
import { useState, useEffect, useContext, useCallback, useRef } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from '../../configuration/config.json';
import Popup from "../../User/popup";
import ConfirmContext from "../../utils/confirmContext";
import { useHistory } from "react-router";
import BranchesInfo from "../cart/component/BranchInfo";
import CouponControl from "../cart/component/CouponControl";
import { getData, User_Data_Key, saveData } from "../../utils/localStorageManager";
import _, { concat, sum, includes } from "lodash";
import { GetMessages, formValidate, } from '../../utils/validation';
import Joi from "joi";
import { RadioInputDynamic } from "../../components/_input/_radioInputDynamic";
import { toast } from 'react-toastify';
import SectionHeading from "../../components/sectionHeading";
import SMSCheck from "./components/smsCheck";
import { EvaluationType } from "../maintenance/components/EvaluationType";
import { Swiper, SwiperSlide } from "swiper/react";
import PhoneDropDown from "../../components/_input/_phoneDropDown";
import CustomItemServices from "../../components/CustomItemServices";
import { InputField } from "../../components/_input/_input";
import { CheckInput } from "../../components/_input/_checkInput";
import { Price } from "../../components/__simple/_price";
import { numberFormat } from "../../utils/currencyFormatter";

export function CarCheckoutCashPage({ vehicle, activeModel, isToyota, isLexus, selectedColor, finance, financeSettings, filterCarImages, ServiceParm }) {
    const { t: trans, i18n } = useTranslation(["new", "finance", "inputs", "info", "cart", "extra"]);
    let lang = (i18n.language || 'ar');
    const subFunctionRef = useRef(null);
    const thisConfirmContext = useContext(ConfirmContext);
    const location = useLocation();
    const history = useHistory();
    const [showFinalPopupMessage, setShowFinalPopupMessage] = useState();
    const { slug, sn } = useParams();
    const [isLoadingSendOrder, setLoadingSendOrder] = useState(false);
    const [sadadInfo, setSadadInfo] = useState(null);
    const [carInfo, setCarInfo] = useState(null);

    const [dataFullNum, setDataFullNum] = useState(0);
    const [stepNum, setStepNum] = useState(1);
    const [dataFinalPopup, setDataFinalPopup] = useState({});
    const [showFinalPopup, setShowFinalPopup] = useState(false);
    const [noteAccessories, setNoteAccessories] = useState("");
    const [requestForm, setRequestForm] = useState({ selectedNationality: "SAU" });
    const [scrollSnackUp, setScrollSnackUp] = useState({
        show: false,
        height: '16rem'
    });
    const allowCustGroups = ["G4", "G3"];
    const query = new URLSearchParams(location.search);
    const [paymentType, setPaymentType] = useState(null);
    const [OTP, setOTP] = useState("");
    const [error, setError] = useState({});

    const schema_0 = Joi.object({
    });
    const schema_1 = Joi.object({
        name: Joi.string().required().messages(GetMessages()),
        phoneNumber: Joi.string().min(12).required().messages(GetMessages()),
        City: Joi.string().required().messages(GetMessages()),
        accountType: Joi.string().required().messages(GetMessages()),
        identity: Joi.string().min(10).required().messages(GetMessages()),
        commercialIdentity: Joi.string().min(10).required().messages(GetMessages()),
    });
    const schema_2 = Joi.object({
        // OTP: Joi.string().required().min(4).messages(GetMessages()),
    });
    const schema_3 = Joi.object({
        paymentType: Joi.string().min(1).required().messages(GetMessages()),

    });
    const [propsData, setPropsData] = useState({});
    const [checkoutType, setCheckoutType] = useState(null);

    const [TotalTax, SetTotalTax] = useState(0)
    const [TotalDiscount, SetTotalDiscount] = useState(0);
    const [SumItemTotal, setSumItemTotal] = useState(0);
    const [SubTotal, setSubTotal] = useState(0);
    const [fullTotal, setfullTotal] = useState(0);
    const [MiniDownPayment, setMiniDownPayment] = useState(0);
    const [accountTypes, setAccountTypes] = useState([]);

    const [isClientAccount, setIsClientAccount] = useState(null);
    //-----------------------
    const [allServiceID, setAllServiceID] = useState([]);
    const [Service, setService] = useState(null);
    const [serviceImportantAdditions, setServiceImportantAdditions] = useState(null);
    const [indexPanel, setIndexPanel] = useState("1000");
    const [acticeService, setActiceService] = useState(null);
    const [evaluations, setEvaluations] = useState({
        evaluation_false_0: 0,
        evaluation_false_1: 0.2,
        evaluation_false_2: 0.2,
        evaluation_false_3: 0.2,
        evaluation_true_0: 0.2,
    });
    const evaluationOptions = [{
        titel: trans("new:windshield"),
        titelEn: "Windshield",
        mandatory: false,
        defultValue: 0
    },
    {
        titel: trans("new:rearGlass"),
        titelEn: "Rear glass",
        mandatory: true,
        defultValue: 0.2
    },
    {
        titel:  trans("new:frontSideGlass"),
        titelEn: "Front side glass",
        mandatory: true,
        defultValue: 0.2
    },
    {
        titel: trans("new:rearSideGlass"),
        titelEn: "Rear side glass",
        mandatory: true,
        defultValue: 0.2
    },];
    const [isToggleSwitch, setIsToggleSwitch] = useState(true);
    const [SalesInvoiceTrans, setSalesInvoiceTrans] = useState([]);
    const [acticeMenu, setActiceMenu] = useState("Saving Packages");
    const [allselectServices, setAllSelectServices] = useState([]);

    useEffect(() => {
        const parent = document.querySelector(".App");

        const handleScroll = () => {
            if (parent) {
                if (window.scrollY > 30) {
                    parent.style.overflow = "visible"; // Enable when scrolling
                } else {
                    parent.style.overflow = "hidden"; // Reset when at the top
                }
            }
        };

        // Attach scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Cleanup function
        return () => {
            window.removeEventListener("scroll", handleScroll);
            if (parent) {
                parent.style.overflow = ""; // Reset when unmounting
            }
        };
    }, []);
    const cachName = "checkoutCash_" + sn + "_" + slug;
    useEffect(() => {

        if (ServiceParm && ServiceParm.length > 0) {
            setService(ServiceParm);
        }


    }, [ServiceParm]);
    //-----------------------
    useEffect(() => {
        async function fetchData() {
            await handelGetCachData();

            axios.post(
                config.baseURL + 'func',
                {
                    "name": "SiteGetUserAccountTypes"
                }
            ).then(response => {
                //// console.log("accountType", response.data);
                setAccountTypes(response?.data?.filter(c => allowCustGroups?.includes(c.ID)));

            }).catch(err => {
                // console.log(err);
            })
            // ...
        }
        fetchData();

    }, []);
    const [platformTypes, setPlatformTypes] = useState([]);

    useEffect(() => {
        // تحميل الملف من مجلد public
        const loadManifest = async () => {
            try {
                const response = await fetch(`${process.env.PUBLIC_URL}/config.json`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                // Filter and map to extract keys with isActive = true
                var platformsTypes = data.platformsTypes || [];
                const activeKeys = platformsTypes
                    .filter((platform) => platform.isActive)
                    .map((platform) => platform.key);

                // console.log("Active keys:", activeKeys);

                setPlatformTypes(activeKeys);

            } catch (error) {
                console.error("Error loading manifest.json:", error);
            }
        };

        loadManifest();
    }, []);

    // const [subFunctionRef, setSubFunctionRef] = useState(null);
    // Function to call the CheckSMS-component's function
    // const handleRunSubSMSFunction = useCallback(() => {

    //     console.log("handleRunSubSMSFunction:", subFunctionRef.current);
    //     setOTP("");
    //     if (subFunctionRef?.current) {
    //         subFunctionRef.current();
    //     }
    // }, [subFunctionRef]);

    const timeoutRef = useRef(null); // مرجع لتخزين معرف المؤقت

    const handleRunSubSMSFunction = useCallback(() => {
        // إذا كان المؤقت السابق قيد التشغيل، قم بإلغائه
        if (timeoutRef.current) {
            clearInterval(timeoutRef.current);
            timeoutRef.current = null;
        }

        if (subFunctionRef.current) {
            console.log("Running subFunction immediately...");
            subFunctionRef.current();
        } else {
            console.warn("SMSCheck is not initialized yet! Waiting for 5 seconds...");

            let attempts = 0;
            timeoutRef.current = setInterval(() => {
                attempts++;

                if (subFunctionRef.current) {
                    console.log("SMSCheck loaded! Running subFunction...");
                    subFunctionRef.current();
                    clearInterval(timeoutRef.current); // إيقاف المؤقت عند نجاح العملية
                    timeoutRef.current = null; // إعادة تعيين المرجع
                } else if (attempts >= 5) {
                    console.warn("Timeout reached! SMSCheck was not initialized in time.");
                    clearInterval(timeoutRef.current);
                    timeoutRef.current = null;
                }
            }, 1000); // تحقق كل ثانية لمدة 5 ثوانٍ
        }
    }, [subFunctionRef]);




    useEffect(() => {
        //اجراء التاكد من حالة الدفع وتحديث حقل isPayment
        //Site_Carts_PaymentStatus
        const fetchPaymentStatus = async () => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var id = query.get("OrderGUID")?.trim();
            if (!id) return;

            try {
                const response = await axios.post(`${config.controllURL}api/Payment/CarPaymentStatus`, null, {
                    headers: myHeaders,
                    params: { ID: id },
                    responseType: "json"
                });

                const responseData = response?.data;

                if (!responseData) return;

                let data = Array.isArray(responseData)
                    ? responseData.find(p => p.Status === "success") || responseData[0]
                    : responseData;

                if (!data) return;

                if (data.Status === "success") {
                    if (data?.SadadNumber) setSadadInfo(data.SadadNumber);
                    setShowFinalPopup(true);
                    setDataFinalPopup(data);
                } else if (data?.ResponseText) {
                    try {
                        const jsonData = JSON.parse(data.ResponseText);
                        console.log("IsSuccess:", jsonData.IsSuccess);
                        console.log("InvoiceStatus:", jsonData.Data?.InvoiceStatus);

                        if (jsonData.IsSuccess == true && jsonData.Data?.InvoiceStatus) {
                            setShowFinalPopup(true);
                            setShowFinalPopupMessage(trans("new:contactSalesDepartment"));
                            data["InvoiceStatus"] = jsonData.Data?.InvoiceStatus;
                            setDataFinalPopup(data);
                        }
                    } catch (error) {
                        console.error("Invalid JSON:", error);
                    }
                } else if (data?.ErrorMessage) {
                    alert(`${data.ErrorMessage} / ${data?.ErrorProcedure || ""}`);
                }

            } catch (error) {
                console.error("Error fetching payment status:", error);
            }
        };

        // استدعاء الدالة عند الحاجة
        fetchPaymentStatus();


    }, []);
    var currentSize = 0;
    const CategoryCar = (Category) => {

        if (Size != currentSize) {
            currentSize = Category;
            SetSize(Category);
        }
    }
    const refrechDateCartItems = async (carInfo) => {

        Account(carInfo);


    }

    const handelSetRequestForm = useCallback((newData) => {
        // console.log("handelSetRequestForm>>>>>>>>>>>>>>:", newData);
        // console.log("requestForm>>>>>>>>>>>>>>:", requestForm);
        setRequestForm((prevRequestForm) => ({
            ...prevRequestForm,
            ...newData
        }));

    }, [requestForm]);

    const [personalDataRequest, setPersonalDataRequest] = useState({
        arFirstName: '',
        arMiddleName: '',
        arLastName: '',
        enFirstName: '',
        enMiddleName: '',
        enLastName: '',
        genderID: '',
        address: '',
        cityID: '',
        countryID: '',
        logo: ''
    });
    const handelSetPersonalData = useCallback(() => {


        // You can await here
        const isLogin = getData('isAuthenticated');
        if (isLogin) {


            const userData = JSON.parse(getData(User_Data_Key, true));
            // console.log("userData>>>>>>>>>>>>>>>>:", userData);
            if (userData) {
                setPersonalDataRequest(userData);
                var name = lang == "ar" ? userData?.arFirstName ?? "" + " " + userData?.arMiddleName ?? "" + " " + userData?.arLastName ?? ""
                    : userData?.enFirstName ?? "" + " " + userData?.enMiddleName ?? "" + " " + userData?.enLastName ?? "";
                userData["name"] = name;

                const val = {};
                val.name = name;

                const match = userData?.phone.match(/^(\+\d{1,4})(\d+)$/);

                const mainNumber = userData?.phone?.slice(-9);     // Last 9 digits, e.g., 530005261
                // Get everything before the last 9 digits as the country code
                const countryCode = userData?.phone?.slice(0, -9);
                val.phoneNumberCode = countryCode.replace("+", "");
                val.phoneNumberOnly = mainNumber;
                val.phoneNumber = userData?.phone ? userData?.phone : "";
                val.accountType = (userData?.custGroupID && allowCustGroups.includes(userData?.custGroupID)) ? userData?.custGroupID : allowCustGroups[0];
                val.isClientAccount = val.accountType === "G4" ? true : false;
                setIsClientAccount(val.isClientAccount);
                val.identity = userData?.identity ? userData?.identity : "";
                val.commercialIdentity = userData?.tradeNo ? userData?.tradeNo : "";

                handelSetRequestForm(val);
            }
        }

        // ...
    }, [allowCustGroups, handelSetRequestForm, lang, requestForm]);


    useEffect(() => {

        async function fetchData() {


            if (carInfo) {
                CategoryCar(carInfo?.groupID);
                await refrechDateCartItems(carInfo);

            }
            // ...
        }
        fetchData();

    }, [carInfo]);
    //Accounting for all items 
    function Account(p) {
        p.quantity = 1;
        let currentTotalTax = ((100 / 115) * ((p.MiniDownPayment ? p.MiniDownPayment : p.NetSalePrice) * p.quantity)) * 0.15;

        SetTotalTax(currentTotalTax);

        let currentTotalDiscount = calculateDiscount(p)
        SetTotalDiscount(currentTotalDiscount);

        let currentSumItemTotal = ((p.MiniDownPayment ? p.MiniDownPayment : p.NetSalePrice)) * p.quantity;

        setSumItemTotal(currentSumItemTotal);
        let currentSumfullTotal = (p.NetSalePrice) * p.quantity;

        setfullTotal(currentSumfullTotal);
        let currentSubTotal = ((p.MiniDownPayment ? p.MiniDownPayment : p.NetSalePrice)) * p.quantity;
        setSubTotal(currentSubTotal);


        let currentMiniDownPayment = (p.MiniDownPayment && p.MiniDownPayment != 0) ? p.MiniDownPayment * p.quantity : ((p.MiniDownPayment ? p.MiniDownPayment : p.NetSalePrice)) * p.quantity;
        setMiniDownPayment(currentMiniDownPayment);

    };
    // calculate discount
    const calculateDiscount = (itemInfo) => {
        let discountResult = [];
        try {
            if (couponDetail?.items) {
                //console.log(itemInfo);
                discountResult = couponDetail?.items.map((item) => {
                    let result = 0;
                    const { BrandID: brand, groupID, modelTypes, miniDownPayment, minPrice, quantity } = itemInfo;
                    if (brand == item?.brandID && groupID === item?.productGroupID && modelTypes === item?.productModelTypeID) {
                        result = getDiscountValueByType(item, minPrice, quantity);
                    }


                    return result;
                });
            }
        } catch (error) {
            //console.log(error);
        }
        return sum(discountResult) ?? 0;
    };

    //-----------------------------------------------
    const [couponDetail, setCouponDetail] = useState(null);
    const [clearCoupon, setClearCoupont] = useState(false);
    //to apply the coupon discount on the cart
    const applyCoupon = (code) => {
        const _id = getData("secretKey");
        if (code) {
            if (_id) {
                axios.request({
                    baseURL: config.controllURL,
                    url: lang + "/api/cart/CouponDetails",
                    method: "post",
                    data: {
                        "couponCode": code,
                        "id": _id
                    }
                }).then(response => {
                    const responseData = response.data;
                    if (responseData?.items?.length > 0) {
                        setCouponDetail(responseData);
                        setClearCoupont(true);
                    } else {
                        setCouponDetail(null);
                        setClearCoupont(false);
                    }
                }).catch(error => {
                    //console.log(error);
                })
            } else {
                // toast.error(trans("new:login_first"));
                thisConfirmContext.onShowRegistrationPup(true);
            }
        } else {
            SetTotalDiscount(0);
        }
    };

    // apply discount by type
    const getDiscountValueByType = (item, price, quantity) => {
        let result = 0;
        const { discountType, discountAmount, discountRate } = item;
        if (discountType?.toLowerCase() === 'amount') {
            const discountValue = discountAmount * quantity;
            result = discountValue;
        } else if (discountType?.toLowerCase() === 'rate') {
            const priceRateValue = ((price * quantity) * (discountRate / 100));
            result = priceRateValue;
        }
        return result;
    };
    useEffect(() => {
        handleFetchCountries();
        GetBranch();
    }, []);
    useEffect(() => {
        //console.log("props:", propsData);
        var mm = {
            ...propsData,
            vehicle, isToyota, isLexus, selectedColor, finance, financeSettings, filterCarImages
        }
        setPropsData(mm);

        if (selectedColor)
            setCarInfo({ ...selectedColor });

    }, [vehicle, isToyota, isLexus, selectedColor, finance, financeSettings, filterCarImages]);

    useEffect(() => {


        var id = query.get("OrderGUID")?.trim();
        if (id) return;
        // console.log("activeModel:", activeModel);
        if (activeModel && activeModel?.buyOnline != true) {
            setShowFinalPopupMessage(trans("new:chassisReserved"));
            setShowFinalPopup(true);

        }
    }, [activeModel]);

    const [Shipping, setShipping] = useState({ UnitTime: "", CountTime: 0, Cost: 0,CostWithTax:0, DateTimeDelevry: "" });
    const [dataShippingInfo, setDataShippingInfo] = useState({ "": [] });
    const [selectcity, setSelectcity] = useState(null);
    const [develviryType, setDeleviryType] = useState(2);
    const [ValueCitie, setValueCitie] = useState("");
    const [Size, SetSize] = useState(0);
    const [currentDeliveryMethod, setcurrentDeliveryMethod] = useState({
        branchMethod: true,
        locationMethod: false
    });
    const [ValueBranch, setValueBranch] = useState("");
    function getValueBranch(option) {
        setValueBranch(option.value);
    }
    const [Branches, setBranches] = useState([]);
    const [branchesDetails, setBranchesDetails] = useState([]);
    const GetUrlfunc = config.baseURL + 'func';
    async function GetBranch() {
        const Body = {
            "name": "site_FetchBranches"
        };
        axios.request({
            url: GetUrlfunc,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                const result = response.data;
                setBranches(result);
                const formatResult = result.map(item => {
                    return {
                        title: concat(item[`${trans("model:description")}`], item.Address ? concat('-', item.Address) : ''),
                        details: item.WorkingHoursFormat,
                        lat: item.Latitude,
                        long: item.Longitude
                    }
                });
                setBranchesDetails(formatResult);
            })
            .catch(function (error) {
                //console.log(error);
            });
    };

    async function CarShippingInfo(Citey, mSize) {
        setValueCitie(Citey.value)
        const Body = {
            "name": "Site_CarShippingInfo",
            "values": {
                "City": `${Citey.value}`,
                "categore": `${mSize}`
            }
        }
        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // //console.log(response.data.ApiObjectData);
                var currentvalue = { ...Shipping };
                currentvalue.Cost = response.data[0].CostShipping
                currentvalue.CostWithTax=response.data[0].CostShippingWithTax
                currentvalue.UnitTime = response.data[0].Type
                currentvalue.CountTime = response.data[0].TimeDelivary
                setShipping(currentvalue);
                //console.log(currentvalue);
            })
            .catch(function (error) {
                //console.log(error);
                var currentvalue = { ...Shipping };
                currentvalue.Cost = 0
                currentvalue.CostWithTax = 0
                currentvalue.UnitTime = trans("new:not_avilable");
                currentvalue.CountTime = null
                setShipping(currentvalue);
                //console.log(currentvalue);
            });

    }

    useEffect(() => {



        async function fetchData() {
            // You can await here
            await checkStepsErrorsDataFullNum();
            // ...
        }
        fetchData();

    }, [requestForm, evaluations]);
    const cheangeSteps = async (step) => {

        if (step > 0 && step <= 4
            // && step < stepNum
            && step != stepNum) {

            const statusStep = await checkStepsErrors();
            if (statusStep >= step) {
                setStepNum(step);

            }

        }

    }

    //----------------------
    const [filteredCities, setFilteredCities] = useState([]);
    useEffect(() => {


        async function fetchData() {
            try {
                const settingsResponse = await axios.request({
                    baseURL: config.controllURL,
                    url: lang + "/api/user/getSettings",
                    method: "get",
                });

                if (settingsResponse.status === 200) {
                    var mm = settingsResponse?.data?.genders.map(e => {
                        return { value: String(e.id), text: e.description }
                    });


                    setFilteredCities(settingsResponse?.data?.cities?.filter(item => {
                        return item.code == "SAU"
                    }));

                }


            } catch (err) {
                // console.log(err);
            }
            // ...
        }
        fetchData();
    }, [lang]);
    //-------------------------------------------
    const [cities, setCities] = useState([]);
    // to fetch all SAU countries
    const handleFetchCountries = async () => {
        let countries = [];
        try {
            const response = await axios.post(
                config.baseURL + 'func',
                {
                    "Name": "site_ShippingCities"
                }
            );
            if (response.status == 200) {
                countries = response.data.map(item => {
                    return { value: item.ID, text: item[`${trans("model:description")}`] }
                });
            }
        } catch (error) {
            //console.log('error in fetching the countries of SAU', error);
        }
        setCities(countries);
    };
    //---------------------
    const scrollToArticle = (id) => {

        try {
            const element = document.getElementById(id);
            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset; // موضع العنصر بالنسبة للصفحة
                const offset = elementPosition - window.innerHeight / 2; // ضبط ليكون العنصر في منتصف الشاشة

                window.scrollTo({
                    top: offset,
                    behavior: 'smooth', // تمرير ناعم
                });
            }
        } catch (error) {

        }



    };
    const checkAccessoriesErrorsDataFullNum = async () => {

        var result = formValidate(schema_0, requestForm) ?? {};

        const hasTintingService = allselectServices.find(s => allServiceID.includes(s.ServiceID) && (s?.text?.includes("تظليل") || s?.text?.includes("Tinting") || s["ServiceDetailsEn"]?.includes("tinting")
            || s["ServiceDetails"]?.includes("تظليل")));


        var noteTinting = "";
        if (hasTintingService) {

            if (isToggleSwitch == false) {
                if (evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0] == null || evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0] === "") {
                    noteTinting += "\\nزجاج امامي:" + "0";

                } else {
                    noteTinting += "\\nزجاج امامي:" + evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0];
                }
                if (evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 1] == null || evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 1] === "") {
                    result[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 1] = trans("الاختيار اجباري");
                    setActiceMenu(hasTintingService?.MainWebGroup);
                    setIndexPanel(hasTintingService?.ServiceID);
                    setActiceService(hasTintingService);
                    scrollToArticle(hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 1);
                } else {
                    noteTinting += "\\nزجاج خلفي:" + evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 1];

                }
                if (evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 2] == null || evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 2] === "") {
                    result[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 2] = trans("الاختيار اجباري");
                    setActiceMenu(hasTintingService?.MainWebGroup);
                    setIndexPanel(hasTintingService?.ServiceID);
                    setActiceService(hasTintingService);
                    scrollToArticle(hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 2);
                } else {
                    noteTinting += "\\nزجاج جانبي امامي:" + evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 2];
                }
                if (evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 3] == null || evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 3] === "") {
                    result[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 3] = trans("الاختيار اجباري");
                    setActiceMenu(hasTintingService?.MainWebGroup);
                    setIndexPanel(hasTintingService?.ServiceID);
                    setActiceService(hasTintingService);
                    scrollToArticle(hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 3);
                } else {
                    noteTinting += "\\nزجاج جانبي خلفي:" + evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 3];
                }



            } else {

                if (evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0] == null || evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0] === "") {
                    result[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0] = trans("الاختيار اجباري");
                    setActiceMenu(hasTintingService?.MainWebGroup);
                    setIndexPanel(hasTintingService?.ServiceID);
                    setActiceService(hasTintingService);
                    scrollToArticle(hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0);
                } else {
                    noteTinting += "\\n كامل زجاج السياره:" + evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0];
                }
            }

        }
        setNoteAccessories(noteTinting);
        //console.log(">>>>>>>>>>>>>>>>>result", result)
        setError(result);

        return result;

    }
    const checkStepsErrorsDataFullNum = async () => {
        var srempNumber = 0

 

        var result = formValidate(schema_1, requestForm) ?? {};
        let otherProperities = [];
        let newErrors = {};
        const val = { ...requestForm };
        if (isClientAccount) {

            otherProperities = ['commercialIdentity'];
        } else {
            otherProperities = ['identity'];
        }
        newErrors = otherProperities ? removeUnspecificError(result, otherProperities) : result;

        if (Object.keys(newErrors).length === 0) {
            srempNumber = 1;
        } else {
            setDataFullNum(srempNumber);
            return srempNumber;
        }

        const accessoriesResult = await checkAccessoriesErrorsDataFullNum();
        // console.log("accessoriesResult>>>>>>>>>>>>>>:", accessoriesResult);
        if (Object.keys(accessoriesResult).length === 0) {
            srempNumber = 2;
        } else {
            setDataFullNum(srempNumber);
            return srempNumber;
        }



        result = formValidate(schema_2, requestForm) ?? {};


        if (Object.keys(result).length === 0) {
            srempNumber = 3;
        } else {
            setDataFullNum(srempNumber);
            return srempNumber;
        }
        result = formValidate(schema_3, requestForm) ?? {};

        if (Object.keys(result).length === 0) {
            srempNumber = 4;
        } else {
            setDataFullNum(srempNumber);
            return srempNumber;
        }
        if (Object.keys(result).length === 0) {
            srempNumber = 4;
        } else {
            setDataFullNum(srempNumber);
            return srempNumber;
        }

        setDataFullNum(srempNumber);
        return srempNumber;
    }
    // to remove the unneeded props
    const removeUnspecificError = (errorList, values = []) => {
        let errorResult = {};
        if (values?.length > 0) {
            const lowerValues = values.map(item => {
                return item.toLowerCase()
            });
            Object.assign(errorResult, errorList);
            if (errorList) {
                for (let index = 0; index < Object.keys(errorList).length; index++) {
                    const key = Object.keys(errorList)[index];
                    if (includes(lowerValues, key.toLowerCase())) {
                        delete errorResult[key];
                    } else {
                        continue;
                    }
                }
            }
        }
        return errorResult;
    };
    const checkStepsErrors = async (valForm = {}) => {
        var srempNumber = 0
     
        var result = formValidate(schema_1, { ...requestForm, ...valForm }) ?? {};
        let otherProperities = [];
        let newErrors = {};
        const val = { ...requestForm };
        if (isClientAccount) {

            otherProperities = ['commercialIdentity'];
        } else {
            otherProperities = ['identity'];
        }
        newErrors = otherProperities ? removeUnspecificError(result, otherProperities) : result;
        if (stepNum == 1)
            setError(newErrors);


        // console.log("requestForm>>>>>>>>>>>>>>:", requestForm);
        // console.log("newErrors>>>>>>>>>>>>>>:", newErrors);

        if (Object.keys(newErrors).length === 0) {
            srempNumber = 1;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }
        result = formValidate(schema_2, { ...requestForm, ...valForm }) ?? {};
        // console.log("result222>>>>>>>>>>>>>>:", result);

        const accessoriesResult = await checkAccessoriesErrorsDataFullNum();
        // console.log("accessoriesResult>>>>>>>>>>>>>>:", accessoriesResult);
        if (Object.keys(accessoriesResult).length === 0) {
            srempNumber = 2;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }

        if (stepNum == 3)
            setError(result);

        if (Object.keys(result).length === 0) {
            srempNumber = 3;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }
        result = formValidate(schema_3, { ...requestForm, ...valForm }) ?? {};
        // console.log("result333>>>>>>>>>>>>>>:", result);
        if (stepNum == 4)
            setError(result);

        if (Object.keys(result).length === 0) {
            srempNumber = 4;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }

        if (stepNum == 4)
            setError(result);
        //console.log("result", result);
        if (Object.keys(result).length === 0) {
            srempNumber = 4;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }

        setDataFullNum(srempNumber);
        return srempNumber;
    }

    const handlePayment = async () => {

        const isLogin = getData('isAuthenticated');
        if (isLogin) {


            const cleanData = JSON.parse(getData(User_Data_Key, true));
            // console.log("handlePayment:", cleanData);
            const user_id = cleanData.id.toString().split('_')[1];
            if (user_id) {
                if ((!currentDeliveryMethod?.locationMethod) && (!currentDeliveryMethod?.branchMethod)) {
                    toast.warn(trans("new:not_delivery_selected"));
                } else if (currentDeliveryMethod?.locationMethod && (ValueCitie == '' || ValueCitie == null)) {
                    toast.warn(trans("new:not_delivery_selected_loc"));
                } else if (currentDeliveryMethod?.branchMethod && (ValueBranch == '' || ValueBranch == null)) {
                    toast.warn(trans("new:not_delivery_selected_branch"));
                } else {
                    await PaymentMAin(
                        1,

                        (currentDeliveryMethod?.locationMethod ?? false) ? ValueCitie : null,
                        (currentDeliveryMethod?.branchMethod) ?? false ? ValueBranch : null,
                        currentDeliveryMethod?.locationMethod ?? false,
                        currentDeliveryMethod?.branchMethod ?? false,
                        true,
                        false,
                        MiniDownPayment, SumItemTotal, Shipping.Cost, SubTotal, TotalTax, TotalDiscount,
                        //--------------
                        [{
                            id: carInfo?.ProductID,
                            quantity: 1,
                            colorID: carInfo?.ColorID,
                            Type: 1,
                            price: carInfo?.NetSalePrice,
                            CartID: null,
                            CostShipping:Shipping.Cost,
                            VIN: carInfo?.SN,
                            MiniDownPayment: carInfo?.MiniDownPayment ? carInfo?.MiniDownPayment : 5000
                        }]
                        //--------------
                        , parseInt(user_id), MiniDownPayment, paymentType, allServiceID);
                }
            } else {
                toast.error(trans("new:login_first"), {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        } else {
            thisConfirmContext.onShowRegistrationPup(true);

        }
    };

    //PaymentMAin
    async function PaymentMAin(status, InfoLocation, InfoBranch, ByLocation, ByBranch,
        IsReservation, IsPayment, PaymentAmount, FinalTotal, AmountShipping,
        SubAmountTotal, TotalTaxAmount, TotalDiscount, Product, UserID, MiniDownPayment, MethodPayment, Accessories) {


        handelSaveCachData();
        if (!isLoadingSendOrder) {
            //////console.log("handleSubmit:", requestForm);
            setLoadingSendOrder(true);

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const { ipv4 } = await getUserIpAddress();

            let listofServices;
            // allServiceID
            Accessories?.map((item) => {

                if (listofServices)
                    listofServices += "," + item;
                else
                    listofServices = item;
            });

            var raw = {
                "URL": `${window.location.origin}/${lang}/car/checkoutCash/${sn}/${slug}`,
                "Lang": lang,
                "Product": Product,
                "Accessories": listofServices,
                "NoteAccessories": noteAccessories,
                "UserID": UserID,
                "City": requestForm.City,
                "NotificationWhatsApp": requestForm.NotificationWhatsApp == true ? 1 : 0,
                "customerDetails": {
                    "IP": ipv4
                },
                "DataAmount": {
                    "FinalTotal": FinalTotal,
                    "AmountShipping": AmountShipping,
                    "SubAmountTotal": SubAmountTotal,
                    "TotalTaxAmount": TotalTaxAmount,
                    "TotalDiscount": TotalDiscount,
                    "MiniDownPayment": MiniDownPayment
                },
                "PaymentAmount": PaymentAmount,
                "TypePaymnet": {
                    "IsReservation": IsReservation,
                    "IsPayment": IsPayment,
                    "PaymnetTypeID": MethodPayment == "Financing" ? "Credit" : "Cash",//Credit - Cash
                    "MethodPayment": MethodPayment
                },
                "DeliveryType": {
                    "ByBranch": ByBranch,
                    "ByLocation": ByLocation
                },
                "DeliveryData": {
                    "InfoBranch": InfoBranch,
                    "InfoLocation": InfoLocation
                },
                "CobonData": {
                    "Code": couponDetail?.coupon?.code ?? null,
                    "ID": couponDetail?.coupon?.id ?? null,
                    "Amount": couponDetail?.coupon?.CAmount
                },
                "RefrancePayment": null,
                "status": status
            };
            console.log("Payment", raw);

            axios.request({
                url: config.controllURL + "api/Payment/CarPayment", headers: myHeaders,
                data: raw, method: "POST", responseType: "json"
            }).then(function (response) {
                // console.log('response1', response);
                //// console.log(response.data.URL_Payment);OrderId
                var responseData = null
                if (response && response?.data && response?.data?.length > 0) {
                    var dataRow = response?.data?.find(a => a.OrderId != null);
                    if (dataRow && dataRow.length > 0) {
                        responseData = dataRow[0];
                    } else {
                        responseData = response?.data[0];
                    }

                }
                // console.log('responseData', responseData);
                var invoiceID = responseData?.SadadNumber;
                if (invoiceID)
                    setSadadInfo(invoiceID);
                if (responseData?.URL_Payment) {

                    window.location.href = responseData?.URL_Payment;
                } else if (responseData?.MessageError && responseData?.MessageError == "Duplicate Request") {
                    window.location.href = `${window.location.origin}/${lang}/car/checkoutCash/${sn}/${slug}`;

                } else if (responseData?.OrderId && responseData?.ResponsMessage == "Paid") {

                    var data = responseData;
                    setShowFinalPopupMessage(trans("new:paymentAlreadyMade"));
                    setShowFinalPopup(true);
                    setDataFinalPopup(data);

                } else {
                    toast.error(trans("error:unknown_failure"));
                }



                setLoadingSendOrder(false);
            }
            ).then((response2) => {
                // console.log('response2', response2);

                setLoadingSendOrder(false);
            }).catch(error => {

                // console.log('error', error.messages)

                // alert(error.messages)

                setLoadingSendOrder(false);
            });
        }
    }
    // to get info of current ipv4
    const getUserIpAddress = async () => {
        let currentIpv4Info = {};
        try {
            const response = await axios.get('https://geolocation-db.com/json/');
            if (response.status === 200) {
                const { IPv4, country_code, country_name } = response.data;
                currentIpv4Info.ipv4 = IPv4;
                currentIpv4Info.countryCode = country_code;
                currentIpv4Info.countryName = country_name;
            }
        } catch (error) {
            toast.error(trans("extra:not_connection"))
            // console.log(error);
        }
        return currentIpv4Info;
    };

    const handelSaveCachData = async () => {

        try {
            var cachData = {
                requestFormParm: requestForm,
                allServiceIDParm: allServiceID,
                allselectServicesParm: allselectServices,
                dataFullNumParm: dataFullNum,
                stepNumParm: stepNum,
                evaluationsParm: evaluations,
                isClientAccountParm: isClientAccount
            };
            // console.log("handelSaveCachData>>>>>>>>>>>>>>>>>>>>>>>>:", cachData);
            saveData(cachName, cachData, true)
        } catch (error) {

        }

    }

    const handelGetCachData = useCallback(() => {

        try {
            //// console.log("handelGetCachData>>>>>>>>>>>>>>>>>>>>>>>>:", cachName);
            const cachdataobject = getData(cachName, true);
            //// console.log("cachdataobject>>>>>>>>>>>>>>>>>>>>>>>>:", cachdataobject);
            const cachdata = JSON.parse(cachdataobject);
            //// console.log("cachdata>>>>>>>>>>>>>>>>>>>>>>>>:", cachdata);
            const {
                requestFormParm,
                allServiceIDParm,
                allselectServicesParm,
                dataFullNumParm,
                stepNumParm,
                evaluationsParm,
                isClientAccountParm
            } = cachdata;
            // console.log("cachdata>>>>>>>>>>requestFormParm>>>>>>>>>>>>>>:", requestFormParm);
            if (requestFormParm) {
                const val = { ...requestFormParm };
                val.OTP = null;
                handelSetRequestForm(val);
            }

            if (isClientAccountParm != null)
                setIsClientAccount(isClientAccountParm);

            if (allServiceIDParm)
                setAllServiceID(allServiceIDParm);

            if (allselectServicesParm)
                setAllSelectServices(allselectServicesParm);

            if (dataFullNumParm) {
                var NewdataFullNumParm = dataFullNumParm > 2 ? 2 : dataFullNumParm
                setDataFullNum(NewdataFullNumParm);
            }


            // if (stepNumParm) {
            //     var NewstepNumParm = stepNumParm > 2 ? 2 : stepNumParm
            //     setStepNum(NewstepNumParm);
            // }


            if (evaluationsParm)
                setEvaluations(evaluationsParm);

        } catch (error) {
            console.warn(error.messages);
        }
        handelSetPersonalData();
    }, [requestForm]);
    return (
        <>
            <section className="carOverview-page  nice-relative carCheckout-page flex flex-col ">

                <div className="nice-sticky show-xs">

                    <div className="flex flex-col  nice-align-item-center   nice-shadow-1 bg-white w-100">
                        <div className=" flex flex-row justify-between nice-align-item-center py-10 px-10   w-100 ">

                            <img className=" rotate-180" src="/assets/icons/arrow-right.svg" width="7" height="7"
                                alt="arrow" onClick={() => {
                                    history.goBack();
                                }} />
                            <span className="text-30">شراء سيارة نقدا</span>
                            <span className=" text-24 text-gray"
                                onClick={() => {
                                    history.push({
                                        pathname: `/car/overview/${sn}/${slug}`,
                                        search: ""
                                    });
                                }}
                            >الغاء  </span>
                        </div>

                        <div className="nice-shadow-2 bg-white w-100">
                            <div className=" py-2 px-5 ">
                                {/* <div className="py-5 flex flex-row justify-between nice-align-item-center">
                                    <h3 className="text-30 text-bold ">{"تفاصيل الطلب"}</h3>

                                    
                                </div> */}

                                <div className="ruler-2 mb-5"></div>
                                <div className="carOverview-page-body flex flex-row justify-between nice-align-item-center">
                                    {

                                        (
                                            propsData?.vehicle ?
                                                <>

                                                    <div className="carOverview-page-body-desc">
                                                        <div className="carOverview-page-body-name">
                                                            <div className="carOverview-page-body-type ">
                                                                <div className="carOverview-page-body-type-content nice-gap-1rem">
                                                                    <h2 className="text-20 text-bold">{propsData?.vehicle?.brand && propsData?.vehicle?.brand}</h2>
                                                                    {/* <img src={vehicle?.brandIconPath && vehicle?.brandIconPath} /> */}
                                                                    <img className="" src={`/assets/icons/brands/${(isToyota ? "toyota" : "") + (isLexus ? "lexus" : "")}.webp`} />
                                                                </div>
                                                            </div>

                                                            <h3 className="text-20 text-gray ">{activeModel?.modelDescription && activeModel?.modelDescription}</h3>
                                                            {
                                                                carInfo ?

                                                                    <h3 className={"text-16 text-gray"}>{(carInfo[lang == "ar" ? `ColorDescriptionAr` : `ColorDescriptionEn`]?.split("/")[0])
                                                                    +" / "+(carInfo[lang == "ar" ? `ColorDescriptionAr` : `ColorDescriptionEn`]?.split("/")[1])
                                                                    }</h3>
                                                                    : null
                                                            }
                                                        </div>


                                                    </div>

                                                    <div className="carOverview-page-body-img"
                                                        style={{
                                                            maxWidth: "70%",

                                                        }}
                                                    >
                                                        <img className="carOverview-page-header-img"
                                                            src={config.FilePathURL + filterCarImages[0]?.Image_URL}
                                                            alt=""
                                                        />

                                                    </div>
                                                </>
                                                :
                                                <div className="container search-line my-40"><h4 className="text-gray text-20 py-2">{trans("new:no_data")}</h4></div>
                                        )


                                    }
                                </div>
                                <div className="ruler-2 mb-5"></div>
                                <div className="nice-container flex flex-col gap-3 justify-between">
                                    <div className="flex flex-col justify-center">
                                        {<>

                                            <div className="nice-flex nice-flex-row nice-flex-space-between mb-10">
                                                <h4 className="text-20">{trans("new:cash_price")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span> </h4>
                                                <span className="text-20 font-bold text-primary">{(carInfo?.NetSalePrice) && <Price price={parseFloat(carInfo?.NetSalePrice)} />}</span>
                                            </div>
                                            {
                                                allselectServices && allselectServices.length > 0 &&
                                                <div className="nice-flex nice-flex-row nice-flex-space-between mb-10">
                                                    <h4 className="text-20">{trans("new:accessories")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span> </h4>

                                                    <div dir="ltr" className={"flex text-20 text-error font-bold"}> {allselectServices?.reduce((total, service) => total + service.Price, 0) ? <>   <Price price={allselectServices?.reduce((total, service) => total + service.Price, 0)} className={"white-space-pre"} />{" + "} </> : ""}</div>
                                                </div>
                                            }


                                        </>}
                                    </div>

                                </div>





                            </div>
                        </div>
                        <div className="checkoutStepper nice-shadow-2 bg-white w-100 pt-10">
                            <div className={"checkoutStepper-part " + (dataFullNum > 0 ? "active" : " ")}
                                onClick={() => cheangeSteps(1)}
                            >
                                <div className={`checkoutStepper-part-titel ${stepNum == 1 ? " active" : null}`}>
                                    <span>{trans("new:addYourInformation")}</span>
                                </div>
                                <div className={"checkoutStepper-part-connector  "}></div>
                            </div>
                            <div className={"checkoutStepper-part " + (dataFullNum > 1 ? "active" : " ")}
                                onClick={() => cheangeSteps(2)}
                            >
                                <div className={`checkoutStepper-part-titel ${stepNum == 2 ? " active" : null}`}>
                                    <span>{trans("new:accessories")}</span>
                                </div>
                                <div className={"checkoutStepper-part-connector  "}></div>
                            </div>
                            <div className={"checkoutStepper-part " + (dataFullNum > 2 ? "active" : " ")}
                                onClick={() => cheangeSteps(3)}
                            >
                                <div className={`checkoutStepper-part-titel ${stepNum == 3 ? " active" : null}`}>
                                    <span> {trans("new:verifyCode")}</span>
                                </div>
                                <div className={"checkoutStepper-part-connector  "}></div>
                            </div>

                            <div className={"checkoutStepper-part " + (dataFullNum > 3 ? "active" : " ")}
                                onClick={() => cheangeSteps(4)}
                            >
                                <div className={`checkoutStepper-part-titel ${stepNum == 4 ? " active " : null}`}>
                                    <span>{trans("new:payment")}</span></div>
                                <div className={"checkoutStepper-part-connector  "}></div>
                            </div>


                        </div>

                    </div>
                </div>
                <div className="carOverview-page-content grid grid-cols-12 gap-5
                          justify-items-auto mx-30 mt-20">



                    <div className="carOverview-page-main  h-vh border-radius col-span-12 md:col-span-8 sm:col-span-8 lg:col-span-7 xl:col-span-7">

                        <div className="flex mb-10 hide-xs">
                            <div className={"btn nice-flex-row border-radius nice-shadow-1 nice-border-checkout-gray  bg-white px-10  py-5  text-24 "}
                                onClick={() => {
                                    history.push({
                                        pathname: `/car/overview/${sn}/${slug}`,
                                        search: ""
                                    });
                                }} >{trans("info:الغاء")} </div>
                        </div>


                        <div className=" px-20 carOverview-page-main nice-shadow-3 bg-white  h-vh border-radius col-span-12">


                            <div className="flex flex-col py-20  px-10 ">


                                <div className="checkoutStepper hide-xs">
                                    <div className={"checkoutStepper-part " + (dataFullNum > 0 ? "active" : " ")}
                                        onClick={() => cheangeSteps(1)}
                                    >
                                        <div className={`checkoutStepper-part-titel ${stepNum == 1 ? " active" : null}`}>
                                            <span>{trans("new:addYourInformation")}</span>
                                        </div>
                                        <div className={"checkoutStepper-part-connector  "}></div>
                                    </div>
                                    <div className={"checkoutStepper-part " + (dataFullNum > 1 ? "active" : " ")}
                                        onClick={() => cheangeSteps(2)}
                                    >
                                        <div className={`checkoutStepper-part-titel ${stepNum == 2 ? " active" : null}`}>
                                            <span>{trans("new:accessories")}</span>
                                        </div>
                                        <div className={"checkoutStepper-part-connector  "}></div>
                                    </div>
                                    <div className={"checkoutStepper-part " + (dataFullNum > 2 ? "active" : " ")}
                                        onClick={() => cheangeSteps(3)}
                                    >
                                        <div className={`checkoutStepper-part-titel ${stepNum == 3 ? " active" : null}`}>
                                            <span> {trans("new:verifyCode")}</span>
                                        </div>
                                        <div className={"checkoutStepper-part-connector  "}></div>
                                    </div>

                                    <div className={"checkoutStepper-part " + (dataFullNum > 3 ? "active" : " ")}
                                        onClick={() => cheangeSteps(4)}
                                    >
                                        <div className={`checkoutStepper-part-titel ${stepNum == 4 ? " active " : null}`}>
                                            <span>{trans("new:payment")}</span></div>
                                        <div className={"checkoutStepper-part-connector  "}></div>
                                    </div>


                                </div>

                                <form className="flex flex-col  my-5 " onSubmit={(e) => {
                                    // submitRequest(e);
                                }}>
                                    {
                                        stepNum == 2 &&
                                        <>

                                            {
                                                Service &&
                                                <div className={"protection-and-shading   " + (vehicle?.brandID == 1 ? " toyota-theme " : vehicle?.brandID == 2 ? " lexus-theme " : "")} >
                                                    <div className="protection-and-shading-menu more-services bg-secondary-lighter border-radius py-2 alert-indicator-target-area">
                                                        <div className=" pb-10 my-5">
                                                            {/* menu tabs */}
                                                            <div className=" nice-apply-theme px-10 ">
                                                                <Swiper
                                                                    spaceBetween={3}
                                                                    slidesPerView={3}
                                                                    breakpoints={{
                                                                        576: {
                                                                            slidesPerView: 3,
                                                                        },
                                                                    }}
                                                                    className={" my-8  nice-apply-theme mx-5"}
                                                                    resizeObserver={"true"}
                                                                >

                                                                    {[{ name: trans("new:savingPackages"), id: "Saving Packages" }, 
                                                                    { name:trans("new:protectionAndTinting"), id: "Protection And Shading" }, 
                                                                    { name: trans("new:specialServices"), id: "Special Services" }]?.map((_val, _index) => {
                                                                        return (

                                                                            <SwiperSlide key={_index} className={""}>
                                                                                <div className='flex h-100' onClick={() => {
                                                                                    setActiceService(null);
                                                                                    setActiceMenu(_val.id);
                                                                                    setIndexPanel(null);
                                                                                }}>

                                                                                    <div className={'protection-and-shading-menu-item nice-apply-theme bg-theme-up text-center bg-white ' + (acticeMenu == _val.id ? " active " : "  ")}>
                                                                                        <h3 className="text-20 font-bold">{lang == "ar" ? _val.name : _val.id} </h3>
                                                                                    </div>


                                                                                </div>
                                                                            </SwiperSlide>

                                                                        );
                                                                    })}



                                                                </Swiper>
                                                            </div>
                                                            {/* body */}
                                                            <div className="flex flex-col  nice-relative ">
                                                                {
                                                                    Service?.filter(s => s.MainWebGroup == acticeMenu).map((_Srtvice, _index) => {
                                                                        return (
                                                                            <div key={_index} className="panel-section mx-5">

                                                                                {
                                                                                    SalesInvoiceTrans.includes(_Srtvice.ServiceID) &&
                                                                                    <div className="panel-section-overlay">

                                                                                    </div>
                                                                                }

                                                                                <div className={`panel-content  ${(indexPanel == _Srtvice.ServiceID) ? "show-panel-info" : ""}`}>
                                                                                    <div className="panel-content-top"

                                                                                        onClick={(e) => {
                                                                                            setIndexPanel(null);
                                                                                            setActiceService(null);
                                                                                            if (indexPanel != _Srtvice.ServiceID) {
                                                                                                setIndexPanel(_Srtvice.ServiceID);
                                                                                                setActiceService(_Srtvice);
                                                                                            }


                                                                                        }}


                                                                                    >
                                                                                        <div className="protection-and-shading-menu-servie">
                                                                                            <h1 className={"text-20    font-bold " + (SalesInvoiceTrans.includes(_Srtvice.ServiceID) ? " text-gray " : " text-super-gray ") + (allServiceID.includes(_Srtvice.ServiceID) ? " selectd " : "")}>
                                                                                                {lang == "ar" ? _Srtvice.ServiceNameAR : _Srtvice.ServiceName}</h1>

                                                                                            <div className='protection-and-shading-menu-price flex flex-row  text-bold nice-justifiy-end '>

                                                                                                {
                                                                                                    _Srtvice?.PriceExcludeTax && <>  <Price price={_Srtvice?.PriceExcludeTax} /> {" + "}  </>
                                                                                                }
                                                                                            </div>
                                                                                        </div>

                                                                                        <img src="/assets/icons/arrow.svg" />
                                                                                    </div>

                                                                                    <div className="panel-content-body">

                                                                                        <ul>
                                                                                            {
                                                                                                _Srtvice[lang == "ar" ? "ServiceDetails" : "ServiceDetailsEn"]?.replaceAll("\\n\\n", "\\n")?.split('\n')?.map((note, index) => {

                                                                                                    return (

                                                                                                        note ?
                                                                                                            <li key={index} className="text-16 text-gray"  >
                                                                                                                {/* <span className="text-16 text-gray" >{note}</span> */}
                                                                                                                <span className="text-16 text-gray" dangerouslySetInnerHTML={{ __html: note }} />
                                                                                                            </li>
                                                                                                            : null

                                                                                                    );
                                                                                                })
                                                                                            }


                                                                                        </ul>




                                                                                        {
                                                                                            acticeService && (acticeService?.text?.includes("تظليل") || acticeService?.text?.includes("Tinting") || acticeService["ServiceDetailsEn"]?.includes("tinting")
                                                                                                || acticeService["ServiceDetails"]?.includes("تظليل")) &&
                                                                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem my-20">
                                                                                                <div className="flex flex-col">
                                                                                                    <div className="flex nice-flex-space-between">
                                                                                                        <div className='text-30 flex nice-gap-2rem'>
                                                                                                            <span className='text-20 text-bold flex'>{trans('info:Degree_of_shading')}</span>
                                                                                                        </div>


                                                                                                        <div className="flex flex-col nice-align-item-center">

                                                                                                            <div className="toggle-switch">
                                                                                                                <input type="checkbox" id="switch-2"
                                                                                                                    className="checkbox"
                                                                                                                    checked={isToggleSwitch}
                                                                                                                    onChange={(e) => {
                                                                                                                        //console.log("setIsToggleSwitch:", isToggleSwitch)
                                                                                                                        setIsToggleSwitch(isToggleSwitch ? false : true)

                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="switch-2" className="label-toggle flex nice-align-item-center"
                                                                                                                    style={{
                                                                                                                        display: "flex",
                                                                                                                        width: "7rem",
                                                                                                                        height: "2rem",
                                                                                                                        backgroundColor: "#C6C6C6"
                                                                                                                    }}>
                                                                                                                    <img
                                                                                                                        style={{
                                                                                                                            left: isToggleSwitch ? "-1rem" : "4rem",
                                                                                                                            top: "auto",
                                                                                                                            width: "3rem",
                                                                                                                            height: "3rem",
                                                                                                                            backgroundColor: isToggleSwitch ? "#6D6D6D" : "#3bd461"
                                                                                                                        }}
                                                                                                                        className="img-responsive"
                                                                                                                        alt="" />
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            <h4 className="text-20 text-center nice-apply-theme font-family-primary">{trans('info:multiple_degrees')}</h4>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="flex flex-row grid-2-cols nice-gap-2rem ">

                                                                                                        {

                                                                                                            (isToggleSwitch) ?


                                                                                                                [trans('info:full_car_glass')].map((item, index) => (

                                                                                                                    <EvaluationType
                                                                                                                        key={acticeService?.ServiceID + "_" + isToggleSwitch + "_" + index}
                                                                                                                        uniqueKey={acticeService?.ServiceID + "_" + isToggleSwitch + "_" + index} // Pass it explicitly

                                                                                                                        title={item}
                                                                                                                        defultActive={evaluations[acticeService?.ServiceID + "_" + isToggleSwitch + "_" + index]}
                                                                                                                        mandatory={item?.Mandatory}
                                                                                                                        error={error[acticeService?.ServiceID + "_" + isToggleSwitch + "_" + index]}
                                                                                                                        click={(val) => {

                                                                                                                            var evalua = { ...evaluations };
                                                                                                                            evalua[acticeService?.ServiceID + "_" + isToggleSwitch + "_" + index] = val;
                                                                                                                            // avg = avg + val;
                                                                                                                            setEvaluations(evalua);

                                                                                                                        }}
                                                                                                                    />
                                                                                                                ))


                                                                                                                :


                                                                                                                acticeService ?
                                                                                                                    evaluationOptions?.map((item, index) => (

                                                                                                                        <EvaluationType
                                                                                                                            key={acticeService?.ServiceID + "_" + isToggleSwitch + "_" + index}
                                                                                                                            uniqueKey={acticeService?.ServiceID + "_" + isToggleSwitch + "_" + index} // Pass it explicitly

                                                                                                                            title={lang == "ar" ? item.titel : item.titelEn}
                                                                                                                            defultActive={evaluations[acticeService?.ServiceID + "_" + isToggleSwitch + "_" + index]}
                                                                                                                            mandatory={item.mandatory}
                                                                                                                            error={error[acticeService?.ServiceID + "_" + isToggleSwitch + "_" + index]}
                                                                                                                            click={(val) => {

                                                                                                                                var evalua = { ...evaluations };
                                                                                                                                evalua[acticeService?.ServiceID + "_" + isToggleSwitch + "_" + index] = val;
                                                                                                                                // avg = avg + val;
                                                                                                                                //console.log("evalua", evalua);
                                                                                                                                setEvaluations(evalua);

                                                                                                                            }}
                                                                                                                        />
                                                                                                                    ))
                                                                                                                    : null

                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }

                                                                                        {
                                                                                            !SalesInvoiceTrans.includes(_Srtvice.ServiceID) && (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={() => {

                                                                                                        if (!allServiceID.includes(_Srtvice.ServiceID)) {
                                                                                                            // Add the service to the lists
                                                                                                            setAllSelectServices(prevServices => [...prevServices, _Srtvice]);

                                                                                                            setAllServiceID(prevServiceIDs => [...prevServiceIDs, _Srtvice.ServiceID]);
                                                                                                        } else {
                                                                                                            // Remove the service from the lists
                                                                                                            setAllSelectServices(prevServices =>
                                                                                                                prevServices.filter(service => service !== _Srtvice)
                                                                                                            );

                                                                                                            setAllServiceID(prevServiceIDs =>
                                                                                                                prevServiceIDs.filter(id => !_Srtvice.ServiceID.includes(id))
                                                                                                            );
                                                                                                        }
                                                                                                    }}
                                                                                                    className="protection-and-shading-menu-addbtn h-full  flex justify-center items-center">

                                                                                                    {

                                                                                                        allServiceID.includes(_Srtvice.ServiceID) ?
                                                                                                            <>
                                                                                                                <span className='text-white font-bold text-30 mx-5  capitalize'>{" - "}</span>
                                                                                                                <span className='text-white font-bold pl-2 text-24 pr-2 capitalize'>{trans('common:cancel')}</span>
                                                                                                            </>

                                                                                                            :
                                                                                                            <>
                                                                                                                <span className='text-white font-bold text-30 mx-5  capitalize'>{" + "}</span>
                                                                                                                <span className='text-white font-bold pl-2 text-24 pr-2 capitalize'>{trans('common:addition')}</span>
                                                                                                            </>



                                                                                                    }


                                                                                                </button>
                                                                                            )}


                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })

                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }


                                            {/* menu tabs */}
                                            {
                                                allselectServices && allselectServices.length > 0 &&
                                                <div className=" more-services bg-secondary-lighter border-radius py-2">

                                                    <div className=" py-5 flex flex-col ">
                                                        <h3 className="text-balck text-bold text-20  text-center ">
                                                            {trans("new:addedServices")}</h3>
                                                    </div>
                                                    <div className="my-10 nice-apply-theme nice-container nice-relative ">
                                                        <Swiper
                                                            spaceBetween={3}
                                                            slidesPerView={3}
                                                            pagination
                                                            breakpoints={{
                                                                576: {
                                                                    slidesPerView: 3,
                                                                },
                                                            }}
                                                            className={" my-8  nice-apply-theme"}
                                                            resizeObserver={"true"}
                                                        >

                                                            {allselectServices?.map((_val, _index) => {
                                                                return (

                                                                    <SwiperSlide key={_index} className={""}>
                                                                        <div className='flex  nice-relative overflow-hidden'>
                                                                            <CustomItemServices
                                                                                // title={trans("mobileCommon:finance")}
                                                                                title={lang == "ar" ? _val.ServiceNameAR : _val.ServiceName}
                                                                                borderCSS={"border-primary-box nice-flex-important nice-align-item-center my-5"}

                                                                            >
                                                                                <div className='border-box-periodic-checkInput'>
                                                                                    <CheckInput
                                                                                        checked={true}
                                                                                        className="text-primary cursor-pointer"
                                                                                        oncheng={async (target) => {
                                                                                            const isChecked = target.checked;

                                                                                            if (isChecked) {
                                                                                                // Add the service to the lists
                                                                                                setAllSelectServices(prevServices => [...prevServices, _val]);

                                                                                                setAllServiceID(prevServiceIDs => [...prevServiceIDs, _val.ServiceID]);
                                                                                            } else {
                                                                                                // Remove the service from the lists
                                                                                                setAllSelectServices(prevServices =>
                                                                                                    prevServices.filter(service => service !== _val)
                                                                                                );

                                                                                                setAllServiceID(prevServiceIDs =>
                                                                                                    prevServiceIDs.filter(id => !_val.ServiceID.includes(id))
                                                                                                );
                                                                                            }

                                                                                        }}
                                                                                        Name={"23-44"}
                                                                                    >
                                                                                    </CheckInput>
                                                                                </div>
                                                                            </CustomItemServices>
                                                                        </div>
                                                                    </SwiperSlide>

                                                                );
                                                            })}



                                                        </Swiper>
                                                    </div>
                                                </div>
                                            }
                                            <div className=" nice-container nice-relative">
                                                {
                                                    allselectServices && allselectServices.length > 0 &&
                                                    <div className="flex flex-col">

                                                        {
                                                            allselectServices?.map((_val, _index) => {
                                                                return (

                                                                    <div key={_index} className="cart-grid-info-div ">
                                                                        <div className={"text-14 text-gray mx-1"}>{lang == "ar" ? _val.ServiceNameAR : _val.ServiceName}&nbsp;</div>
                                                                        <div className={"text-14 text-gray mx-1"}> {_val?.PriceExcludeTax ? <Price price={_val?.PriceExcludeTax} className={"white-space-pre"} /> : ""}</div>

                                                                    </div>

                                                                );
                                                            })
                                                        }
                                                    </div>
                                                }

                                                {
                                                    allselectServices && allselectServices.length > 0 &&
                                                    <>
                                                        <div className="cart-grid-info-div border-tb">
                                                            <div className={"text-14  text-black"}>{trans("new:tax_value")}</div>
                                                            <div className={"text-14 text-black "}> {allselectServices?.reduce((total, service) => total + service.Price, 0) ? <Price price={allselectServices?.reduce((total, service) => total + (service.Price - service.PriceExcludeTax), 0)} className={"white-space-pre"} /> : ""}</div>
                                                        </div>
                                                        <div className="cart-grid-info-div border-tb">
                                                            <div className={"text-16 font-bold text-black"}>{trans("new:total")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span></div>
                                                            <div dir="ltr" className={"flex  text-20 text-error font-bold"}> {allselectServices?.reduce((total, service) => total + service.Price, 0) ? <>  <Price price={allselectServices?.reduce((total, service) => total + service.Price, 0)} className={"white-space-pre"} />{" + "} </> : ""}</div>
                                                        </div>
                                                    </>

                                                }

                                            </div>

                                            <div className="flex nice-flex-center  nice-align-item-center  gap-5 justify-items-auto p-10   my-20 ">

                                                <div className="border-box-periodic-checkInput col-span-12 md:col-span-7 sm:col-span-7 lg:col-span-7 xl:col-span-7">

                                                    <div className={"btn nice-flex-row  py-5 px-5 text-white text-24  bg-danger"}
                                                        onClick={async () => {
                                                            const num = await checkStepsErrors();

                                                            if (num >= (stepNum)) {

                                                                if (stepNum < 3) {
                                                                    setAllSelectServices([]);
                                                                    setAllServiceID([])
                                                                    setStepNum(stepNum + 1);
                                                                    handleRunSubSMSFunction();
                                                                    handelSaveCachData();
                                                                }

                                                            } else {
                                                                // checkErrors();
                                                            }
                                                        }}




                                                    >{trans("new:skip")} </div>

                                                </div>
                                                <div className="col-span-12 md:col-span-5 sm:col-span-5 lg:col-span-5 xl:col-span-5">

                                                    <div className={"btn nice-flex-row  py-5 px-5 text-white text-24 " + ((allselectServices && allselectServices.length > 0 && dataFullNum > 1) ? " bg-primary " : " bg-gray ")}
                                                        onClick={async () => {

                                                            if (allselectServices && allselectServices.length > 0) {

                                                                const num = await checkStepsErrors();

                                                                if (num >= (stepNum)) {

                                                                    if (stepNum < 3) {

                                                                        setStepNum(stepNum + 1);
                                                                        handleRunSubSMSFunction();
                                                                        handelSaveCachData();
                                                                    }

                                                                } else {
                                                                    // checkErrors();
                                                                }
                                                            }

                                                        }}




                                                    >{trans("new:add_and_complete")} </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        stepNum == 1 &&
                                        <>

                                            <h3 className="text-30 text-bold mb-10  ">{trans("new:addYourInformation")}</h3>
                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                <DropDownInput
                                                    disabled={personalDataRequest?.custGroupID}
                                                    title={trans("info:account_type")}
                                                    placeholder={trans("info:account_type")}
                                                    extraClasses={"dropdown-Input-module"}
                                                    selectedValue={requestForm.accountType}
                                                    errorLabel={error?.accountType}
                                                    options={[
                                                        ...accountTypes.map(item => {
                                                            if (lang === 'ar') {
                                                                return { text: item.DescriptionAr, value: item.ID, isClient: item.NeedIdentity }
                                                            } else {
                                                                return { text: item.DescriptionEn, value: item.ID, isClient: item.NeedIdentity }
                                                            }
                                                        })
                                                    ]}
                                                    onfilter={(item) => {

                                                        const val = { ...requestForm };
                                                        val.accountType = item.value;
                                                        val.isClientAccount = item.isClient;
                                                        setRequestForm(val);
                                                        if (item.isClient == undefined) {
                                                            setIsClientAccount(null);
                                                            return;
                                                        }
                                                        setIsClientAccount(item.isClient);
                                                    }}
                                                />
                                            </div>
                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                {isClientAccount != null && isClientAccount == true ?
                                                    <>
                                                        <InputField
                                                            key={"identity"}
                                                            length={10}
                                                            disabled={personalDataRequest?.identity}
                                                            moduleLabel={trans("form:identity-no")}
                                                            name='identity'
                                                            errorLabel={error?.identity}
                                                            extraClasses={"Input-module"}
                                                            value={requestForm?.identity}
                                                            onChangeValue={(e) => {
                                                                const val = { ...requestForm };
                                                                val.identity = e.target.value;
                                                                setRequestForm(val);
                                                            }}
                                                            placeholder={trans("form:identity-no")}
                                                        />

                                                    </>
                                                    : isClientAccount != null && isClientAccount == false ? <>
                                                        <InputField
                                                            key={"commercialIdentity"}
                                                            length={14}
                                                            name='commercialIdentity'
                                                            errorLabel={error?.commercialIdentity}
                                                            moduleLabel={trans("info:trade_no")}
                                                            extraClasses={"Input-module"}
                                                            value={requestForm?.commercialIdentity}
                                                            onChangeValue={(e) => {
                                                                const val = { ...requestForm };
                                                                val.commercialIdentity = e.target.value;
                                                                setRequestForm(val);
                                                            }}
                                                            placeholder={trans("info:trade_no")}
                                                        />

                                                    </> : null
                                                }
                                            </div>
                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                <InputField
                                                    placeholder={""}
                                                    disabled={personalDataRequest?.arFirstName || personalDataRequest?.enFirstName}
                                                    moduleLabel={trans("form:name")}
                                                    value={requestForm.name}
                                                    errorLabel={error.name}
                                                    extraClasses={"Input-module"}
                                                    onChangeValue={(e) => {
                                                        const val = { ...requestForm };
                                                        val.name = e.target.value;
                                                        setRequestForm(val);
                                                    }}
                                                    name='name'
                                                />
                                            </div>

                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                {

                                                    < PhoneDropDown
                                                        disabled={personalDataRequest?.phone}
                                                        mandotory={true}
                                                        name='phoneNumber'
                                                        moduleLabel={trans("new:mobile")}
                                                        extraClasses={"phone-Input-module"}
                                                        defaultFlag={requestForm.phoneNumberCode}
                                                        defaultValue={requestForm.phoneNumberOnly}
                                                        errorLabel={error?.phoneNumber}
                                                        onChangeValue={(countryCode, phoneNumber) => {
                                                            const val = { ...requestForm };
                                                            val.phoneNumberOnly = phoneNumber;
                                                            val.phoneNumberCode = countryCode;
                                                            val.phoneNumber = `+${countryCode}${phoneNumber}`;
                                                            setRequestForm(val);
                                                        }}

                                                    />
                                                }
                                            </div>


                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 px-5 ">
                                                <CheckInput
                                                    className="text-primary cursor-pointer"
                                                    checked={requestForm.NotificationWhatsApp}
                                                    oncheng={async (target) => {
                                                        // console.log("NotificationWhatsApp:", target.checked);
                                                        const val = { ...requestForm };
                                                        val.NotificationWhatsApp = target.checked;
                                                        setRequestForm(val);
                                                    }}
                                                    Name={"check-conectWat"}
                                                >

                                                    <span className="whatsapp-icon">
                                                        <img className="object-max-full " src='/assets/icons/social/wpImg.svg' width='25' height='25' />
                                                    </span>
                                                    <span className='text-20 nice-pointer text-inline-block'>
                                                        <span className='text-20 nice-pointer  text-inline-block'>{trans("info:  أود التواصل واستقبال تحديثات طلبي عن طريق الواتساب")}</span>
                                                    </span>
                                                </CheckInput>
                                            </div>
                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                <DropDownInput
                                                    title={trans("new:city")}
                                                    errorLabel={error.City}
                                                    extraClasses={"dropdown-Input-module"}
                                                    selectedValue={requestForm.City}
                                                    options={[...filteredCities?.map(item => {
                                                        return { text: item?.description, value: item?.id, optional: item?.code }
                                                    })]}
                                                    onfilter={(Citey) => {
                                                        const val = { ...requestForm };
                                                        val.City = Citey.value;
                                                        setRequestForm(val);
                                                        setSelectcity(Citey)

                                                    }}
                                                />
                                            </div>

                                            <div className="ruler-2 mt-20 mb-5"></div>

                                            <h3 className="text-30 text-bold mb-10  ">{trans("new:doYouHaveACode")}</h3>

                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                <CouponControl
                                                    onReset={() => {
                                                        setClearCoupont(false);
                                                        SetTotalDiscount(0);
                                                        setCouponDetail(null);
                                                    }}
                                                    onSubmit={(codeValue) => {
                                                        applyCoupon(codeValue);
                                                    }}
                                                    reset={clearCoupon}
                                                    discountPrice={TotalDiscount}
                                                    couponCode={couponDetail?.coupon?.description}
                                                />


                                            </div>


                                            <p className='text-20 text-gray text-bold text-inline-block mt-20' >{trans("new:depositRefundableMessage")}</p>


                                            <div className=" grid nice-align-item-center grid-cols-12 gap-5 justify-items-auto p-10   my-20 ">

                                                <div className="border-box-periodic-checkInput col-span-12 md:col-span-7 sm:col-span-7 lg:col-span-7 xl:col-span-7">


                                                </div>

                                                <div className="col-span-12 md:col-span-5 sm:col-span-5 lg:col-span-5 xl:col-span-5">

                                                    <div className={"btn nice-flex-row  py-5 px-5 text-white text-24 " + (dataFullNum > 0 ? " bg-primary " : " bg-gray ")}
                                                        onClick={async () => {
                                                            const num = await checkStepsErrors();

                                                            if (num >= (stepNum)) {

                                                                if (stepNum < 3) {

                                                                    setStepNum(stepNum + 1);

                                                                }

                                                            } else {
                                                                // checkErrors();
                                                            }
                                                        }}




                                                    >{trans("info:اكمل لدفع العربون")} </div>
                                                </div>

                                            </div>


                                        </>}


                                    {
                                        stepNum == 3 &&
                                        <>

                                            {/* {<SMSCheck
                                                mobile={requestForm?.phoneNumber}

                                                setSubFunctionRef={(ref) => { subFunctionRef.current = ref; }} // تخزين المرجع فقط
                                                SignUpData={requestForm}
                                                onSucess={async (sucsessData) => {
                                                    // console.log("SMS> Click  sucsessData>>>>>>", sucsessData)
                                                    var codeOtp = sucsessData?.OTP;
                                                    // console.log("SMS> Click Continue codeOtp>>>>>>", codeOtp)
                                                    setOTP(codeOtp);

                                                    const val = { ...requestForm };
                                                    val.OTP = codeOtp;
                                                    setRequestForm(val);
                                                    // console.log("SMS> Click Continue >>>>>>")
                                                    const num = await checkStepsErrors(val);

                                                    if (num >= (stepNum)) {

                                                        if (stepNum <= 3) {
                                                            setStepNum(stepNum + 1);
                                                            handelSaveCachData();
                                                        }
                                                    } else {
                                                        // checkErrors();
                                                    }
                                                }}

                                                goBack={async () => {
                                                    setOTP("");
                                                    setStepNum(stepNum - 1);
                                                    const val = { ...requestForm };
                                                    val.OTP = "";
                                                    setRequestForm(val);
                                                }}
                                            />} */}

                                        </>

                                    }


                                    {
                                        stepNum == 4 &&
                                        <>

                                            {/* <h3 className="text-30 text-bold mb-10  ">{" الدفع "}</h3> */}
                                            <SectionHeading
                                                hasRuler={false}
                                                title={trans("extra:choose_receive_way") + ":"}
                                            >

                                                <RadioInputDynamic
                                                    onChangeValue={(value) => {
                                                        const cunrrentvalue = currentDeliveryMethod;
                                                        setDeleviryType(value);
                                                        cunrrentvalue.branchMethod = value === 2 ? true : false;
                                                        cunrrentvalue.locationMethod = value === 1 ? true : false;
                                                        setcurrentDeliveryMethod(cunrrentvalue);

                                                        const val = { ...requestForm };
                                                        val.develviryType = value;
                                                        setRequestForm(val);

                                                    }}
                                                    extraClasses={"Radio-Input-module"}
                                                    selectValue={develviryType}
                                                    name='recieveMethod'
                                                    groupName="recieveMethod"
                                                    title={""}
                                                    ListValues={[{ text: trans("new:delivery"), value: 1 }, {
                                                        text: trans("new:branch"),
                                                        value: 2
                                                    }]}
                                                    errorLabel={error.develviryType}
                                                />
                                                {
                                                    <>
                                                        {develviryType == 2 &&
                                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                                <DropDownInput
                                                                    title={trans("cartFile:branch")}
                                                                    onSelect={(option) => {
                                                                    }}
                                                                    extraClasses={"dropdown-Input-module"}
                                                                    selectedValue={ValueBranch}
                                                                    options={[
                                                                        ...Branches.map(A => {
                                                                            if (lang === "ar") {
                                                                                return { value: A.SiteID, text: A.DescriptionAr };
                                                                            }
                                                                            else {
                                                                                return { value: A.SiteID, text: A.DescriptionEn };
                                                                            }

                                                                        })]}
                                                                    onfilter={getValueBranch}
                                                                />

                                                                <BranchesInfo
                                                                    values={branchesDetails}
                                                                />

                                                            </div>

                                                        }
                                                        {develviryType == 1 &&
                                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                                <DropDownInput
                                                                    extraClasses={"dropdown-Input-module"}
                                                                    title={trans("new:city")}
                                                                    selectedValue={ValueCitie}
                                                                    options={[...cities]}
                                                                    onfilter={(Citey) => CarShippingInfo(Citey, Size)}
                                                                />

                                                                <p className='text-20 text-gray text-inline-block' >{"سيتم اضافة تكاليف شحن السيارة لمدينتك على المبلغ الاجمالي"}</p>

                                                            </div>
                                                        }
                                                    </>
                                                }

                                            </SectionHeading>

                                            <>
                                                <div className="ruler-2 mt-20 mb-5"></div>

                                                {
                                                    <div className="cart-grid-info-div ">
                                                        <div className={"text-20 font-bold text-black"}>{(activeModel?.modelDescription)} {(activeModel?.year)}
                                                            &nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span></div>
                                                        <div className={"text-20 text-black nice-space-nowrap text-primary font-bold"}>{numberFormat((fullTotal), lang)}</div>
                                                    </div>
                                                }
                                                {
                                                    carInfo ?
                                                        <div className="cart-grid-info-div ">
                                                            <div className={"text-16 text-gray"}>{trans("new:exterior_color") + " : " + (carInfo[lang == "ar" ? `ColorDescriptionAr` : `ColorDescriptionEn`]?.split("/")[0])}</div>
                                                            <div className={"text-16 text-gray font-bold"}>{numberFormat(0, lang)}</div>
                                                        </div> : null
                                                }
                                                {
                                                    carInfo ?
                                                        <div className="cart-grid-info-div border-t">
                                                            <div className={"text-16 text-gray"}>{trans("new:interior_color") + ": " + (carInfo[lang == "ar" ? `ColorDescriptionAr` : `ColorDescriptionEn`]?.split("/")[1])}</div>
                                                            <div className={"text-16 text-gray font-bold"}>{numberFormat(0, lang)}</div>
                                                        </div> : null
                                                }
                                                {
                                                    develviryType == 1 && <>
                                                        {
                                                            Shipping.CountTime ?
                                                                <div className="cart-grid-info-div mb-8">
                                                                    <div className={"text-16 text-gray "}>{trans("cartFile:receive_peroid")}</div>
                                                                    <div className={"text-16 text-gray font-bold"}>{Shipping.CountTime} {Shipping.UnitTime} </div>
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            Shipping.CostWithTax ?
                                                                <div className="cart-grid-info-div">
                                                                    <div className={"text-16 text-gray"}>{trans("cartFile:shipping_costs")}</div>
                                                                    <div className={"text-16 text-gray font-bold"}>{numberFormat(Shipping.CostWithTax, lang)}</div>
                                                                </div>
                                                                : null

                                                        }

                                                    </>
                                                }

                                                {
                                                    TotalDiscount ?
                                                        <div className="cart-grid-info-div ">
                                                            <div className={"text-16 text-gray"}>{trans("cartFile:discount_total")}</div>
                                                            <div className={"text-16 text-gray font-bold"}>{numberFormat(TotalDiscount, lang)}</div>
                                                        </div> : null
                                                }

                                                {
                                                    allselectServices && allselectServices.length > 0 ?
                                                        <div className="cart-grid-info-div ">
                                                            <div className={"text-16 font-bold text-black"}>{trans("new:اجمالي الملحقات ")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span></div>
                                                            <div dir="ltr" className={"flex text-16 text-error font-bold"}> {allselectServices?.reduce((total, service) => total + service.Price, 0) ? <>   <Price price={allselectServices?.reduce((total, service) => total + service.Price, 0)} className={"white-space-pre"} /> {" + "} </> : ""}</div>

                                                        </div> : null
                                                }

                                                {

                                                    develviryType == 1 ?
                                                        <>
                                                            <div className="cart-grid-info-div border-tb">
                                                                <div className={"text-20 font-bold text-black"}>{trans("cart:total")}</div>
                                                                <div className={"text-20 text-black font-bold"}>{numberFormat(((allselectServices?.reduce((total, service) => total + service.Price, 0)) + fullTotal + Shipping.CostWithTax) - TotalDiscount, lang)}</div>
                                                            </div>
                                                        </>

                                                        :
                                                        <div className="cart-grid-info-div border-tb">
                                                            <div className={"text-20 font-bold text-black"}>{trans("cart:total")}</div>
                                                            <div className={"text-20 text-black font-bold"}>{numberFormat(((allselectServices?.reduce((total, service) => total + service.Price, 0)) + fullTotal) - TotalDiscount, lang)}</div>
                                                        </div>
                                                }



                                                {
                                                    SubTotal &&
                                                    <div className="cart-grid-info-div">
                                                        <div className={"text-16 text-gray"}>{trans("cartFile:Downpayment")}</div>
                                                        <div className={"text-16 text-gray font-bold"}>{numberFormat(SubTotal, lang)}</div>
                                                    </div>
                                                }


                                                <div className="cart-grid-info-div border-tb">
                                                    <div className={"text-20 font-bold text-black"}>{trans("new:min_down_payment")}</div>
                                                    <div className={"text-20 text-black font-bold"}>{numberFormat(MiniDownPayment, lang)}</div>
                                                </div>
                                            </>


                                            <div className="my-10 ">
                                                <div className="flex flex-col nice-gap-2rem">
                                                    <h3 className="text-gray text-20">{trans("new:avilable_platforms")}</h3>
                                                    <div className="breakout!! flex flex-col nice-gap-2rem ">


                                                        {
                                                            platformTypes && platformTypes.includes("payTabs") &&
                                                            <div className={"flex flex-col nice-gap-2rem   " + (paymentType == "PayTabs&SADAD" ? " bg-gray-lighter " : "")}

                                                                onClick={() => {
                                                                    setPaymentType("Myfatoorah&SADAD");
                                                                    const val = { ...requestForm };
                                                                    val.paymentType = "Myfatoorah&SADAD";
                                                                    setRequestForm(val);
                                                                }}
                                                            >
                                                                <label className={" text-20 font-bold flex flex-row nice-gap-1rem "}>
                                                                    <input type='radio' checked={paymentType == "PayTabs&SADAD" ? true : false} name='paymentType'

                                                                        id="primary"
                                                                    />
                                                                    {(trans("new:online_batch") + " " + trans("new:and_the_rest_via_SADAD"))}

                                                                </label>
                                                                <div className="flex flex-row items-center  mx-10">
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/master-card-3.png" alt={"master"} />
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/visa-3.png" alt={"visa"} />
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/mada-3.png" alt={"mada"} />

                                                                    <img className="cart-payment-icon object-contain" src={"/assets/icons/companies/SADAD-Logo.png"} alt={"SADAD"} />

                                                                </div>

                                                            </div>


                                                        }

                                                        {
                                                            platformTypes && platformTypes.includes("myfatoorah") &&
                                                            <div className={"flex flex-col nice-gap-2rem border-radius p-5 " + (paymentType == "Myfatoorah&SADAD" ? " bg-gray-light " : "")}
                                                                onClick={() => {
                                                                    setPaymentType("Myfatoorah&SADAD");
                                                                    const val = { ...requestForm };
                                                                    val.paymentType = "Myfatoorah&SADAD";
                                                                    setRequestForm(val);
                                                                }}
                                                            >
                                                                <label className={" text-20 font-bold flex flex-row nice-gap-1rem "}>
                                                                    <input type='radio' checked={paymentType == "Myfatoorah&SADAD" ? true : false} name='paymentType'


                                                                        id="primary"
                                                                    />
                                                                    {(trans("new:online_batch") + " " + trans("new:and_the_rest_via_SADAD"))}

                                                                </label>
                                                                <div className="flex flex-row items-center  mx-10">
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/master-card-3.png" alt={"master"} />
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/visa-3.png" alt={"visa"} />
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/mada-3.png" alt={"mada"} />
                                                                    <img className="cart-payment-icon object-contain" src={"/assets/icons/companies/SADAD-Logo.png"} alt={"SADAD"} />

                                                                </div>

                                                            </div>


                                                        }

                                                        {/* {
                                                            platformTypes && platformTypes.includes("myfatoorah") &&
                                                            <div className={"flex flex-col nice-gap-2rem border-radius p-5 " + (paymentType == "Myfatoorah" ? " bg-gray-light " : "")}
                                                                onClick={() => {
                                                                    setPaymentType("Myfatoora");
                                                                }}
                                                            >
                                                                <label className={" text-20 font-bold flex flex-row nice-gap-1rem "}>
                                                                    <input type='radio' checked={paymentType == "Myfatoorah" ? true : false} name='paymentType'


                                                                        onChange={() => {



                                                                        }}
                                                                        id="primary"
                                                                    />
                                                                    {trans("new:online_all")}

                                                                </label>
                                                                <div className="flex flex-row items-center  mx-10">
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/master-card-3.png" alt={"master"} />
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/visa-3.png" alt={"visa"} />
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/mada-3.png" alt={"mada"} />

                                                                </div>

                                                            </div>


                                                        } */}
                                                    </div>

                                                </div>
                                            </div>

                                            <div className=" grid nice-align-item-center grid-cols-12 gap-5 justify-items-auto p-10   my-20 ">

                                                <div className="border-box-periodic-checkInput col-span-12 md:col-span-8 sm:col-span-8 lg:col-span-8 xl:col-span-8">
                                                </div>

                                                <div className="col-span-12 md:col-span-4 sm:col-span-4 lg:col-span-4 xl:col-span-4">

                                                    <div className={"btn nice-flex-row  py-5 px-5 text-white text-30 " + (dataFullNum > 3 ? " bg-primary " : " bg-gray ")}
                                                        onClick={async () => {
                                                            // console.log("handlePayment:", stepNum);
                                                            const num = await checkStepsErrors();
                                                            // const num = 4;
                                                            // console.log("handlePayment:", num);
                                                            if (num >= (stepNum)) {
                                                                // console.log("handlePayment:", num);
                                                                // if (stepNum < 4) {
                                                                setStepNum(4);
                                                                handlePayment();
                                                                // }

                                                            } else {
                                                                // checkErrors();
                                                            }
                                                        }}




                                                    >
                                                        {
                                                            isLoadingSendOrder && <span className='mx-2 loading-btn '>
                                                                <img className="object-max-full " src='/assets/icons/loading.svg' width='25' height='25' />
                                                            </span>
                                                        }

                                                        {trans("info:دفع")}

                                                    </div>
                                                </div>

                                            </div>


                                        </>}
                                </form>


                            </div>




                            {/* //------------------------ */}

                            <div id="parent-footer"
                                className="eparts-page-footer bg-theme-up show-xs"
                                style={{ height: scrollSnackUp.height }}
                                onTouchEnd={(event) => {
                                    var hh = "70rem";
                                    let vx = document.getElementById("parent-footer");
                                    if (vx) {
                                        var hh = vx.scrollHeight + 50;
                                        //console.log(hh);  // Check the scroll height value
                                    }

                                    if (!event?.target?.id.includes("footer-actions")) {
                                        const currentValue = { ...scrollSnackUp };
                                        currentValue.show = !currentValue.show;
                                        if (currentValue.show) {
                                            currentValue.height = hh;
                                        } else {
                                            currentValue.height = '85px';
                                        }
                                        setScrollSnackUp(currentValue);

                                    }

                                }}>

                                {

                                    (


                                        ((carInfo?.buyOnline == true)) ? (
                                            <>


                                                <div className="flex flex-row justify-between">

                                                    <div className="" >

                                                        <div className="carOverview-page-body-desc">
                                                            <div className="carOverview-page-body-name">
                                                                <div className="carOverview-page-body-type">
                                                                    <div className="carOverview-page-body-type-content nice-gap-1rem">
                                                                        <h2 className="text-20 text-bold">{propsData?.vehicle?.brand && propsData?.vehicle?.brand}</h2>
                                                                        {/* <img src={vehicle?.brandIconPath && vehicle?.brandIconPath} /> */}
                                                                        <img className="" src={`/assets/icons/brands/${(isToyota ? "toyota" : "") + (isLexus ? "lexus" : "")}.webp`} />
                                                                    </div>
                                                                </div>

                                                                <h3 className="text-30 text-bold">{vehicle?.name && vehicle?.name}</h3>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col justify-center">
                                                        {<>
                                                            <div className="flex flex-row gap-1">
                                                                <div className="flex flex-row gap-1">
                                                                    <span className="text-30 font-bold text-primary">{(carInfo?.NetSalePrice) && <Price price={parseFloat(carInfo?.NetSalePrice)} />}</span>
                                                                </div>
                                                            </div>

                                                        </>}
                                                    </div>

                                                </div >
                                                {
                                                    // scrollSnackUp.show &&
                                                    <div className="flex flex-col nice-gap-1rem py-5">


                                                        <div className="carOverview-page-body-desc">

                                                            {/* <div className="nice-container carOverview-page-body-number flex flex-row nice-gap-1rem my-10">
                                                                <span className="text-20 text-gray"> {trans("new:item_no")}</span>
                                                                <span className="text-20 text-gray">{vehicle?.productNo ? vehicle?.productNo : "########"}</span>
                                                            </div> */}
                                                        </div>

                                                        <div className="carOverview-page-body-img">
                                                            <img className="carOverview-page-header-img"
                                                                src={config.FilePathURL + filterCarImages[0]?.Image_URL}
                                                                alt=""
                                                            />

                                                        </div>





                                                        <div className="nice-flex nice-flex-row nice-flex-space-between mb-10">
                                                            <h4 className="text-20">{trans("new:cash_price")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span> </h4>
                                                            <span className="text-20 font-bold text-primary">{(carInfo?.NetSalePrice) && <Price price={parseFloat(carInfo?.NetSalePrice)} />}</span>
                                                        </div>
                                                        {
                                                            allselectServices && allselectServices.length > 0 &&
                                                            < div className="nice-flex nice-flex-row nice-flex-space-between mb-10">
                                                                <h4 className="text-20">{trans("new:accessories")}</h4>
                                                                <div dir="ltr" className={"flex text-20 text-error font-bold"}> {allselectServices?.reduce((total, service) => total + service.Price, 0) ? <>  <Price price={allselectServices?.reduce((total, service) => total + service.Price, 0)} className={"white-space-pre"} />{" + "} </> : ""}</div>
                                                            </div>
                                                        }

                                                    </div>


                                                }
                                            </>
                                        )

                                            :
                                            (

                                                <div className="flex flex-row justify-between">
                                                    <div className="flex flex-col justify-center">


                                                    </div>


                                                    <div id="footer-actions-buy" className="flex flex-col btn-notfound bg-gray-light p-5">
                                                        <div id="footer-actions-buy" className="flex flex-row items-center justify-center btn-notfound-available">
                                                            <p id="footer-actions-buy" className="text-center  text-24  text-danger ">
                                                                {trans("new:not_available")}
                                                            </p>
                                                        </div>

                                                        <div className="border-l-5 my-5 mx-5 text-gray"></div>


                                                        <div id="footer-actions-buy" className="flex flex-row basis-2/4 items-center btn-notfound-alert"

                                                            onClick={() => {

                                                            }}
                                                        >
                                                            <p id="footer-actions-buy" className="text-center text-alert text-24  text-line-clamp   ">
                                                                {trans("new:alert_when_available")}
                                                            </p>
                                                            <img className="  " src="/assets/icons/alert-icon.svg" />
                                                        </div>
                                                    </div>


                                                </div>
                                            )




                                    )

                                }


                            </div >




                            {/* //----------------- */}
                        </div >
                    </div >

                    <div className=" hide-xs col-span-0  md:col-span-4 sm:col-span-4 lg:col-span-5 xl:col-span-5 ">
                        <div className="carOverview-page-sub pt-20 nice-shadow-3 border-radius bg-white ">
                            <div className=" py-10 px-5 ">
                                <div className="py-5">
                                    <h3 className="text-30 text-bold ">{trans("new:orderDetails")}</h3>
                                </div>

                                <div className="ruler-2 mb-5"></div>
                                <div className="carOverview-page-body">
                                    {

                                        (
                                            propsData?.vehicle ?
                                                <>

                                                    <div className="carOverview-page-body-desc">
                                                        <div className="carOverview-page-body-name">
                                                            <div className="carOverview-page-body-type">
                                                                <div className="carOverview-page-body-type-content nice-gap-1rem">
                                                                    <h2 className="text-20 text-bold">{propsData?.vehicle?.brand && propsData?.vehicle?.brand}</h2>
                                                                    {/* <img src={vehicle?.brandIconPath && vehicle?.brandIconPath} /> */}
                                                                    <img className="" src={`/assets/icons/brands/${(isToyota ? "toyota" : "") + (isLexus ? "lexus" : "")}.webp`} />
                                                                </div>
                                                            </div>

                                                            <h3 className="text-20 text-gray ">{activeModel?.modelDescription && activeModel?.modelDescription}</h3>
                                                            {
                                                                carInfo ?

                                                                    <h3 className={"text-16 text-gray"}>{(carInfo[lang == "ar" ? `ColorDescriptionAr` : `ColorDescriptionEn`]?.split("/")[0])
                                                                    +" / "+(carInfo[lang == "ar" ? `ColorDescriptionAr` : `ColorDescriptionEn`]?.split("/")[1])
                                                                    }</h3>
                                                                    : null
                                                            }
                                                        </div>
                                                        {/* <div className="nice-container carOverview-page-body-number flex flex-row nice-gap-1rem my-10">
                                                            <span className="text-20 text-gray"> {trans("new:item_no")}</span>
                                                            <span className="text-20 text-gray">{vehicle?.productNo ? vehicle?.productNo : "########"}</span>
                                                        </div> */}
                                                    </div>

                                                    <div className="carOverview-page-body-img">
                                                        <img className="carOverview-page-header-img"
                                                            // src={"data:image;base64," + image.DATA}
                                                            src={config.FilePathURL + filterCarImages[0]?.Image_URL}
                                                            alt=""
                                                        />

                                                    </div>
                                                </>
                                                :
                                                <div className="container search-line my-40"><h4 className="text-gray text-20 py-2">{trans("new:no_data")}</h4></div>
                                        )


                                    }
                                </div>
                                <div className="ruler-2 mb-5"></div>
                                <div className="nice-container flex flex-col gap-3 justify-between">
                                    <div className="flex flex-col justify-center">
                                        {<>

                                            <div className="nice-flex nice-flex-row nice-flex-space-between mb-10">
                                                <h4 className="text-20">{trans("new:cash_price")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span> </h4>
                                                <span className="text-20 font-bold text-primary">{(carInfo?.NetSalePrice) && <Price price={parseFloat(carInfo?.NetSalePrice)} />}</span>
                                            </div>
                                            {
                                                allselectServices && allselectServices.length > 0 &&
                                                <div className="nice-flex nice-flex-row nice-flex-space-between mb-10">
                                                    <h4 className="text-20">{trans("new:accessories")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span> </h4>

                                                    <div dir="ltr" className={"flex text-20 text-error font-bold"}> {allselectServices?.reduce((total, service) => total + service.Price, 0) ? <>   <Price price={allselectServices?.reduce((total, service) => total + service.Price, 0)} className={"white-space-pre"} />{" + "} </> : ""}</div>
                                                </div>
                                            }


                                        </>}
                                    </div>

                                </div>



                                {/* {
                                <div className="flex flex-col nice-gap-1rem py-5">


                                    {
                                        finance?.totalPrice ?
                                            <>
                                                <p className="text-center text-24 mt-10">
                                                    {trans("new:installment_price")}
                                                </p>
                                                <div className="nice-ruler-base bg-secondary"></div>

                                                <div className="nice-flex nice-flex-column nice-gap-1rem">
                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                        <h3 className="text-gray-dark text-16">{trans("finance:finance")}</h3>
                                                        <p className={"text-primary-dark text-16"}>{lang == "ar" ? financeSettings.DescriptionAr : financeSettings.DescriptionEn}</p>
                                                    </div>
                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                        <h3 className="text-gray-dark text-16">{trans("finance:period")}</h3>
                                                        <p className={"text-primary-dark text-16"}>{finance?.period}</p>
                                                    </div>
                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                        <h3 className="text-gray-dark text-16">{trans("finance:management_fees")}</h3>
                                                        <p className={"text-primary-dark text-16"}><Price price={finance?.mangFees} showZero={true} /></p>
                                                    </div>
                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                        <h3 className="text-gray-dark text-16">{trans("finance:monthPayment")}</h3>
                                                        <p className={"text-primary-dark text-16"}><Price price={finance?.price} showZero={true} /></p>
                                                    </div>
                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                        <h3 className="text-gray-dark text-16">{trans("finance:advance_payment")}</h3>
                                                        <p className={"text-primary-dark text-16"}> <Price price={finance?.first} showZero={true} /></p>
                                                    </div>
                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                        <h3 className="text-gray-dark text-16">{trans("finance:lastPayment")}</h3>
                                                        <p className={"text-primary-dark text-16"}> <Price price={finance?.last} showZero={true} /></p>
                                                    </div>

                                                    <div className="mx-2 my-10 nice-flex nice-flex-row">
                                                        <img src="/assets/icons/info.svg" className="mx-3 text-inline-block nice-max-icon" />
                                                        <p className="text-12 text-bold text-gray text-inline-block">{trans("finance:This_calculation_is_estimate")} </p>
                                                    </div>
                                                </div>

                                                <div className="nice-ruler-base bg-secondary"></div>

                                            </>
                                            : null

                                    }


                                </div>
                            } */}


                            </div>
                        </div>
                    </div>
                </div >


            </section >

            <Popup show={showFinalPopup}>
                <div className="w-full  flex flex-column justify-center items-center" >
                    <div className="cart-finalized" >
                        <div className="cart-finalized-container flex flex-col">

                            <div className="cart-finalized-container-img flex flex-col">
                                <img src="/assets/images/finalized.svg" />
                            </div>

                            <div className="flex flex-wrap justify-center">
                                <span className="text-20 font-bold text-black mx-2 unselectable ">{trans("new:thank_to_choose")}</span>
                                <span className="text-20 font-bold text-primary mx-2"> {trans("new:HJ_cars")}</span>
                            </div>

                            {showFinalPopupMessage &&
                                <>
                                    <div className="flex mt-10 flex-wrap justify-center mx-20">
                                        <span className="text-24 text-gray  mx-2"> {showFinalPopupMessage}</span>
                                    </div>

                                </>

                            }
                            {dataFinalPopup?.InvoiceStatus &&
                                <>
                                    <div className="flex flex-wrap justify-center mx-20">
                                        <span className="text-24 text-gray  mx-2"> {trans("new:حالة الدفع") + ":"}</span>
                                        <span className="text-24 text-gray  mx-2"> {dataFinalPopup?.InvoiceStatus}</span>
                                    </div>

                                </>

                            }
                            {
                                dataFinalPopup &&
                                <>

                                    {
                                        dataFinalPopup?.OrderId &&
                                        <div className="flex flex-row justify-center cart-finalized-code"
                                            onClick={() => {

                                                navigator.clipboard.writeText(dataFinalPopup?.OrderId);
                                            }}

                                        >

                                            <span className="text-20 font-bold text-white  mx-2"> {trans("info:order-number") + " :"}</span>
                                            <span className="text-20 font-bold text-white  mx-2">{dataFinalPopup?.OrderId && dataFinalPopup?.OrderId}</span>
                                            <img src="/assets/icons/copy.svg" alt='' />
                                        </div>
                                    }
                                </>

                            }

                            {
                                sadadInfo &&
                                <>

                                    <div className="flex mt-10  flex-wrap justify-center mx-20">
                                        <span className="text-24 text-center text-gray  mx-2"> {trans("new:a_payment_number_has_already_been_created")
                                            + " " + trans("new:to_avoid_cancellation_of_the_order_please_pay_an_amount")
                                            + " " + trans("new:within_24_hours")}</span>
                                    </div>

                                    <div className="flex flex-row nice-align-item-center">
                                        <div className="h-100 mt-10 p-5">
                                            <img className=" object-contain" width={50} height={60}
                                                src={"/assets/icons/companies/SADAD-Logo.png"} alt={"SADAD"} />
                                        </div>
                                        {
                                            <div className="flex flex-row justify-center cart-finalized-code mt-2"
                                                onClick={() => {

                                                    navigator.clipboard.writeText(sadadInfo);
                                                }}

                                            >

                                                {/* <span className="text-20 font-bold text-white  mx-2"> {trans("new:sadad") + " :"}</span> */}
                                                <span className="text-20 font-bold text-white  mx-2">{sadadInfo}</span>
                                                <img src="/assets/icons/copy.svg" alt='' />
                                            </div>
                                        }
                                    </div>

                                </>

                            }
                            <div className="flex flex-center flex-col  mt-5 pt-5 w-80">
                                <div className=" mb-5 w-100">
                                    <div className="nice-sm-btn cart-notification-go  bg-primary text-white text-16" onClick={() => {


                                        if (location.pathname)
                                            history.push("/");

                                    }}>
                                        {trans("cartFile:Continue_browsing")}
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </Popup>
        </>
    );
}