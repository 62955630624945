import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Price } from "../../../components/__simple/_price";
import OwnOrderContext from '../../components/ownOrders/ownOrderContext';
import config from "../../../configuration/config.json";
import { CountdownTimer } from "../../../components/CountdownTimer";
import moment from 'moment';
export const OwnOrderDetails = ({ orderNumber = '',
    orderStatusText = '',
    orderDate = '',
    deliveryLocation = '',
    deliveryMethod = '',
    amount = 0,
    shippingPrice = 0,
    finalTotal = 0,
    paidAmount = 0,
    ValidUntilNotComplatingPay = null,
    orderHistory = [] }) => {
    const { t: trans, i18n } = useTranslation("ownOrder");
    const lang = (i18n.language || 'ar');
    const dir = lang == 'en' ? 'left' : 'right';
    const { handleChangeParentState } = useContext(OwnOrderContext);
    return (
        <div className="own-order-details-box bg-white p-5 mb-4">
            <div className="own-order-details-box-header flex nice-flex-row p-5 mb-4">
                <div className="text-16">
                    {trans("ownOrder:order_no")} <span className="text-inline-block text-16 text-bold">{orderNumber}</span>
                </div>
                <div className="flex nice-flex-row">
                    <div className="box-circle nice-pointer bg-black text-bold text-16 return-btn nice-flex-center text-white status-detail"
                        onClick={() => {
                            handleChangeParentState({ showDetailsBox: false, showHistoryBox: true });
                        }}>
                        <img src="/assets/icons/back.svg" />
                        {trans("ownOrder:back")}
                    </div>
                    <div className="box-circle  bg-primary text-bold text-16 flex nice-flex-center text-white status-detail">
                        {orderStatusText}
                    </div>
                </div>
            </div>
            <div className="amazing-ruller my-4"></div>
            <h1 className="text-16 text-bold text-gray my-5">{trans("ownOrder:order-record")}</h1>
            <div className={`own-order-details-box-records flex nice-flex-column  gap-2 nice-border-primary-${dir} p-5 mx-2`}>
                {
                    orderHistory?.map((item, index) => {
                        return <section className={`own-order-details-box-records-item own-order-details-box-records-item-${dir} flex mb-4`}>
                            <div className="flex nice-flex-row nice-align-item-center">
                                <div className="info-circle flex nice-flex-center mx-2">
                                    <img src="/assets/icons/info.svg" alt="" />
                                </div>
                                <div className="flex nice-flex-column">
                                    <h1 className="text-16 text-bold text-black">{lang === 'ar' ? item.DescriptionAr : item.DescriptionEn} </h1>
                                    <div className="my-2">
                                        <span className=" text-14 text-graytext-inline-block">{new moment(item.Createdat).format('yyyy-MM-DD')}</span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    })
                }
            </div>
            <div className="amazing-ruller my-10"></div>
            <div className="my-8">
                <h1 className="text-16 text-bold text-gray my-4">{trans("ownOrder:order_date")}</h1>
                <div className="my-5">
                    <span className="text-16 text-gray text-inline-block">{new moment(orderDate).format('yyyy-MM-DD')}</span>

                    {/* <span className=" text-16 text-gray text-inline-block mx-2">|</span>
                    <span className=" text-16 text-graytext-inline-block">2021 - 5 -12</span></div> */}
                </div>
                <div className="my-8">
                    <h1 className="text-16 text-bold text-gray my-4">{trans("ownOrder:deleviry_method")}</h1>
                    <div className="text-16">{deliveryMethod}</div>
                </div>
                <div className="my-8">
                    <h1 className="text-16 text-bold text-gray my-4">{trans("ownOrder:deleviry_place")}</h1>
                    <div className="text-16"> {deliveryLocation}</div>
                </div>

                <div className="amazing-ruller my-10"></div>
                <div className="my-8">
                    <h1 className="text-16 text-bold text-gray-dark my-4">{trans("ownOrder:payment")}</h1>

                    {
                        amount ?
                            <div className="flex nice-flex-row nice-flex-space-between my-2">
                                <h1 className="text-16">{trans("ownOrder:amount")}</h1>
                                <div className="text-16 text-bold text-black">
                                    <Price price={amount} />
                                </div>
                            </div> : null

                    }

                    {
                        shippingPrice ?
                            <div className="flex nice-flex-row nice-flex-space-between my-2">
                                <h1 className="text-16">{trans("ownOrder:shipping")}</h1>
                                <div className="text-16 text-bold text-black">
                                    <Price price={shippingPrice} />
                                </div>
                            </div> : null
                    }

                    {
                        finalTotal ?
                            <div className="flex nice-flex-row nice-flex-space-between my-2">
                                <h1 className="text-16 text-primary">{trans("ownOrder:total")}</h1>
                                <div className="text-16 text-bold text-primary">
                                    <Price price={finalTotal} />
                                </div>
                            </div>
                            : null
                    }
                    {

                        <div className="flex nice-flex-row nice-flex-space-between my-2">
                            <h1 className="text-16 text-primary">{trans("new:paid_amount")}</h1>
                            <div className="text-16 text-bold text-primary">
                                <Price price={paidAmount == null ? 0 : paidAmount} showZero={true} />
                            </div>
                        </div>
                    }

                    {

                        <div className="flex nice-flex-row nice-flex-space-between my-2">
                            <h1 className="text-16 text-primary">{trans("new:remaining_amount")}</h1>
                            <div className="text-16 text-bold text-primary">
                                <Price price={(finalTotal - (paidAmount == null ? 0 : paidAmount))} showZero={true} />
                            </div>
                        </div>
                    }
                    <div className="amazing-ruller my-10"></div>
                    {
                        ValidUntilNotComplatingPay && ((finalTotal - (paidAmount == null ? 0 : paidAmount)) > 0) &&
                        <div className="flex nice-flex-row nice-flex-space-between my-2">

                            <div class="flex nice-flex-row nice-align-item-center">

                                <div class="info-circle flex nice-flex-center mx-2 text-error">
                                    <img src="/assets/icons/info.svg" alt="" />
                                </div>
                                <h1 className="text-16 text-error">{trans("new:payment_deadline")}</h1>
                            </div>


                            <div className="text-16 text-error">
                                <CountdownTimer ToTime={ValidUntilNotComplatingPay} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};



export const OwnVehicleOrders = ({ vehicles = [] }) => {
    const { t: trans, i18n } = useTranslation("ownOrder");
    const lang = (i18n.language || 'ar');
    if (vehicles?.length > 0) {
        return (
            <div className="own-order-details-box bg-white p-5 mb-4">
                <div className="own-order-details-box-header p-5">
                    <h1 className="text-16 text-gray-dark text-bold">
                        {trans("ownOrder:vehicle")}
                    </h1>
                    {
                        vehicles.map((item, index) => {
                            return <div key={index} className="own-order-vehicle flex gap-3r nice-flex-row my-8">
                                <div className="own-order-vehicle-box flex nice-flex-center">
                                    <img src={item.Partimg ? config.FilePathURL + item.Partimg : '/assets/images/eparts/default.png'} />
                                </div>
                                <div className="flex nice-flex-column nice-flex-evenly">
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:vehicle_type")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-black">
                                            {lang === "ar" ? item.TypeCarAr : item.TypeCarEn}
                                        </div>
                                    </div>
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:vehicle_color")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-black">
                                            {lang === "ar" ? item.colorsAr : item.colorsEn}
                                        </div>
                                    </div>
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:quantity")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-black">
                                            {item.QTY}
                                        </div>
                                    </div>
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:amount")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-primary">
                                            <Price price={item.SalePrice * item.QTY} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        )
    } else {
        return <></>
    }

}

export const OwnSpareOrders = ({ values = [], onEvaluate }) => {
    const { t: trans, i18n } = useTranslation("ownOrder");
    const lang = (i18n.language || 'ar');
    if (values?.length > 0) {
        return (
            <div className="own-order-details-box bg-white p-5 mb-4">
                <div className="own-order-details-box-header p-5">
                    <h1 className="text-16 text-gray-dark text-bold">
                        {trans("ownOrder:spare_parts")}
                    </h1>
                    {
                        values.map((item, index) => {
                            return <div key={index} className="own-order-vehicle flex gap-3r nice-flex-row my-8">
                                <div className="own-order-vehicle-box flex nice-flex-center nice-relative">
                                    <img src={item.Partimg ? config.FilePathURL + item.Partimg : '/assets/images/eparts/default.png'} />
                                    <div onClick={() => {
                                        if (onEvaluate) {
                                            onEvaluate(item.ProductID, true)
                                        }
                                    }} className="own-order-vehicle-box-btn p-2 nice-pointer nice-flex nice-flex-center text-14 nice-absolute">
                                        تقيم المنتج
                                    </div>
                                </div>
                                <div className="flex nice-flex-column nice-flex-evenly">
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:spare_parts")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-black">
                                            {lang === 'ar' ? item.PartAr : item.Parten}
                                        </div>
                                    </div>
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:quantity")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-black">
                                            {item.QTY}
                                        </div>
                                    </div>
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:amount")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-primary">
                                            <Price price={item.PartPrice * item.QTY} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        )
    } else {
        return <></>
    }
}


export const OwnSDCOrders = ({ values = [], onEvaluate }) => {
    const { t: trans, i18n } = useTranslation("ownOrder");
    const lang = (i18n.language || 'ar');
    if (values?.length > 0) {
        return (
            <div className="own-order-details-box bg-white p-5 mb-4">
                <div className="own-order-details-box-header p-5">
                    <h1 className="text-16 text-gray-dark text-bold">
                        {trans("ownOrder:compain_parts")}
                    </h1>
                    {
                        values.map((item, index) => {
                            return <div key={index} className="own-order-vehicle flex gap-3r nice-flex-row my-8">
                                <div className="own-order-vehicle-box flex nice-flex-center">
                                    <img src={item.SDCimg ? "data:image/png;base64," + item.SDCimg : '/assets/images/eparts/default.png'} />
                                </div>
                                <div className="flex nice-flex-column nice-flex-evenly">
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:spare_parts")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-black">
                                            {lang === 'ar' ? item.SDCAr : item.SDCen}
                                        </div>
                                    </div>
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:quantity")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-black">
                                            {item.QTY}
                                        </div>
                                    </div>
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:amount")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-primary">
                                            <Price price={item.SDCPrice * item.QTY} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        )
    } else {
        return <></>
    }

}


export const OwnPGTOrders = ({ values = [], onEvaluate }) => {
    const { t: trans, i18n } = useTranslation("ownOrder");
    const lang = (i18n.language || 'ar');
    if (values?.length > 0) {
        return (
            <div className="own-order-details-box bg-white p-5 mb-4">
                <div className="own-order-details-box-header p-5">
                    <h1 className="text-16 text-gray-dark text-bold">
                        {trans("ownOrder:package_parts")}
                    </h1>
                    {
                        values.map((item, index) => {
                            return <div key={index} className="own-order-vehicle flex gap-3r nice-flex-row my-8">
                                <div className="own-order-vehicle-box flex nice-flex-center">
                                    <img src={item.PGTimg ? "data:image/png;base64," + item.PGTimg : '/assets/images/eparts/default.png'} />
                                </div>
                                <div className="flex nice-flex-column nice-flex-evenly">
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:spare_parts")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-black">
                                            {lang === 'ar' ? item.PGTAr : item.PGTen}
                                        </div>
                                    </div>
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:quantity")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-black">
                                            {item.QTY}
                                        </div>
                                    </div>
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:amount")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-primary">
                                            <Price price={item.PGTPrice * item.QTY} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        )
    } else {
        return <></>
    }

}

export const OwnServiceOrders = ({ values = [] }) => {
    const { t: trans, i18n } = useTranslation("ownOrder");
    const lang = (i18n.language || 'ar');
    if (values?.length > 0) {
        return (
            <div className="own-order-details-box bg-white p-5 mb-4">
                <div className="own-order-details-box-header p-5">
                    <h1 className="text-16 text-gray-dark text-bold">
                        {trans("ownOrder:Service")}
                    </h1>
                    {
                        values.map((item, index) => {
                            return <div key={index} className="own-order-vehicle flex gap-3r nice-flex-row my-8">
                                <div className="flex nice-flex-column nice-flex-evenly">
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:Servic")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-black">
                                            {lang === 'ar' ? item.PartAr : item.Parten}
                                        </div>
                                    </div>
                                    <div className="flex nice-flex-row ">
                                        <h1 className="text-16">
                                            {trans("ownOrder:amount")}
                                        </h1>
                                        <div className="text-16 text-bold mx-4 mx-4 text-primary">
                                            <Price price={item.SalePrice} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        )
    } else {
        return <></>
    }

}
