import { useTranslation } from "react-i18next";
import { Price } from "./__simple/_price";
import { Fragment, useContext } from "react";
import { shareMaker } from "../utils/shareDataMaker";
import ConfirmContext from "../utils/confirmContext";
import { useCompare } from "../utils/compareProvider";
import { useUserCart } from '../User/hooks/useUserCart';
// import {
//     EmailShareButton,
//     EmailIcon,
//     FacebookShareButton,
//     FacebookIcon,
//     TelegramShareButton,
//     TelegramIcon,
//     TwitterShareButton,
//     TwitterIcon,
//     WhatsappShareButton,
//     WhatsappIcon
// } from "react-share";
import { useHistory } from "react-router";
import { useState } from "react";
import { numberFormat } from "../utils/currencyFormatter";
import { LikeButton } from "./__simple/_likeButton";
import { useUserFavorite } from '../User/hooks/useUserFavorite';
import config from "../configuration/config.json";
function Car({
    year,
    slug,
    colorID,
    type,
    id,
    qty,
    img,
    name = "",
    category,
    brand,
    group,
    brandID,
    brandIcon,
    brandImage,
    gifts = 0,
    miniDownPayment,
    colorName = "",
    minPrice,
    link,
    showDetails = true,
    hybird = false,
    newCar = false,
    ProductId,
    Tax = 0,
    RateCareDiscount = 0,
    modelTypes,
    sharable = true,
    hybridImage,
    discountPrice,
    isOnline = false,
    showPric = false,
    buyOnline = false,
    goBuyDerict = false,
    carData = {},
    onClickShowSN,
    btnShowSN = false,
    className = "",
    listcolors = []

}) {
    const { t: trans, i18n } = useTranslation(["common", "info", "vehicales", "compare", "car"]);
    const context = useContext(ConfirmContext);
    let lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    const [shareOpened, setShareOpened] = useState(false);
    const isToyota = (brand === "تويوتا" || brand.toLowerCase() === "toyota");
    const { onInsert } = useCompare(null);
    const history = useHistory();
    const { isFavoriteItem, changeFavoriteItemState } = useUserFavorite();
    const { changecartItem } = useUserCart();


    return (
        <div className={"car-card border-radius " + className + " " + languageDir}>


            <div className="car-card-top">

                <div className={"car-card-type " + (isToyota || "lexus")}>

                    <div className={"car-card-type-brand " + (isToyota || "bg-lexus")}>
                        {/* <img className={"filter-white pt-1"}
                            data-src={config.FilePathURL +brandIcon} alt={(isToyota ? "toyota" : "lexus")} /> */}
                        <img className=""
                            src={`/assets/icons/brands/${(isToyota ? "toyota" : "lexus")}.webp`} alt={(isToyota ? "toyota" : "lexus")} />
                        {/* <h4 className={"text-white text-12 pb-1"}>{brand}</h4> */}
                    </div>
                    {
                        hybridImage && (<div className="car-card-type-hybird">
                            <img className={" pt-1"}
                                dsrc={config.FilePathURL + hybridImage} alt={("hybridImage")}
                            />
                            {/* <h4 className={"text-black text-12 pb-1"}>{trans("vehicales:veh-hybrid")}</h4> */}
                        </div>)
                    }
                    {
                        (gifts != null && gifts > 0) &&
                        (<div className="car-card-type-gift nice-flex nice-flex-column nice-flex-center">
                            <img className="" src={'/assets/icons/gift.svg'} alt={("gift")}
                            />

                            <div className="text-12 gifts-count text-bold text-black">{gifts}</div>
                        </div>)
                    }

                    {(buyOnline == true) && <div className={"car-card-type-online car-card-top-btn-tags flex flex-row"}>
                        <span className={"bg-primary tag text-white "}> {trans("info:Available_for_purchase_online")}
                        </span>
                    </div>}

                </div>


                <a className="nice-pointer" onClick={() => {
                    // history.push(link);
                    window.location.href = link;
                }}>

                    <div className="car-img">
                        <img data-src={config.FilePathURL + img}
                            width={65} height={44}
                            alt={name}
                            className={'lazyload object-contain object-full p-6'}
                        />
                        <div className="car-img-bg">
                            <img className={" object-full"}
                                src={`/assets/icons/brands/${(isToyota ? "toyota-light" : "lexus")}.webp`}
                                alt={(isToyota ? "toyota" : "lexus")} />
                            {/* data-src={config.FilePathURL + brandImage} /> */}
                        </div>


                        {
                            (discountPrice != null && discountPrice > 0) &&
                            (<div className="car-img-discount nice-flex nice-flex-column nice-flex-center">
                                <span className="text-white text-14 text-bold">{trans("info:price_discount")}</span>
                                <span className="text-white text-14 nice-line-through"><Price price={discountPrice} /></span>
                            </div>)
                        }
                    </div>

                </a>


                {/* {sharable && <div className="car-img-share" onMouseEnter={(event) => { event.stopPropagation() }} style={{ right: (shareOpened ? 0 : '-50rem'), flexDirection: (lang === 'en' ? 'row-reverse' : 'row') }}>
                    <div className="car-img-share-icons">
                        <FacebookShareButton
                            url={url}
                            quote={title}
                            hashtag={hashTag}
                        >
                            <FacebookIcon size={36} />
                        </FacebookShareButton>
                        <TwitterShareButton
                            url={url}
                            quote={title}
                            hashtag={hashTag}
                        >
                            <TwitterIcon size={36} />
                        </TwitterShareButton>
                        <WhatsappShareButton
                            url={url}
                            quote={title}
                            hashtag={hashTag}
                        >
                            <WhatsappIcon size={36} />
                        </WhatsappShareButton>
                        <TelegramShareButton
                            url={url}
                            quote={title}
                            hashtag={hashTag}
                        >
                            <TelegramIcon size={36} />
                        </TelegramShareButton>
                        <EmailShareButton
                            url={url}
                            quote={title}
                            hashtag={hashTag}
                        >
                            <EmailIcon size={36} />
                        </EmailShareButton>
                    </div>
                </div>} */}


                <div className="car-card-top-btn w-100 flex-row justify-between">
                    {

                        (btnShowSN == true) ?
                            <div className="car-options-links-showImg mt-1 mx-5"
                                title={"show car images"}

                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (onClickShowSN)
                                        onClickShowSN(carData?.sn);
                                }}
                            >
                                {/* <div className="car-options-links-showImg-cover"></div> */}
                                <div className={"car-options-links-showImg-btn " + (lang == "en" ? " " : " ")}>
                                    <img className=" z-3" src="/assets/icons/camera-svgrepo-com.svg"
                                        alt={trans("Preview")} />
                                </div>

                                <span className="text-14 text-bold ">{trans("info:Preview")}</span>

                            </div>
                            :
                            <div></div>
                    }


                    {/* {<div className={" car-card-top-color flex flex-col   p-2"}>

                        {(colorName && colorName?.split("/")[0]) && <div className={"flex flex-row nice-shadow-0  text-line-clamp-1    white-space-pre"}>
                            <span className=" nice-shadow-0 text-12 hide-xs  ">{trans("car:exterior_color")}</span>
                            <span className="tag text-12 mx-2  text-line-clamp-1 white-space-pre">{colorName?.split("/")[0]}</span>
                        </div>}
                        {(colorName && colorName?.split("/")[1]) && <div className={"flex flex-row nice-shadow-0  mt-2 text-line-clamp-1    white-space-pre"}>
                            <span className=" nice-shadow-0 text-12  hide-xs ">{trans("car:interior_color")}</span>
                            <span className="tag  text-12 mx-2    text-line-clamp-1  white-space-pre">{colorName?.split("/")[1]}</span>
                        </div>}
                    </div>} */}
                    <div className={"flex flex-row nice-flex-space-center gap-2"}>
                        <div className="car-img-share-toggle">
                            <div
                                title="Share"
                                className="share-btn"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setShareOpened(!shareOpened);
                                }}>
                                <img className="lazyload" width={23} height={22} data-src="/assets/icons/share-icon.png"
                                    alt={"share icon"} />
                            </div>
                        </div>
                        <LikeButton
                            favorite={Boolean(isFavoriteItem(ProductId))}
                            onToggle={() => { changeFavoriteItemState(ProductId) }}
                        />
                    </div>

                </div>


            </div>
            <div className="car-card-info border-radius box-shadow">

                {colorName && <div className={" car-card-top-color flex flex-row gap-1 mb-2  p-2"}>

                    {/* <marquee direction="right" scrollamount="10" scrolldelay="200"> This text will bounce </marquee> */}
                    {(colorName && colorName?.split("/")[0]) && <div className={"flex flex-row nice-flex-space-center nice-shadow-0  text-line-clamp-1    white-space-pre"}>
                        <span className="border-v nice-shadow-0 text-10  text-bold  ">{trans("car:exterior_color")}</span>
                        <span className="tag text-10 mx-2  text-line-clamp-1  text-bold white-space-pre">{colorName?.split("/")[0]}</span>
                    </div>}
                    {(colorName && colorName?.split("/")[1]) && <div className={"flex flex-row nice-flex-space-center nice-shadow-0  mt-2 text-line-clamp-1    white-space-pre"}>
                        <span className="border-v nice-shadow-0 text-10 text-bold  ">{trans("car:interior_color")}</span>
                        <span className="tag  text-10 mx-2  text-bold  text-line-clamp-1  white-space-pre">{colorName?.split("/")[1]}</span>
                    </div>}
                </div>}

                <div className="car-card-info-name language-en">

                    <h3 className={"text-14 font-bold text-line-clamp text-line-clamp-2"} title={name}>{name}</h3>

                    <div className={"car-tags flex flex-row"}>
                        {
                            showDetails && <Fragment>
                                {newCar && <span className={"bg-toyota tag text-white "}>{trans("info:new")}</span>}

                                {hybird && <span className={"bg-info tag text-white "}>{trans("vehicales:veh-hybrid")}</span>}

                            </Fragment>


                        }

                        <span className={"tag bg-white "}>{category}</span>

                    </div>

                </div>

                {showDetails && <div className="car-options" >

                    <div className="flex flex-col nice-align-item-start ">
                        <div className="options-price flex-row nice-flex-center">
                            {
                                (showPric || goBuyDerict) &&
                                <>
                                    <span className="border-v text-20 mx-3">{(showPric || goBuyDerict) && trans("info:product_price")}</span>

                                    <h4 className={"text-20 font-bold inline-block text-primary"}>{(showPric || goBuyDerict) && <Price price={minPrice} />}</h4>
                                </>


                            }


                        </div>




                    </div>
                    <div className="car-options-links">

                        {/* <Link to={link} className={"text-14"}>{trans("show")} </Link> */}
                        {/* <a onClick={() => {
                            onInsert(ProductId);
                        }} className={"text-14 text-bold"}>{trans("compare:compare_btn")} </a> */}
                        {/* 
                        {

                            (btnShowSN == true) &&
                            <div className="car-options-links-showImg"
                                title={"show car images"}

                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (onClickShowSN)
                                        onClickShowSN(carData?.sn);
                                }}
                            >
                                <div className="car-options-links-showImg-cover"></div>
                                <div className="car-options-links-showImg-btn">
                                    <img data-src="/assets/icons/arrow-alt-circle-left-regular.svg" onError={e => e.target.style.display = 'none'} />
                                </div>

                                <span className="text-14 text-primary ">{trans("info:Preview")}</span>

                            </div>
                        } */}
                        <a
                            onClick={() => {
                                try {

                                    if (goBuyDerict == true) {
                                        var obb =
                                        {
                                            id: slug,
                                            Type: 'car',
                                            colorId: colorID
                                            , name: carData?.descriptionAr
                                            , nameEn: carData?.descriptionEn
                                            , price: minPrice
                                            , brand: carData?.brandAr
                                            , brandEn: carData?.brandEn
                                            , Tax: Tax
                                            , image: img
                                            , Discount: RateCareDiscount
                                            , productID: ProductId
                                            , group: carData?.groupAr
                                            , groupEn: carData?.groupEn
                                            , BrandID: brandID
                                            , modelTypes: carData?.descriptionAr
                                            , modelTypesEn: carData?.descriptionEn
                                            , colorName: carData?.colorNameAr
                                            , colorNameEn: carData?.colorNameEn
                                            , SN: carData?.sn
                                            , year: year
                                            , modelTypeID: type
                                            , MiniDownPayment: miniDownPayment
                                        }

                                        changecartItem(ProductId, colorID, type, 1, null, null, obb, null, year);

                                    } else {

                                        context.onShow(
                                            {
                                                modelYear: year,
                                                displayConfirm: true,
                                                title: name,
                                                image: img,
                                                backGround: brandImage,
                                                brand: brand,
                                                brandID: brandID,
                                                isHyper: hybird,
                                                isNew: newCar,
                                                defaultColorID: colorID,
                                                cartDetails: {
                                                    id: ProductId,
                                                    name: name,
                                                    price: minPrice,
                                                    brand: brand,
                                                    image: img,
                                                    Tax: Tax,
                                                    Discount: RateCareDiscount,
                                                    Type: "car",
                                                    modelTypes: modelTypes
                                                },
                                                carOrder: {
                                                    vehicle: ProductId,
                                                    group: group
                                                }
                                            });
                                    }



                                } catch (error) {
                                    console.log(error);
                                }

                            }}
                            // href="./" 
                            className={"text-30"}>
                            <img width={24} height={24} className={" inline filter-primary size-30 pr-1"}
                                src="/assets/icons/cart.svg"
                                alt={trans("buy")} />
                        </a>

                    </div>
                </div>
                }
                {

                    listcolors?.length > 0 &&
                    < div className="car-listcolors flex w-100  py-5">
                        {
                            listcolors?.map((colorimg, index) => {

                                return (
                                    <div class="car-listcolors-vailable">
                                        <img src={config.FilePathURL + colorimg} alt="" />
                                    </div>

                                )
                            })

                        }

                    </div>
                }



            </div>
        </div >

    );
}

export default Car;