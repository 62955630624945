import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Price } from "../../components/__simple/_price"
import config from "../../configuration/config.json";
function CarUser({
    id,
    img,
    name = "" ,
    brand,
    brandIcon,
    brandImage,
    link,
    inputDate,
    onUpdate,
    minPrice,
    fromToYear,
    showDetails = true,
    withMoreLinke = true,
    active = false,
    onclick
}) {
    const { t: trans } = useTranslation(["common", "info"]);

    const isToyota = (brand?.toLowerCase() === "تويوتا" || brand?.toLowerCase() === "toyota");
    const handleRemoveUserCar = () => {
        if (window.confirm('هل ترغب بحذف المركبة الحالة؟')) {
            try {
                axios.put(
                    config.baseURL + 'update',
                    {
                        "object": "siteUserCars",
                        "values": {
                            "IsDelete": true
                        },
                        "filters": `where GUID = '${id}'`
                    }
                ).then(response => {
                    window.location.reload();
                }).catch(error => {
                    console.log(error);
                })
            } catch (err) {
                console.log('error in deleting the vehicle', err);
            }
        }
    };
    return (
        <div className={`car-card border-radius  ${(active ? " car-card-active " : " ")}`}>
            <div className="car-card-top">
                <a href={link}

                    onClick={(e) => {

                        if (!withMoreLinke) {
                            e.preventDefault()
                            if (onclick)
                                onclick();
                        }

                    }}

                >
                    <div className={"car-card-type " + (isToyota || "lexus")}>
                        <div className={"car-card-type-brand " + (isToyota || "bg-lexus")}>
                            {/* <img className={"filter-white pt-1"}
                                src={brandIcon} alt="toyota" /> */}

                            <img className={" pt-1"}
                                src={`/assets/icons/brands/${(isToyota ? "toyota" : "lexus")}.webp`} />
                            {/* <h4 className={"text-white text-12 pb-1"}>{brand}</h4> */}
                        </div>

                        <div className="car-card-type-hybird ">
                            <h4 className={" text-16  mt-2 text-bold pb-1"}>{fromToYear ? fromToYear : ""}</h4>
                        </div>
                    </div>
                    <div className="car-img">

                        <img src={config.FilePathURL + img} alt={""} className={'object-contain object-full p-6'} onError={e => e.target.style.display = 'none'} />

                        <div className="car-img-bg">
                            <img className={"object-full"} src={`/assets/icons/brands/${(isToyota ? "toyota-light" : "lexus")}.webp`}
                                alt={""} />
                        </div>
                    </div>
                </a>
            </div>
            <div className="car-card-info border-radius box-shadow">
                <div className="car-card-info-name">
                    <h3 className={"text-16 font-bold    text-line-clamp text-line-clamp-2"}
                        style={{ maxWidth: "90%" }}>{name}</h3>
                </div>


                <div className="flex flex-col nice-align-item-start ">
                    <div className="options-price flex-row nice-flex-center">
                        {
                            minPrice &&
                            <>
                                <span className="border-v text-14 mx-3">{(minPrice) && trans("info:product_price")}</span>

                                <h4 className={"text-14 font-bold inline-block text-primary"}>{(minPrice) && <Price price={minPrice} />}</h4>
                            </>


                        }


                    </div>

                </div>
                {true && <div className="car-options">
                    {
                        inputDate &&
                        <div className="options-price">
                            <span className="text-16 ml-3 mx-2">{trans("info:input_date")}</span>

                            <h4 className={"text-16 font-bold inline-block text-primary"}>{inputDate}</h4>
                        </div>

                    }

                    {showDetails && <div className="options-links">
                        {
                            id &&
                            <a
                                onClick={() => {
                                    handleRemoveUserCar(id);
                                }}
                                className={"text-14 icon-btn"}>
                                <img title="حذف" className={"inline filter-primary size-21"} src="/assets/icons/trash.svg" />
                            </a>
                        }

                        {
                            id &&
                            <a
                                onClick={() => {
                                    if (onUpdate) {
                                        onUpdate(id);
                                    }
                                }}
                                className={"text-14 icon-btn"}>
                                <img title="تعديل" className={"inline filter-primary size-21"} src="/assets/icons/edit.svg" />
                            </a>
                        }

                    </div>
                    }
                </div>
                }
            </div>
        </div>

    );
}

export default CarUser;