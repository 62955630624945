import { Fragment, useEffect, useRef, useState } from "react";
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import { useTranslation } from "react-i18next";
import { FilterButton } from "../../components/__simple/_filterButton";
import { FinanceSearch } from "./components/financeSearch";
import { FinanceCar } from "./components/financeCar";
import PageHeading from "../../components/__simple/_pageheading";
import SectionHeading from "../../components/sectionHeading";
import Banner from "../../components/banner";
import { OfferSections } from "../../sections/offersSection";
import Breadcrumb from "../../components/__simple/breadcrumb";
import config from '../../configuration/config.json';
import axios from 'axios';
import Popup from "../../User/popup";
import { Price } from "../../components/__simple/_price";
import { InputField } from "../../components/_input/_input";
import { TextArea } from "../../components/_input/_textArea";
import PhoneDropDown from "../../components/_input/_phoneDropDown";
import { DropDownInput } from "../../components/_input/_dropdown";
import Joi from "joi";
import { formValidate, GetMessages } from "../../utils/validation";
import { includes } from "lodash";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Skeleton from 'react-loading-skeleton'
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function FinancePage() {
    const { t: trans, i18n } = useTranslation(["heading", "finance", "model", "navbar", "form", "error2", "common", "info", "error"]);
    let lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    const popRef = useRef();
    const [products, setProducts] = useState([]);
    const [filteredCars, setFilteredCars] = useState(products);
    const [filteredCarsByBrand, setFilteredCarsByBrand] = useState(products);
    const [carBrandFilter, setCarFilter] = useState(null);
    const [activeMenu, setActiveMenu] = useState(1);
    const [banner, setbanner] = useState({});
    const [financeOffers, setFinanceOffers] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [carInfo, setCarInfo] = useState({});
    const [isLoadingVehicles, setLoadingVehicles] = useState(false);
    const [banks, setBanks] = useState([]);
    const [accountTypes, setAccountTypes] = useState([]);
    const [financeSettings, setFinanceSettings] = useState(null);
    const [profitRates, setProfitRates] = useState([]);
    const [isLoadingInfo, setLoadingInfo] = useState(true);
    const [isLoadingSendOrder, setLoadingSendOrder] = useState(false);
    const [selectionBank, setSelectionBank] = useState({});
    const [seoPageInfo, setSeoPageInfo] = useState({});
    const [approximateResult, setApproximateResult] = useState({
        period: '',
        mangFees: null,
        monthPay: null,
        firstPay: null,
        lastPay: null,
        annualRate: '',
        total: null
    });

    const [requestForm, setRequestForm] = useState({
        custType: null,
        identityNo: '',
        cn: '',
        fullNameAr: '',
        fullNameEn: '',
        phoneNumber: '',
        email: '',
        bankID: '',
        income: '',
        period: '60',
        groupColor: '',
        colorID: '',
        advancedPayment: '',
        monthlyPayment: '',
        note: ''
    });
    const [defaultBank, setDefaultBank] = useState(null);
    const [defaultperiod, setDefaultperiod] = useState(24);
    const [error, setError] = useState({});
    const [identityType, setIdentityType] = useState(null);
    const [colors, setColors] = useState([]);
    const schema = Joi.object({
        custType: Joi.string().required().messages(GetMessages()),
        identityNo: Joi.string().min(10).required().messages(GetMessages()),
        cn: Joi.string().min(10).required().messages(GetMessages()),
        fullNameAr: Joi.string().required().messages(GetMessages()),
        email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).required().messages(GetMessages()),
        phoneNumber: Joi.string().min(13).required().messages(GetMessages()),
        income: Joi.string().required().messages(GetMessages()),
        period: Joi.number().min(24).max(60).required().messages(GetMessages()),
    });



    useEffect(() => {
        initialBanner();
        initialFinanceoffers();
        // initialProducts();
        // initialBanksData();
        initialCustGroups();
        initalSettings();
        intialProfitRates();
    }, []);

    // useEffect(() => {
    //     setFilteredCars(products);
    // }, [products]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (popRef.current && !popRef.current.contains(event.target) && showPopup === true) {
                resetControls();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showPopup]);

    let handleRefechData;
    const fn = function (sayHelloFromComp1) {
        handleRefechData = sayHelloFromComp1;
    };

    useEffect(() => {

        if (products && products?.length > 0) {

            handleRefechData();
        }

        // if (activeMenu == 1)
        //     handleFilterByActiveMenu({
        //         maxFinanceAmount: 5000,
        //         maxFinancePeriod: 24,
        //         lastBatch: 40000
        //     }, activeMenu, carBrandFilter);

    }, [products]);
    // filter cars
    function filterCars(carsData, brand) {

        //    //console.log("getCarsByBrand:", carsData);
        //     //console.log("getCarsByBrand:", brand);

        setCarFilter(brand);
        setFilteredCars(carsData);
        let filterData;
        if (brand != null) {
            switch (brand.toLowerCase()) {

                case trans("finance:brand_toyota").toLowerCase():
                    filterData = getCarsByBrand(carsData, trans("finance:brand_toyota").toLowerCase());
                    break;

                case trans("finance:brand_lexus").toLowerCase():
                    filterData = getCarsByBrand(carsData, trans("finance:brand_lexus").toLowerCase());
                    break;
            }
        } else {
            filterData = carsData;
        }
        setFilteredCarsByBrand(filterData);
    };

    const initialProductsByBank = async (thisBankID) => {
        setLoadingVehicles(true);
        try {
            setProducts([]);
            //console.log(" initialProductsByBank:", thisBankID);
            var res = null;
            var dataVehicles = null;
            // try {
            //     if ("caches" in window) {
            //         var curl = "VehiclesByPriceListTypeOnlin-" + lang + "_" + thisBankID;

            //         // Opening that particular cache
            //         const cacheStorage = await caches.open("FinancePage");

            //         // Fetching that particular cache data
            //         const cachedResponse = await cacheStorage.match(
            //             curl
            //         );
            //         dataVehicles = await cachedResponse?.json();

            //         if (dataVehicles) {

            //             setProducts(dataVehicles);
            //             setLoadingVehicles(false);
            //         }

            //     }

            // } catch (error) {
            //     ////console.log(error);
            // }
            axios.post(
                config.baseURL + "func",
                {//site_FetchVehiclesByPriceListTypeIDOnlineAr
                    "name": lang == "ar" ? "site_FetchVehiclesByPriceListTypeIDOnlineAr" : "site_FetchVehiclesByPriceListTypeIDOnlineEn",
                    // "name": "SiteFetchSupportedCarsInFinance",
                    "Values": {
                        "PriceListTypeID": thisBankID
                        // "PriceListTypeID": "Al Rajhi Rental"
                    }
                }
            ).then(response => {
                setLoadingVehicles(false);
                const result = response.data;
                const customizedResult = result.map(e => {
                    return {
                        id: e.OfferID,
                        brandID: e.BrandID,
                        brandTitle: e.Brand,
                        brandImage: e.BrandImage ? "data:image/png;base64," + e.BrandImage : null,
                        productID: e.ProductID,
                        productImage: e.Image ? config.mainURL + e.Image : null,
                        groupID: e.CarGroupID,
                        groupTitle: e.Group,
                        model: e.Year,
                        modelTypeID: e.Type,
                        modelTypeTitle: e.DescriptionEn,
                        colorID: e.ColorID,
                        colorGroupID: e.ColorGroupID,
                        colorName: e.ColorName,
                        colorImage: e.ColorImage ? "data:image/png;base64," + e.ColorImage : null,
                        price: e.MinPrice,
                        showPric: true,
                        buyOnline: e.BuyOnline,
                        sn:e.SN
                    }


                });

                if (dataVehicles != customizedResult) {
                    setProducts(customizedResult);


                    // const resdataVehicles = new Response(JSON.stringify(customizedResult));

                    // if ("caches" in window) {
                    //     // Opening given cache and putting our data into it
                    //     caches.open("FinancePage").then((cache) => {
                    //         cache.put("VehiclesByPriceListTypeOnlin-" + lang + "_" + thisBankID, resdataVehicles);

                    //     });
                    // }
                }
            }).catch(error => {
                //////console.log(error);
                setLoadingVehicles(false);
            });

        } catch (error) {
            ////console.log(error);
            setLoadingVehicles(false);
        }
    };


    // It's used to initialize the products
    const initialProducts = async () => {

        // try {

        //     var res = null;
        //     if ("caches" in window) {
        //         var curl = "OnlineVehicles-" + lang;

        //         // Opening that particular cache
        //         const cacheStorage = await caches.open("FinancePage");

        //         // Fetching that particular cache data
        //         const cachedResponse = await cacheStorage.match(
        //             curl
        //         );
        //         var dataVehicles = await cachedResponse?.json();

        //         setProducts(dataVehicles);

        //     }

        //     const response = await axios.request({
        //         baseURL: config.controllURL,
        //         url: lang + "/api/vehicles/OnlineVehicles",
        //         method: "get",
        //     });

        //     const modelTrns = trans("model:description");
        //     const result = response.data.vehicles;
        //     const customizedResult = result.map(e => {
        //         return {
        //             id: e.OfferID,
        //             brandID: e.brandID,
        //             brandTitle: e.brand,
        //             brandIcon: e.BrandIcon ? "data:image/png;base64," + e.BrandIcon : null,
        //             brandImage: e.BrandImage ? "data:image/png;base64," + e.BrandImage : null,
        //             productID: e.ProductID,
        //             productImage: e.image ? config.mainURL + e.image : null,
        //             groupID: e.carGroupID,
        //             groupTitle: e.group,
        //             model: e.year,
        //             modelTypeID: e.type,
        //             modelTypeTitle: e.modelDescription,
        //             colorID: e.colorID,
        //             colorGroupID: e.ColorGroupID,
        //             colorName: e.colorName,
        //             colorImage: e.ColorImage ? "data:image/png;base64," + e.ColorImage : null,
        //             price: e.minPrice,
        //             showPric: true,
        //             buyOnline: e.buyOnline
        //         }


        //     });
        //     setProducts(customizedResult);
        //     const resdataVehicles = new Response(JSON.stringify(customizedResult));

        //     if ("caches" in window) {
        //         // Opening given cache and putting our data into it
        //         caches.open("FinancePage").then((cache) => {
        //             cache.put("OnlineVehicles-" + lang, resdataVehicles);

        //         });
        //     }


        // } catch (error) {
        //     ////console.log(error);
        // }

        // const modelTrns = trans("model:description");
        axios.post(
            config.baseURL + "func",
            {
                "name": lang == "ar" ? "site_FetchVehiclesByPriceListTypeOnlineAr" : "site_FetchVehiclesByPriceListTypeOnlineEn",
                // "name": "SiteFetchSupportedCarsInFinance",
                "Values": {
                    "PriceListTypeID": 'Al Rajhi Bank'
                }
            }
        ).then(response => {
            const result = response.data;
            const customizedResult = result.map(e => {
                return {
                    id: e.OfferID,
                    brandID: e.BrandID,
                    brandTitle: e.Brand,
                    brandImage: e.BrandImage ? "data:image/png;base64," + e.BrandImage : null,
                    productID: e.ProductID,
                    productImage: e.Image ? config.mainURL + e.Image : null,
                    groupID: e.CarGroupID,
                    groupTitle: e.Group,
                    model: e.Year,
                    modelTypeID: e.Type,
                    modelTypeTitle: e.DescriptionEn,
                    colorID: e.ColorID,
                    colorGroupID: e.ColorGroupID,
                    colorName: e.ColorName,
                    colorImage: e.ColorImage ? "data:image/png;base64," + e.ColorImage : null,
                    price: e.MinPrice,
                    showPric: true,
                    buyOnline: e.BuyOnline
                }


            });
            setProducts(customizedResult);
        }).catch(error => {
            //////console.log(error);
        });
    };

    // It's used to filter the cars by brand
    const getCarsByBrand = (carsData, brand) => {
        //////console.log("getCarsByBrand:", carsData);
        //////console.log("getCarsByBrand:", brand);

        try {
            return carsData?.filter(e => {
                return (e.brandTitle.toLowerCase() == brand)
            })
        } catch (error) {
            return [];

        }

    };


    const handleFilterByBank = (activeBank) => {
        try {
            //console.log("handleFilterByBank:", activeBank);
            const val = { ...requestForm };
            val.bankID = activeBank.GUID;
            setRequestForm(val);
            setSelectionBank(activeBank);
            setDefaultBank(activeBank?.GUID);

            initialProductsByBank(activeBank?.PriceListTypeID);
        } catch (err) {
            //////console.log(err);
            //console.log("err handleFilterByBank:", activeBank);
        }
    };

    const initialBanner = async () => {
        let result = [];

        const response = await axios.request({
            baseURL: config.controllURL,
            url: lang + "/api/Finance/Banner",
            method: "get",
        });

        //////console.log('response.data.banner:', response.data.banner);
        result = response.data.banner;
        setTimeout(setbanner(result), 500);
        return result;
    };

    // to fetch the finance offers
    const initialFinanceoffers = () => {
        axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchOffersFinanceWithSettingBank"
            }
        ).then(response => {
            const descriptionModel = trans("model:description");
            const responseData = response.data.map((item, index) => {
                return {
                    key: index,
                    startDate: item?.StartDate,
                    endDate: item?.EndDate,
                    title: item[`Title${descriptionModel}`],
                    excerpt: item[`${lang == "ar" ? 'excerptAr' : 'excerptEn'}`],
                    slug: item?.slugAr,
                    otherLink: item?.slugEn,
                    totalDays: item?.TotalDays,
                    image: item.Image
                }
            });
            setFinanceOffers(responseData);
        }).catch(error => {
            //////console.log('Error in fetching the finance offers', error);
        })
    };



    //It's used to fetch all the customer types
    const initialCustGroups = () => {
        axios.post(
            config.baseURL + 'func',
            {
                "name": "SiteGetCustGroupsCollection"
            }
        ).then(response => {
            setAccountTypes(response.data);

        }).catch(err => {
            //////console.log(err);
        })
    };

    // to handle the specific car order
    const handleOnApplay = (car, selectionBank) => {
        setShowPopup(true);
        setCarInfo({ ...car });
        reCountFinanceSettings(car, selectionBank, requestForm?.period);
        axios.post(
            config.baseURL + 'func',
            {
                "name": "SiteFetchColorsByGroup",
                "values": {
                    "CroupColorID": car.groupColor,
                    "Type": car.modelType
                }
            }
        ).then(response => {
            setColors(response.data.map(item => {
                return { value: item.ColorID, text: item[`${trans("model:description")}`] }
            }));
        }).catch(err => {

        });

    };

    // to intialize the finance settings
    const initalSettings = () => {
        axios.post(
            config.baseURL + 'func',
            {
                "name": "SiteFetchActiveBankWithFinanceSettings"
            }
        ).then(response => {
            setFinanceSettings(response.data[0]);
        }).catch(err => {
            //////console.log(err);
        })
    };
    // to intialize the finance profit Rates
    const intialProfitRates = () => {
        axios.post(
            config.baseURL + 'func',
            {
                "name": "SiteFetchProfitRates"
            }
        ).then(response => {
            setProfitRates(response.data);
        }).catch(err => {
            //////console.log(err);
        })
    };

    // to reset controls 
    const resetControls = () => {
        setShowPopup(false);
        setCarInfo({});
        setIdentityType(null);
        setError({});
        setApproximateResult(
            {
                period: '',
                mangFees: null,
                monthPay: null,
                firstPay: null,
                lastPay: null,
                annualRate: '',
                total: ''
            }
        );
        setRequestForm({
            ...requestForm,
            custType: null,
            identityNo: '',
            cn: '',
            fullNameAr: '',
            fullNameEn: '',
            phoneNumber: '',
            email: '',
            income: null,
            colorID: '',
            advancedPayment: null,
            lastPayment: null,
            note: ''

        });
    };

    const countFinancCar = async (priceCar, instance) => {

        const { maxFinancePeriod, maxFinanceAmount, lastBatch } = instance;
        var finalAmount = 0;
        // try {
        const firstPay = (selectionBank?.AdvancedPaymentRate / 100) ?? 0;
        const lastPay = (selectionBank?.AdvancedPaymentRate / 100) ?? 0;
        const managementFees = financeSettings?.ManagementFees ? (financeSettings?.ManagementFees / 100) : 0; // الرسوم الادارية
        const financeRate = (selectionBank?.FinanceRate / 100) ?? 0; //نسبة الفائدة



        const firstPayPrice = (firstPay * parseFloat(priceCar))
        const lastPayPrice = (lastPay * parseFloat(priceCar));

        const financeAmount = (parseFloat(priceCar) - parseFloat(firstPayPrice));

        var addAmount = (financeAmount * financeRate) * (maxFinancePeriod / 12);


        if (addAmount && managementFees && managementFees > 0) {
            // الرسوم الادارية
            var managementFeesAmount = (financeAmount * managementFees);
            managementFeesAmount = managementFeesAmount + (managementFeesAmount * 0.15);
            addAmount = addAmount + managementFeesAmount;

        }


        const mounthBrach = ((financeAmount + addAmount) - lastPayPrice) / (maxFinancePeriod - 1);

        finalAmount = ((mounthBrach * (maxFinancePeriod - 1)) + lastPayPrice + firstPayPrice);

        // } catch (error) {

        // }



        ////console.log("finalAmount>>>>>>>>>>>>>>>>>>>:", finalAmount);
        return parseFloat(finalAmount);
    }

    const handleFilterByActiveMenu = async (obj, thisActiveMenu, thiscarBrandFilter = carBrandFilter) => {
        //console.log("handleFilterByActiveMenu:", thisActiveMenu);
        //console.log("handleFilterByActiveMenu:", obj);
        //console.log("handleFilterByActiveMenu:", products);
        if (products && products?.length > 0) {
            const instance = { ...obj };
            setLoadingInfo(true);
            setTimeout(() => {

                ////console.log("handleFilterByActiveMenu:", instance);
                let filteredData;
                //////console.log("activeMenu:", thisActiveMenu);
                //////console.log("thiscarBrandFilter:", thiscarBrandFilter);
                //////console.log("products:", products);

                switch (thisActiveMenu) {
                    case 1:

                        const { maxFinancePeriod, maxFinanceAmount, lastBatch } = instance;

                        // setDefaultperiod(maxFinancePeriod);
                        //////console.log("instance:", instance);
                        const total = (((parseFloat(maxFinanceAmount) * maxFinancePeriod) - parseFloat(lastBatch)) / (maxFinancePeriod - 1));
                        //////console.log("total:", total);
                        const finalTotal = (parseFloat(total) * (maxFinancePeriod - 1)) + parseFloat(lastBatch);
                        //////console.log("finalTotal>>>>>>>>>>>>>>>>>>>:", finalTotal);

                        // const DatawithFinanc = products?.filter(async car => {
                        //     return (car.finalFinaniceTotal = ((parseFloat(await countFinancCar(car.price, instance)))))
                        // });

                        const DatawithFinanc = products?.filter(async car => {
                            return (car.finalFinaniceTotal = car.price)
                        });
                        ////console.log("DatawithFinanc>>>>>>>>>>>>>>>>>>>:", DatawithFinanc);
                        setTimeout(() => {
                            filteredData = DatawithFinanc?.filter(car => {
                                return (finalTotal >= car.finalFinaniceTotal)
                            });
                            ////console.log("filteredData>>>>>>>>>>>>>>>>>>>:", filteredData);
                            filterCars(filteredData, thiscarBrandFilter);
                            setLoadingInfo(false);
                        }, 100);
                        break;
                    case 2:
                        const { brandID, groupID, subCategoryID } = instance;
                        filteredData = products?.filter(car => {
                            return (((brandID && brandID.trim().length > 0) ? car.brandID == brandID : 1 == 1) && ((groupID && groupID.trim().length > 0) ? car.groupID == groupID : 1 == 1) && ((subCategoryID && subCategoryID.trim().length > 0) ? car.modelTypeID == subCategoryID : 1 == 1))
                        });

                        filterCars(filteredData, thiscarBrandFilter);
                        setLoadingInfo(false);
                        break;
                    case 3:
                        const { budget } = instance;
                        //////console.log("budget:", budget);
                        if (budget) {
                            filteredData = products?.filter(car => {
                                return (budget >= car.price)
                            });
                        }

                        filterCars(filteredData, thiscarBrandFilter);
                        setLoadingInfo(false);
                        break;
                }

                //////console.log("filteredData:", filteredData);


            }, 10);
        }
    };
    // to sumbit finance car order
    const handleSubmit = () => {
        try {

            if (!isLoadingSendOrder) {
                //////console.log("handleSubmit:", requestForm);
                setLoadingSendOrder(true);
                const result = formValidate(schema, requestForm) ?? {};
                let unspesificErrors = null;
                let newErrors = {};
                if (identityType == 1) {
                    // identity type is (identity card)
                    unspesificErrors = ['cn'];

                } else if (identityType == 2) {

                    unspesificErrors = ['identityNo'];

                }
                newErrors = unspesificErrors ? removeUnspecificError(result, unspesificErrors) : result;

                ////console.log("newErrors", newErrors);
                setError(newErrors);
                if (Object.keys(newErrors).length === 0) {
                    const { LastPaymentRate } = { ...financeSettings };
                    if (requestForm.income > 0) {
                        axios.post(
                            config.baseURL + 'add',
                            {
                                "object": "SiteFinanceOrders",
                                "values": {
                                    "OfferID": carInfo.offerID,
                                    "ProductID": carInfo.productID,
                                    "ModelYear": carInfo.model,
                                    "CustType": requestForm.custType,
                                    "IdentityNo": requestForm.identityNo,
                                    "CN": requestForm.cn,
                                    "Email": requestForm.email,
                                    "FullNameAr": requestForm.fullNameAr,
                                    "FullNameEn": requestForm.fullNameEn,
                                    "ColorGroup": carInfo.groupColor != '' || carInfo.groupColor != undefined || carInfo.groupColor != null ? carInfo.groupColor : null,
                                    "ColorID": requestForm.colorID != '' || requestForm.colorID != null || requestForm.colorID != undefined ? requestForm.colorID : null,
                                    "BankID": requestForm.bankID != '' ? requestForm.bankID : null,
                                    "PhoneNumber": requestForm.phoneNumber,
                                    "Income": Number(requestForm.income),
                                    "FirstPayment": (requestForm.advancedPayment != '' || requestForm.advancedPayment != null || requestForm.advancedPayment != undefined) ? Number(requestForm.advancedPayment) : 0,
                                    "MonthlyAmount": carInfo.price * (LastPaymentRate / 100),
                                    "Period": Math.floor(Number(requestForm.period)) ?? financeSettings.FinancePeriod ?? 1,
                                    "Message": requestForm.note ?? null

                                }
                            }
                        ).then(response => {
                            toast.success(trans("common:order-request-success"));
                            resetControls();
                            setLoadingSendOrder(false);
                        }).catch(err => {
                            //////console.log(err);
                            setLoadingSendOrder(false);
                        });
                    } else {
                        toast.error(trans("error2:not_accept_less_than_0_error"));
                        setLoadingSendOrder(false);
                    }
                } else {
                    //////console.log("newErrors>>:", newErrors);
                    setLoadingSendOrder(false);
                }

            }
        } catch (err) {
            //////console.log(err);
            setLoadingSendOrder(false);
        }
    };

    //to select the type of identity
    const handleCustType = (option) => {
        const val = { ...requestForm };
        val.custType = option;
        setRequestForm(val);

        try {
            if (option == '' || option == null || option == '0') {
                setIdentityType(null);
                return;
            }

            // const currentValue = accountTypes.find(item => {
            //     return item.ID == option
            // }) ?? {};

            let value = null;
            if (option == "G1") {
                value = 1;
                val.cn = null;
            } else {
                value = 2;
                val.identityNo = null;
            }

            setRequestForm(val);
            setIdentityType(value);

        } catch (err) {
            //////console.log(err);
        }
    };


    const [selectedFinancePeriod, setSelectedFinancePeriod] = useState(24);
    const [minFirstPayPrice, setMinFirstPayPrice] = useState(0);
    const reCountFinanceSettings = (carInfo, financeSettings, selectedFinancePeriod, currentFirstPayPrice) => {

        try {
            //////console.log('reCountFinanceSettings', financeSettings);
            if (carInfo && financeSettings) {
                let financeData = { ...approximateResult };
                if (carInfo?.price && financeSettings?.ManagementFees >= 0) {

                    //selectedFinancePeriod = financeSettings?.MaxFinancePeriod;

                    const firstPay = (financeSettings?.AdvancedPaymentRate / 100) ?? 0;
                    const lastPay = (financeSettings?.LastPaymentRate / 100) ?? 0;
                    const managementFees = (financeSettings?.ManagementFees / 100) ?? 0; // الرسوم الادارية
                    const financeRate = (financeSettings?.FinanceRate / 100) ?? 0; //نسبة الفائدة

                    setMinFirstPayPrice(parseInt(firstPay * carInfo?.price));

                    const firstPayPrice = currentFirstPayPrice ? currentFirstPayPrice : (firstPay * parseFloat(carInfo?.price))
                    const lastPayPrice = (lastPay * parseFloat(carInfo?.price));

                    const financeAmount = (parseFloat(carInfo?.price) - parseFloat(firstPayPrice));

                    var addAmount = (financeAmount * financeRate) * (selectedFinancePeriod / 12);
                    var managementFeesAmount = 0;
                    if (addAmount && managementFees && managementFees > 0) {
                        // الرسوم الادارية
                        managementFeesAmount = (financeAmount * managementFees);
                        managementFeesAmount = managementFeesAmount + (managementFeesAmount * 0.15);
                        addAmount = addAmount + managementFeesAmount;

                    }

                    //////console.log('financeAmount', financeAmount);
                    //////console.log('addAmount', addAmount);
                    //////console.log('lastPayPrice', lastPayPrice);
                    //////console.log('financeSettings?.MaxFinancePeriod', selectedFinancePeriod);
                    const mounthBrach = ((financeAmount + addAmount) - lastPayPrice) / (selectedFinancePeriod - 1)

                    const finalAmount = ((mounthBrach * (selectedFinancePeriod - 1)) + lastPayPrice + firstPayPrice)

                    financeData.period = selectedFinancePeriod;
                    financeData.mangFees = managementFeesAmount;
                    financeData.total = finalAmount;
                    financeData.firstPay = firstPayPrice;
                    financeData.lastPay = lastPayPrice;
                    financeData.monthPay = mounthBrach;

                    //////console.log('financeData', financeData);

                    setApproximateResult(financeData);
                }
            }
        } catch (error) {

            ////console.log("error:", error.messages);

        }

    }

    // to remove the unneeded props
    const removeUnspecificError = (errorList, values = []) => {
        let errorResult = {};
        if (values?.length > 0) {
            const lowerValues = values.map(item => {
                return item.toLowerCase()
            });
            Object.assign(errorResult, errorList);
            if (errorList) {
                for (let index = 0; index < Object.keys(errorList).length; index++) {
                    const key = Object.keys(errorList)[index];
                    if (includes(lowerValues, key.toLowerCase())) {
                        delete errorResult[key];
                    } else {
                        continue;
                    }
                }
            }
        }
        return errorResult;
    };


    useEffect(async () => {

        var page = "finance"

        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchSeoPages",
                "Values": {
                    "page": page
                }
            }
        );
        if (response.status == 200) {

            var resdata = response.data[0];

            setSeoPageInfo(resdata);


        }
    }, []);
    return (
        <Fragment>

            {
                seoPageInfo &&

                <Helmet>

                    <meta charSet="utf-8" />
                    {
                        seoPageInfo?.title &&
                        <title>{seoPageInfo?.title}</title>
                    }
                    {
                        seoPageInfo?.link &&
                        <link rel="canonical" href={seoPageInfo?.link} />

                    }
                    {
                        seoPageInfo?.MetaDescription &&
                        <meta name="description" content={seoPageInfo?.MetaDescription} />
                    }
                    {
                        seoPageInfo?.MetaGroups &&
                        <meta name="keywords" content={seoPageInfo?.MetaGroups} />
                    }

                </Helmet>


            }
            <Banner
                subtitle={banner?.subtitle}
                title={banner?.title}
                desc={banner?.excerpt}
                background={banner?.imagePath}
                backOpasity={banner?.backOpasity}
            // btn={hasRedirect ? {
            //     text: trans("info:order-service"),
            //     link: to
            // } : null}
            />
            {/* {banner.map(BN => {

                if (lang === "en") {
                    return (<Banner
                        background={"data:image/png;base64," + BN.image}
                        title={BN.titleEn}
                        subtitle={BN.subTitleEn}
                        desc={BN.excerptEn}
                    />);
                } else {
                    return (<Banner
                        background={"data:image/png;base64," + BN.image}
                        title={BN.titleAr}
                        subtitle={BN.subTitleAr}
                        desc={BN.excerptAr}
                    />);
                }
            })} */}

            <div className="mt-16">
                <Breadcrumb className={"container mt-16 "}
                    links={[
                        {
                            name: trans("finance:finance")
                        }
                    ]}
                />
                <FinanceSearch
                    onHandleRefechData={fn}
                    onSetBanks={(Banks) => {

                        //////console.log("Banks>>>>>>>>>>>>>:", Banks);
                        setBanks(Banks);
                    }}

                    selectionBank={selectionBank}
                    onBankSelected={
                        (activeBank, thisActiveMenu) => {
                            handleFilterByBank(activeBank);
                        }}
                    onActiveMenu={
                        (index) => {
                            setActiveMenu(index);
                            // setCarFilter(null);
                            filterCars([], null);
                        }}
                    onSelectedProduct={
                        (value, thisActiveMenu, thiscarBrandFilter) => {
                            //console.log("onSelectedProduct>>>>>>>>>>>>>:", value);
                            handleFilterByActiveMenu(value, thisActiveMenu, thiscarBrandFilter);

                        }
                    }
                    onBudgetChange={
                        (value, thisActiveMenu, thiscarBrandFilter) => {
                            //console.log("onBudgetChange>>>>>>>>>>>>>:", value);
                            handleFilterByActiveMenu(value, thisActiveMenu, thiscarBrandFilter);

                        }
                    }
                    onQuantitySearchClick={(value, thisActiveMenu, thiscarBrandFilter) => {
                        //console.log("onQuantitySearchClick>>>>>>>>>>>>>:", value);
                        handleFilterByActiveMenu(value, thisActiveMenu, thiscarBrandFilter);

                    }}

                    onsetDefaultperiod={(value) => {



                        var dp = 24;
                        if (value < 36) {
                            dp = 24;
                        } else if (value < 48) {
                            dp = 36;

                        } else if (value < 60) {

                            dp = 48;
                        } else if (value >= 60) {

                            dp = 60;
                        }
                        //////console.log("MMMMMMMMMMMMMMMMMMMMMMM:>>>", dp);
                        const val = { ...requestForm };
                        val.period = dp;
                        setRequestForm(val);
                        setDefaultperiod(dp);

                    }}
                />
            </div>
            <div className={languageDir}>
                <div className="container">
                    <div className="flex items-start jusitfy-between">
                        <PageHeading
                            fixed={true}
                            title={trans("finance:available_vehiclaes")} />
                        <div className="filter-btns">
                            <FilterButton name={trans("finance:brand_lexus")} isActive={carBrandFilter?.toLowerCase() == trans("finance:brand_lexus").toLowerCase()}
                                onclick={() => filterCars(filteredCars, trans("finance:brand_lexus"))} />
                            <FilterButton name={trans("finance:brand_toyota")} isActive={carBrandFilter?.toLowerCase() == trans("finance:brand_toyota").toLowerCase()}
                                onclick={() => filterCars(filteredCars, trans("finance:brand_toyota"))} />
                            <FilterButton name={trans("finance:all")} isActive={carBrandFilter === null}
                                onclick={() => filterCars(filteredCars, null)} />
                        </div>
                    </div>
                </div>
                <div className=" mb-32 bg-white box-shadow py-20">
                    <div className="container py-8">



                        {
                            isLoadingVehicles == true ?

                                <>
                                    <Skeleton count={5} borderRadius={"1rem"} height={"30rem"}
                                        containerClassName={"grid-5-cols"} className={""}
                                        inline={true} />

                                </>

                                :

                                (<div className="grid-5-cols finance-gap">
                                    {
                                        filteredCarsByBrand?.map((car, index) => {
                                            return (
                                                <FinanceCar
                                                    key={index+"_"+car.sn}
                                                    onApplay={(car) => handleOnApplay(car, selectionBank)}
                                                    offerID={car.id}
                                                    groupColor={car.colorGroupID}
                                                    modelType={car.modelTypeID}
                                                    productID={car.productID}
                                                    brand={car.brandTitle}
                                                    brandImage={car.brandImage}
                                                    brandIcon={car.brandIcon}
                                                    image={car.productImage}
                                                    name={car.modelTypeTitle}
                                                    model={car.model}
                                                    group={car.groupTitle}
                                                    price={car?.finalFinaniceTotal ? car.finalFinaniceTotal : car?.price}
                                                    hasOffer={true}
                                                    brandID={car.brandID}
                                                    showPric={car?.showPric}
                                                    colorName={car?.colorName}
                                                />
                                            )
                                        })

                                    }

                                </div>
                                )
                        }


                    </div>

                </div>

                <OfferSections title={trans("finance:finance_offers")} offersList={financeOffers} />

                <Popup show={showPopup}>
                    <div className="finance-popup">
                        <div ref={popRef} className="finance-popup-container nice-flex nice-flex-row">
                            <div className="finance-popup-container-calculator p-8">
                                <div className="finance-popup-container-calculator-header">
                                    <div className={"finance-car-card-type " + (carInfo?.brandID == 1 ? "toyota" : "lexus")}>
                                        <div className={"finance-car-card-type-brand " + (carInfo?.brandID == 1 ? "bg-toyota" : "bg-lexus")}>
                                            <img className={"filter-white pt-1"}
                                                src={`/assets/icons/brands/${(carInfo?.brandID == 1 ? "toyota" : "lexus")}.webp`} alt="toyota" />
                                        </div>
                                    </div>
                                    <div className="finance-car-img">

                                        <img className="mt-2" src={carInfo.image ? carInfo.image : '/assets/images/car-finance.png'} />

                                        <div className="finance-car-img-bg">
                                            <img className={"object-full"} src={`/assets/icons/brands/${(carInfo?.brandID == 1 ? "toyota-light" : "lexus")}.webp`}
                                                alt={(true ? "toyota" : "lexus")} />
                                        </div>

                                    </div>
                                </div>
                                <div className="text-center p-5">
                                    <h1 className="text-16 text-bold text-black">{carInfo.name}</h1>
                                    <div className="nice-flex nice-flex-column">
                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                            <h3 className="text-gray-dark text-16">{trans("finance:group")}</h3>
                                            <p className={"text-primary-dark text-16"}>{carInfo?.group}</p>
                                        </div>
                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                            <h3 className="text-gray-dark text-16">{trans("info:vehicle_year")}</h3>
                                            <p className={"text-primary-dark text-16"}>{carInfo?.model}</p>
                                        </div>
                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                            {
                                                carInfo?.showPric &&
                                                <>
                                                    <h3 className="text-gray-dark text-16">{trans("finance:price")}</h3>
                                                    <p className={"text-primary-dark text-16"}><Price price={carInfo?.price} /></p>
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="ruler-2 mb-3"></div>
                                <SectionHeading
                                    hasRuler={false}
                                    title={trans("finance:results")}
                                    infoText={trans("finance:finance_note")}
                                >
                                    {
                                        carInfo?.showPric &&
                                        <div className="nice-flex nice-flex-column">
                                            <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                <h3 className="text-gray-dark text-16">{trans("finance:period")}</h3>
                                                <p className={"text-primary-dark text-16"}>{approximateResult?.period}</p>
                                            </div>
                                            <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                <h3 className="text-gray-dark text-16">{trans("finance:management_fees")}</h3>
                                                <p className={"text-primary-dark text-16"}><Price price={approximateResult?.mangFees} showZero={true} /></p>
                                            </div>
                                            <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                <h3 className="text-gray-dark text-16">{trans("finance:monthPayment")}</h3>
                                                <p className={"text-primary-dark text-16"}><Price price={approximateResult?.monthPay} showZero={true} /></p>
                                            </div>
                                            <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                <h3 className="text-gray-dark text-16">{trans("finance:advance_payment")}</h3>
                                                <p className={"text-primary-dark text-16"}> <Price price={approximateResult?.firstPay} showZero={true} /></p>
                                            </div>
                                            <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                <h3 className="text-gray-dark text-16">{trans("finance:lastPayment")}</h3>
                                                <p className={"text-primary-dark text-16"}> <Price price={approximateResult?.lastPay} showZero={true} /></p>
                                            </div>
                                            <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                <h3 className="text-gray-dark text-16">{trans("finance:final_price")}</h3>
                                                <p className={"text-primary-dark text-16"}><Price price={approximateResult?.total} showZero={true} /></p>
                                            </div>
                                        </div>

                                    }

                                </SectionHeading>
                            </div>
                            <div className="finance-popup-container-form p-5 flex flex-col nice-gap-2rem nice-flex-space-between" >
                                <div className="flex flex-col">
                                    <SectionHeading
                                        title={trans("finance:personal_data")}
                                        className={"flex flex-col nice-gap-2rem"}
                                    >
                                        <div className="nice-flex nice-flex-row nice-align-item-center ">
                                            <div className={"text-20 text-gray w-20 "}>{trans("finance:cust_type")}:
                                            </div>
                                            <div className="w-80 ">
                                                <DropDownInput
                                                    defaultOption={requestForm?.custType == null ? { text: trans("finance:cust_type") + '*', value: '0' } : null}
                                                    errorLabel={error?.custType}
                                                    options={[...accountTypes.map(item => {
                                                        return { text: item[`${trans("model:description")}`], value: item.CustGroupCollectingID }
                                                    })]}
                                                    onSelect={handleCustType}

                                                    selectedValue={requestForm?.custType}
                                                />
                                            </div>
                                        </div>
                                        <>
                                            {

                                                (identityType && identityType == 1) ?
                                                    <div className="nice-flex nice-flex-row nice-align-item-center ">
                                                        <div className={"text-20 text-gray w-20 "}>{trans(identityType == 1 ? "form:identity-no" : "finance:cn")}:
                                                        </div>
                                                        <div className="w-80 ">
                                                            <InputField
                                                                errorLabel={error?.identityNo}
                                                                value={requestForm.identityNo}
                                                                length={10}
                                                                placeholder={trans("form:identity-no") + '*'}
                                                                onChangeValue={(e) => {
                                                                    const val = { ...requestForm };
                                                                    val.identityNo = e.target.value;
                                                                    setRequestForm(val);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    : (identityType && identityType == 2) ?
                                                        <div className="nice-flex nice-flex-row nice-align-item-center ">
                                                            <div className={"text-20 text-gray w-20 "}>{trans(identityType == 1 ? "form:identity-no" : "finance:cn")}:
                                                            </div>
                                                            <div className="w-80 ">
                                                                <InputField
                                                                    errorLabel={error?.identityNo}
                                                                    value={requestForm.identityNo}
                                                                    length={20}
                                                                    placeholder={trans("finance:cn") + '*'}
                                                                    onChangeValue={(e) => {
                                                                        const val = { ...requestForm };
                                                                        val.cn = e.target.value;
                                                                        setRequestForm(val);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        : null


                                            }

                                        </>
                                        <div className="nice-flex nice-flex-row nice-align-item-center ">
                                            <div className={"text-20 text-gray w-20 "}>{trans("form:name")}:
                                            </div>
                                            <div className="w-80 ">
                                                <InputField
                                                    errorLabel={error?.fullNameAr}
                                                    placeholder={trans("form:name") + '*'}

                                                    value={requestForm.fullNameAr}
                                                    length={255}
                                                    onChangeValue={(e) => {
                                                        const val = { ...requestForm };
                                                        val.fullNameAr = e.target.value;
                                                        setRequestForm(val);
                                                    }}
                                                />

                                            </div>
                                        </div>
                                        {/* <InputField
                                            errorLabel={error?.fullNameEn}
                                            placeholder={trans("finance:full_name_en") + '*'}
                                            value={requestForm.fullNameEn}
                                            length={255}
                                            onChangeValue={(e) => {
                                                const val = { ...requestForm };
                                                val.fullNameEn = e.target.value;
                                                setRequestForm(val);
                                            }}
                                        /> */}

                                        <div className="nice-flex nice-flex-row nice-align-item-center ">
                                            <div className={"text-20 text-gray w-20 "}>{trans("form:phone") + '*'}:
                                            </div>
                                            <div className="w-80 ">
                                                <PhoneDropDown
                                                    errorLabel={error?.phoneNumber}
                                                    defaultOption={trans("form:phone") + '*'}
                                                    onChangeValue={(countryCode, phoneNumber) => {
                                                        const val = { ...requestForm };
                                                        val.phoneNumber = `+${countryCode}${phoneNumber}`;
                                                        setRequestForm(val);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="nice-flex nice-flex-row nice-align-item-center ">
                                            <div className={"text-20 text-gray w-20 "}>{trans("form:email") + '*'}:
                                            </div>
                                            <div className="w-80 ">
                                                <InputField
                                                    type="email"
                                                    errorLabel={error?.email}
                                                    placeholder={trans("form:email") + '*'}
                                                    value={requestForm.email}
                                                    onChangeValue={(e) => {
                                                        const val = { ...requestForm };
                                                        val.email = e.target.value;
                                                        setRequestForm(val);
                                                    }}
                                                />

                                            </div>
                                        </div>
                                    </SectionHeading>

                                    <SectionHeading
                                        title={trans("finance:finance_order_data")}
                                        hasRuler={false}
                                        className={"flex flex-col nice-gap-2rem"}
                                    >

                                        <div className="nice-flex nice-flex-row nice-align-item-center ">
                                            <div className={"text-20 text-gray w-20 "}>{trans("finance:bank_name")}:
                                            </div>
                                            <div className="w-80 ">
                                                <DropDownInput
                                                    defaultOption={{ text: trans("finance:bank_name"), value: '0' }}
                                                    force={true}
                                                    // defaultOption={defaultBank}
                                                    options={[...banks.map(item => {
                                                        return { text: lang == "ar" ? (item.nameAr ? item.nameAr : item.DescriptionAr) : (item.nameEn ? item.nameEn : item.DescriptionEn), value: item.GUID }
                                                    })]}

                                                    onSelect={(option) => {
                                                        const val = { ...requestForm };
                                                        val.bankID = option;
                                                        setRequestForm(val);
                                                    }}
                                                    selectedValue={requestForm?.bankID ? requestForm?.bankID : selectionBank.GUID}
                                                />
                                            </div>
                                        </div>

                                        <div className="nice-flex nice-flex-row nice-align-item-center ">
                                            <div className={"text-20 text-gray w-20 "}>{trans("finance:net_income") + '*'}:
                                            </div>
                                            <div className="w-80 ">
                                                <InputField
                                                    min={500}
                                                    errorLabel={error?.income}
                                                    type='number'
                                                    value={requestForm.income}
                                                    placeholder={trans("finance:net_income") + '*'}
                                                    onChangeValue={(e) => {
                                                        const val = { ...requestForm };
                                                        val.income = e.target.value;
                                                        setRequestForm(val);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {/* <InputField
                                        min={financeSettings?.FinancePeriod ?? 1}
                                        max={financeSettings?.MaxFinancePeriod ?? 60}
                                        step={financeSettings?.FinancePeriod ?? 1}
                                        errorLabel={error?.period}
                                        type='number'
                                        value={requestForm.period}
                                        title={trans("finance:finance_period") + " (" + trans("finance:byMonth") + " )" + '*'}
                                        onChangeValue={(e) => {
                                            const val = { ...requestForm };
                                            val.period = e.target.value;
                                            setRequestForm(val);
                                            reCountFinanceSettings(carInfo, selectionBank, val.period);
                                        }}
                                    /> */}


                                        <div className="nice-flex nice-flex-row nice-align-item-center ">
                                            <div className={"text-20 text-gray w-20 "}>{trans("finance:finance_period") + " (" + trans("finance:byMonth") + " )" + '*'}:
                                            </div>
                                            <div className="w-80 ">
                                                <DropDownInput
                                                    defaultOption={trans("finance:finance_period") + " (" + trans("finance:byMonth") + " )" + '*'}
                                                    force={true}
                                                    errorLabel={error?.period}
                                                    // defaultOption={defaultperiod}
                                                    options={[{ text: 24, value: 24 }, { text: 36, value: 36 }, { text: 48, value: 48 }, { text: 60, value: 60 }]}
                                                    onSelect={(option) => {
                                                        const val = { ...requestForm };
                                                        val.period = option;
                                                        setRequestForm(val);
                                                        reCountFinanceSettings(carInfo, selectionBank, val.period);
                                                    }}
                                                    selectedValue={requestForm.period}
                                                />
                                            </div>
                                        </div>

                                        <div className="nice-flex nice-flex-row nice-align-item-center ">
                                            <div className={"text-20 text-gray  w-20"}>{trans("finance:advance_payment")}:
                                            </div>
                                            <div className="w-80 ">
                                                <InputField
                                                    min={minFirstPayPrice}
                                                    errorLabel={error?.advancedPayment}
                                                    value={requestForm.advancedPayment}
                                                    placeholder={trans("finance:advance_payment")}
                                                    type='number'
                                                    onChangeValue={(e) => {

                                                        // if (e?.target?.value >= minFirstPayPrice) {

                                                        const val = { ...requestForm };
                                                        val.advancedPayment = e.target.value;
                                                        setRequestForm(val);


                                                        // }

                                                    }}

                                                    onLeave={(e) => {
                                                        if (e?.target?.value >= minFirstPayPrice) {

                                                            reCountFinanceSettings(carInfo, selectionBank, requestForm.period, e.target.value);

                                                        } else {

                                                            const val = { ...requestForm };
                                                            val.advancedPayment = minFirstPayPrice;
                                                            setRequestForm(val);
                                                            reCountFinanceSettings(carInfo, selectionBank, requestForm.period, minFirstPayPrice);
                                                        }

                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {/* {

                                        colors && <DropDownInput
                                            title={trans("form:color")}
                                            options={[...colors]}
                                            onSelect={(option) => {
                                                const val = { ...requestForm };
                                                val.colorID = option;
                                                setRequestForm(val);
                                            }}
                                        />
                                    } */}
                                        <TextArea
                                            placeholder={trans("common:note")}
                                            value={requestForm.note}
                                            onChangeValue={(e) => {
                                                const val = { ...requestForm };
                                                val.note = e;
                                                setRequestForm(val);
                                            }}
                                            row={3}
                                        />

                                    </SectionHeading>
                                </div>
                                <div className="nice-flex nice-flex-row my-10">
                                    {/* <div className="nice-bg-btn bg-black text-white text-14 nice-sm-hidden nice-select-none"
                                        onClick={() => {

                                            // handleCalculate
                                            reCountFinanceSettings(carInfo, selectionBank, requestForm?.period);
                                        }


                                        }
                                    >
                                        {trans("finance:calculate")}
                                    </div> */}
                                    <div
                                        className="nice-bg-btn bg-primary text-white text-20 nice-select-none"
                                        onClick={handleSubmit}
                                    >
                                        {trans("finance:apply")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
            </div>
        </Fragment>
    );
}